// @ts-nocheck


import React, { useReducer, useEffect, useState, useContext } from 'react';

import axios from 'axios';

// lodash
import uniqueId from 'lodash/uniqueId';

// context
import { FormContext } from '../../HOC/FormHOC/context';
import { rootURL } from '../../../config/config';

import { toast } from 'react-toastify';

import { useAfterFormSubmit } from '../../../customHooks/afterFormSubmit';

import { _initialState } from '../state/initialState';

import { IWebsiteQuestionnaireState } from '../Interfaces/Interfaces';

import { getWebQPageNumber, isMLMQuestionnaireCode, isRealEstateQuestionnaireCode, isWebsiteQuestionnaireCode } from '../../../utils/formCodes';

import UserContext from '../../../context/UserContext/Context/context';


const WebsiteQuestionnaireContainer = (props) => {

    const { user } = useContext(UserContext);

    const [afterFormSubmit] = useAfterFormSubmit();

    const [state2, setState] = useState<IWebsiteQuestionnaireState>(_initialState);

    useEffect(() => {
        if (state2?.numberOfPages > 0) {
            generatePages();
        }
    }, [state2?.numberOfPages])

    useEffect(() => {
        if (isWebsiteQuestionnaireCode(state2?.formCode)) {
            // set isFormCode to true && generate the number of pages based upon the formcode
            setState((prevState) => Object.assign({}, { ...prevState, isWebsiteQuestionnaireCode: isWebsiteQuestionnaireCode(prevState?.formCode), numberOfPages: getWebQPageNumber(prevState?.formCode) }));
        }
        if (isRealEstateQuestionnaireCode(state2?.formCode)) {
            return setState((prevState) => Object.assign({}, { ...prevState, isRealEstateCode: true, isMLMCode: false }));
        }
        if (isMLMQuestionnaireCode(state2?.formCode)) {
            return setState((prevState) => Object.assign({}, { ...prevState, isRealEstateCode: false, isMLMCode: true }));
        }
        return setState((prevState) => Object.assign({}, { ...prevState, isWebsiteQuestionnaireCode: false, isRealEstateCode: false, isMLMCode: false }));
    }, [state2.formCode]);

    const validateForm = () => {
        const formValidated = Object.keys(state2).every((key) => {
            if (state2[key] === undefined) {
            }
            return state2[key] !== undefined
        }) && state2?.date;
        return formValidated;
    };

    const onSubmit = () => {
        try {
            const formValidated = validateForm();
            if (!formValidated) {
                throw new Error('Finish filling out the form!');
            }

            setState((prevState) => Object.assign({}, { ...prevState, formDisabled: true }));

            const websiteQuestionnaireForm = document.querySelector('#RealEstateWebsiteQuestionnaireForm') as HTMLFormElement || document.querySelector('#MLMWebsiteQuestionnaireForm') as HTMLFormElement;
            const url = (document.querySelector('#RealEstateWebsiteQuestionnaireForm') as HTMLFormElement) ? '/websiteQuestionnaire/createRealEstateQuestionnaireEmail' : '/websiteQuestionnaire/createMLMQuestionnaireEmail';

            const formData = new FormData(websiteQuestionnaireForm);

            // Delete files
            formData.delete('selectedCustomDesignFile');
            formData.delete('selectedfileUploadFile');

            (state2?.customDesignFiles as [any])?.map((file) => {
                formData.append('customDesignFiles[]', file?.name);
                formData.append('file[]', file)
            });
            (state2?.fileUploadFiles as [any])?.map((file) => {
                formData.append('fileUploadFiles[]', file?.name);
                formData.append('file[]', file)
            });

            formData.append('numberOfPages', state2?.numberOfPages.toString());

            formData.append('pages', JSON.stringify(state2?.pages?.map((page, index) => Object.assign({}, { ...page, index: index + 1 }))));

            formData.append('clientID', user?.accountId);

            // axios.post('/websiteQuestionnaire/createRealEstateQuestionnaireEmail', formData)
            axios.post(rootURL + url, formData)
                .then((results) => afterFormSubmit())
                .catch((error) => {
                    toast.error(error.message);
                    console.warn(error)
                });
        } catch (error) {
            toast.error(error.message)
        }

    };


    const generatePages = () => {
        setState((prevState) => Object.assign({}, {
            ...prevState,
            pages: Array.from(Array(prevState?.numberOfPages))
                .map(() => Object.assign({}, { id: uniqueId('Page_'), title: '', details: '' })
                )
        }))
    };

    /**
     * @name onPageInputChange
     * @param {React.ChangeEvent<HTMLInputElement>} e - The state property name of the input being used by the user
     */
    const onPageInputChange = (inputType: 'title' | 'details', id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        setState((prevState) => Object.assign({}, {
            ...prevState,
            pages: prevState?.pages?.map((page) => {

                if (page?.id === id) {
                    return Object.assign({}, { ...page, [inputType]: value });
                }
                else return page;
            })
        }))
    };

    /**
     * @name onFileChange
     * @description - Handles storing the chosen file from the users cmoputer into state 
     * @param {'custom' | 'fileUpload'} fileUploadType - Determines if the file upload is for the custom design or file upload section of the form
     * @param {React.ChangeEvent<HTMLInputElement>} e - The event handler object when the user clicks to open a file
     */
    const onFileChange = (fileUploadType: 'custom' | 'fileUpload') => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target;

        const fileSizeMeetsRequirements = ((files?.[0]?.size/1024/1024) < 10);

        return (fileUploadType === 'custom')
            ? setState((prevState) => Object.assign({}, { ...prevState, [name]: (fileSizeMeetsRequirements) ? files?.[0] : '', customDesignPercentage_uploading: (fileSizeMeetsRequirements) ? 100 : 0, customDesignPercentage_error: (fileSizeMeetsRequirements) ? 0 : 100, customDesignPercentage_requirements: (fileSizeMeetsRequirements) ? 0 : 100, customDesignPercentage_successful: 0 }))
            : setState((prevState) => Object.assign({}, { ...prevState, [name]: (fileSizeMeetsRequirements) ? files?.[0] : '', fileUpload_uploading: (fileSizeMeetsRequirements) ? 100 : 0, fileUpload_requirements: (fileSizeMeetsRequirements) ? 0 : 100, fileUpload_error: (fileSizeMeetsRequirements) ? 0 : 100, fileUpload_successful: 0 }));
    };

    /**
     * @name onCancelFileUpload
     * @description - Handles storing files into the state array when the user clicks the 'upload button'
     * * @param {'custom' | 'fileUpload'} fileUploadType - Determines if the file upload is for the custom design or file upload section of the form
     * @param {React.ChangeEvent<HTMLButtonElement>} e - The event handler object when the user clicks on the 'cancel' button
     */
    const onCancelFileUpload = (fileUploadType: 'custom' | 'fileUpload') => (e: React.ChangeEvent<HTMLButtonElement>) => {
        const { name } = e.target;
        return (fileUploadType === 'custom')
            ? setState((prevState) => Object.assign({}, { ...prevState, [name]: '', customDesignPercentage_uploading: 0, customDesignPercentage_error: 0, customDesignPercentage_requirements: 0, customDesignPercentage_successful: 0 }))
            : setState((prevState) => Object.assign({}, { ...prevState, [name]: '', fileUpload_uploading: 0, fileUpload_requirements: 0, fileUpload_error: 0, fileUpload_successful: 0 }));
    };

    /**
     * @name onFileUpload
     * @description - Handles storing files into the state array when the user clicks the 'upload'
     * @param {'customDesignFiles' | 'fileUploadFiles'} fileStateName - Name of the state property being updated
     */
    const onFileUpload = (fileStateName: 'customDesignFiles' | 'fileUploadFiles') => (e: React.ChangeEvent<HTMLButtonElement>) => {
        const { name } = e.target;
        return (fileStateName === 'customDesignFiles')
            ? setState((prevState) => Object.assign({}, { ...prevState, [fileStateName]: prevState[fileStateName].concat(prevState[name]) as [File], [name]: '', customDesignPercentage_uploading: 0, customDesignPercentage_error: 0, customDesignPercentage_requirements: 0, customDesignPercentage_successful: 100 }))
            : setState((prevState) => Object.assign({}, { ...prevState, [fileStateName]: prevState[fileStateName].concat(prevState[name]) as [File], [name]: '', fileUpload_uploading: 0, fileUpload_requirements: 0, fileUpload_error: 0, fileUpload_successful: 100 }));
    };

    /**
     * @name onRemoveFile
     * @description - Handles removing file from state array when the user clicks the 'remove' button on the file list
     * @param {'customDesignFiles' | 'fileUploadFiles'} fileStateName - Name of the files state array being updated 
     */
    const onRemoveFile = (fileStateName: 'customDesignFiles' | 'fileUploadFiles') => (e: React.ChangeEvent<HTMLButtonElement>) => {
        const filename = e.target.getAttribute('data-filename')
        setState((prevState) => Object.assign({}, { ...prevState, [fileStateName]: prevState[fileStateName]?.filter((file) => file?.name !== filename) as [File] }));
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prevState) => Object.assign({}, { ...prevState, [name]: value }));
    };

    const onCheckboxClick = (checkedValue: undefined | boolean) => (_, { checked, name }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: (checkedValue !== undefined) ? checkedValue : checked }));
    const onReferredAsClick = (_, { checked, name }) => setState((prevState) => Object.assign({}, {
        ...prevState,
        referredAs_broker: false,
        referredAs_realEstateAgent: false,
        referredAs_realtor: false,
        referredAs_other: false,
        [name]: checked,
    }));

    const onDateChange = (date: Date) => setState((prevState) => Object.assign({}, { ...prevState, date }));

    return <FormContext.Provider value={{}}>
        {props.children({ state2, onChange, onCheckboxClick, onReferredAsClick, onFileChange, onCancelFileUpload, onFileUpload, onRemoveFile, onPageInputChange, onDateChange, onSubmit })}
    </FormContext.Provider>;
}

export default WebsiteQuestionnaireContainer;