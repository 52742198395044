import React from 'react';
import { Segment, Grid, SemanticWIDTHS } from 'semantic-ui-react';

interface IProps {
    id?: string,
    header: JSX.Element,
    children: JSX.Element,
   // gridColumns: SemanticWIDTHS,
};



const FormSegmentSingleHeader: React.FunctionComponent<IProps>  = ({ header, ...props }) => {
    return (
        <Segment textAlign='left' id={props.id}>
            <Grid>
                <Grid.Column className='margin-padding-fix'>
                    {header}
                </Grid.Column>
            </Grid>

                {props.children}
        </Segment>
    );
}

export default FormSegmentSingleHeader;