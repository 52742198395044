import React from 'react';
import { Segment, Grid, Header, Message } from 'semantic-ui-react';

const FormHeader = ({ header, message }) => {

    return (
        <>
            <Segment textAlign='left'>
                <Grid>
                    <Grid.Column>
                        <Header dividing content={header} />
                        <Message>
                            {message}
                        </Message>
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
    );
};

export default FormHeader;
