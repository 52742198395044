import React from 'react';
import { Message, Header } from 'semantic-ui-react';

// Interfaces
import { IHeader } from '../../../Interfaces/Interfaces'; 

const Note: React.FunctionComponent<IHeader> = ({ header, ...props }) => {

    return (
        <>
            <Message style={{ textAlign: 'left' }}>
                <Header content={header} />
                {props.children}
            </Message>
        </>
    );
};

export default Note;
