import React from "react";
import { Form, Segment, Grid, Header, Card } from "semantic-ui-react";

// Components
import OrderForm from "./UpgradesForm/OrderForm/OrderForm";
import PackageOptions from "./UpgradesForm/PackageOptions/PackageOptions";
import PaymentMethod from "./UpgradesForm/SecondSegment/PaymentMethod/PaymentMethod";
import CardInformation from "./UpgradesForm/SecondSegment/CardInformation/CardInformation";
import Inclusions from "./UpgradesForm/SecondSegment/Inclusions/Inclusions";
import TermsConditions from "./UpgradesForm/SecondSegment/TermsConditions/TermsConditions";
import ConfirmUpgradeOrder from "./UpgradesForm/FourthSegment/ConfirmUpgradeOrder/ConfirmUpgradeOrder";
import PaymentInformation from "./UpgradesForm/SecondSegment/PaymentInformation/PaymentInformation";
import ContactInformation from "./UpgradesForm/SecondSegment/ContactInformation/ContactInformation";

// Container
import UpgradesContainer from "./Container/UpgradesContainer";

// Reusable components
import ContactHeaderBar from "../ReusableComponents/ContactHeaderBar/ContactHeaderBar";
import DoubleFormSegment from "../ReusableComponents/DoubleFormSegment/DoubleFormSegment";
import CheckInformation from "./UpgradesForm/SecondSegment/CheckInformation/CheckInformation";
import BillingInformation from "./UpgradesForm/SecondSegment/BillingInformation/BillingInformation";

/**
 *
 * @param props
 */
const Upgrades = (props) => {
  return (
    <UpgradesContainer>
      {({
        packageOptions,
        paymentMethod,
        onSubmit,
        state,
        forceUpdate,
        simpleValidator,
      }) => (
        <>          
          <ContactHeaderBar Header="Upgrades &amp; orders" />
          <Form
            style={{ textAlign: "left" }}
            id="UpgradesForm"
            onSubmit={onSubmit}>
            {/* First Segment */}
            <DoubleFormSegment
              leftColumnWidth={7}
              leftColumn={<OrderForm />}
              rightColumnWidth={9}
              rightColumn={<PackageOptions />}
            />

            {/* Card Information */}
            <Segment>
              <DoubleFormSegment
                leftColumnWidth={7}
                leftColumn={<PaymentInformation />}
                rightColumnWidth={9}
                rightColumn={<ContactInformation />}
                showSegment={false}
              />
              <PaymentMethod />
              {state?.creditDebitCard ? (
                <DoubleFormSegment
                  leftColumnWidth={7}
                  leftColumn={
                    <CardInformation
                      paymentMethod={state?.paymentMethod}
                      forceUpdate={forceUpdate}
                      simpleValidator={simpleValidator}
                      cardNumber={state?.cardNumber}
                      cardExpireMonth={state?.cardExpireMonth}
                      cardExpireYear={state?.cardExpireYear}
                      ccv={state?.cvv}
                      cardFirstName={state?.cardFirstName}
                      cardLastName={state?.cardLastName}
                      dailyCap={state?.dailyCap}
                      state={state}
                    />
                  }
                  rightColumnWidth={9}
                  rightColumn={
                    <BillingInformation
                      address={state?.billingInformationStreetAddress}
                      apt={state?.billingInformationApt}
                      city={state?.billingInformationCity}
                      state={state?.billingInformationState}
                      country={state?.billingInformationCountry}
                      zipcode={state?.billingInformationZipCode}
                    />
                  }
                  showSegment={false}
                />
              ) : (
                ""
              )}
              <CheckInformation
                checkType={state?.checkType}
                bankName={state?.bankName}
                nameOnCheck={state?.nameOnCheck}
                routingNumber={state?.routingNumber}
                accountNumber={state?.accountNumber}
                checkNumber={state?.checkNumber}
                isVisable={state?.check}
              />
              <Inclusions />
            </Segment>
            <TermsConditions
              packageOptions={packageOptions}
              packageValue={state?.upgrade?.value}
            />
            <ConfirmUpgradeOrder
              signature={state?.signature}
              date={state?.date}
              agreeToTermsAndConditions={state?.agreeToTermsAndConditions}
              formDisabled={state?.formDisabled}
            />
          </Form>
        </>
      )}
    </UpgradesContainer>
  );
};

export default Upgrades;
