import React from 'react';
import { Header, Form, Label,  Message, Dropdown, Icon } from 'semantic-ui-react';

// Interfaces
import { BillingAgreementProps } from '../Interfaces/Interfaces';
import ErrorLabel from '../../ReusableComponents/ErrorLabel/ErrorLabel';


/**
 * 
 * @typedef {React.FunctionComponent} BillingAgreement - Form component that handles billing agreement components (billing date, hosting fee, promocode etc)
 */
const BillingAgreement: React.FunctionComponent<any> = ({ billingDate, hostingFee, monthsAgreedTo, promoCode, options, /*billingAgreementOptions,*/ handleOnChange, handleDropdownOnChange }) => {
    
    return (
        <>
            <Header dividing content='Billing Agreement' as='h2' />
            <Message style={{ textAlign: 'left' }} info>
                <Message.Header></Message.Header>
                The hosting fee is: <strong> {hostingFee}
                </strong> per month and the <br /> maintenance fee is <strong>$10.00</strong> per month.
            </Message>
            <Form.Group widths='3'>
                <Form.Field>
                    <label>Billing Date:</label>
                    <Dropdown selection placeholder='Select Billing Date' name='billingDate' options={options} onChange={handleDropdownOnChange} value={billingDate?.value}  />
                    <Label pointing>These two charges appear monthly starting on the following date.</Label>
                    <ErrorLabel error={!billingDate?.value} />
                </Form.Field>
                <Form.Field>
                    <label># of Months Agreed to:</label>
                    <Form.Input required type='number' name="monthsAgreedTo" placeholder="Number of Months" value={monthsAgreedTo} onChange={handleOnChange} />
                    <Label pointing>The following number of months will be the number of months your site
                  will be hosted and maintained for the world to see.</Label>
                  <ErrorLabel error={!monthsAgreedTo} />
                </Form.Field>
                <Form.Field>
                    <label>Promocode:</label>
                    <Form.Input name="promoCode" placeholder="Promocode" value={promoCode} onChange={handleOnChange} />
                    <Label>If you have a promotion code, enter it here.</Label>
                </Form.Field>
            </Form.Group>
            {
                // TODO: Combine the two following components into one compoennt with internal conditional rendering
                (promoCode.toLowerCase().trim() === 'promo1')
                    ? <Message positive>
                        <Icon name='close' />
                        <Message.Header>Congratulations!</Message.Header>
                        The promotion code you entered is valid and saves you <strong>$50.00</strong> per month!
              <br />
              The hosting fee is now just <strong>$9.95</strong>.
                    </Message>
                    : ''
            }
            {
                (promoCode.toLowerCase().trim() === 'custom')
                    ? <Message positive>
                        <Icon name='close' />
                        <Message.Header>Congratulations!</Message.Header>
                        The promotion code you entered is valid and saves you <strong>$40.00</strong> per month!
              <br />
              The hosting fee is now just <strong>$19.95</strong>.
                    </Message>
                    : ''
            }
        </>
    );
};

BillingAgreement.defaultProps = {
    options: [
        { text: '1st', value: 1 },
        { text: '5th', value: 5 },
        { text: '10th', value: 10 },
        { text: '15th', value: 15 },
        { text: '20th', value: 20 },
        { text: '25th', value: 25 },
    ]
};

export default BillingAgreement;