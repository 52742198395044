import React from 'react';
import { Form, Segment, Grid, Header } from 'semantic-ui-react';

type IProps = {
    Header: string,
    GridColumns?: number,
    GridColumnWidth?: number,
    GridColumnClass?: string,
};

const FormSegment: React.FunctionComponent<IProps> = ({ Header: content, GridColumns, GridColumnWidth, ...props }: any) => {

    return (
        <>
                <Segment>
                    <Grid columns={GridColumns} textAlign='left'>
                        <Grid.Column textAlign='left' width={GridColumnWidth} className={props.GridColumnClass}>
                            <Header dividing content={content} as='h2' />
                            {props.children}
                        </Grid.Column>
                    </Grid>
                </Segment>
        </>
    );
};

FormSegment.defaultProps = {
    GridColumns: 1,
    GridColumnWidth: 16,
};

export default FormSegment;
