import React from 'react';
import { Segment, Grid, Header, Message } from 'semantic-ui-react';

// formCodes
import { formCodes, getRevisionType, RevisionType, getRevisionFormType } from '../../utils/formCodes';

const RevisionHeaderMessage = ({ revisionCode }) => {

    // TODO: Use state handlers to display text
    const displayMessageHeader = (revisionCode) => {
        if (getRevisionType(revisionCode) === RevisionType.First) return 'Your site is coming along!';
        if (getRevisionType(revisionCode) === RevisionType.Final) return 'The final stretch for your custom site!';
        if (getRevisionType(revisionCode) === RevisionType.Paid) return 'Thank you for your purchase!';
    };

    const displayMessageText = (revisionCode) => {
        if (getRevisionType(revisionCode) === RevisionType.First) return <>
            This is the <strong>1st</strong> of your <strong>2 free</strong> revisions where you can make tweaks to
              your new custom site. Fill in all the details you'd like updated on your custom and they'll be sent along
              to our design team to get it handled!
        </>;
        if (getRevisionType(revisionCode) === RevisionType.Final) return <>
            This is the <strong>Last</strong> of your <strong>2 free</strong> revisions where you can make tweaks to
          your new custom site. Fill in all the details you'd like updated on your custom and they'll be sent along
          to our design team to get it handled!
        </>;
        if (getRevisionType(revisionCode) === RevisionType.Paid) return <>
            Once submit, your revisions will be handled by our design team ASAP!
        </>;
    }

    return (
        <Segment className='margin-fix'  data-testid='RevisionsHeaderMessage'>
            <Grid columns={1}>
                <Grid.Column textAlign='left'>
                    <Header dividing as='h2' content={getRevisionFormType(revisionCode)} />
                    <Message info >
                        <Header content={displayMessageHeader(revisionCode)} />
                        <br />
                        {displayMessageText(revisionCode)}
                    </Message>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default RevisionHeaderMessage;
