import React from 'react';
import { Label } from 'semantic-ui-react';

interface IProps {
    content?: string,
    className?: string,
    visable: boolean,
};

const WarningLabel: React.FunctionComponent<IProps> = (props) => (props.visable) ? <Label style={{ width: '100%', textAlign: 'center' }}  color='red' basic pointing {...props} /> : null;

WarningLabel.defaultProps = {
    visable: true,
    content: 'Please add details.'
};
export default WarningLabel;