import React from 'react';
import PropTypes from 'prop-types'

/**
 * @name RenderWrapper
 * @description - Component that handles conditional rendering
 * @param {isVisable: boolean, children: React.ReactChildren} props - The props of the RenderWrapper 
 */
const RenderWrapper: React.FunctionComponent<any> = (props) => (props.isVisable) ? props.children : null;

RenderWrapper.propTypes = {
    isVisable: PropTypes.bool,
}

RenderWrapper.defaultProps = {
    isVisable: false,
}

export default RenderWrapper
