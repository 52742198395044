import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import {
  Header,
  Form,
  Label,
  Dropdown,
  TextArea,
  Divider,
} from "semantic-ui-react";
import { EspoClient } from "../../../../App";

// Interfaces
import { IPackageOptionsProps } from "../../../../Interfaces/Interfaces";
import { FormContext } from "../../../HOC/FormHOC/context";

// Package Options

const PRODUCTS = gql`
  {
    upgrades {
      id
      name
    }
  }
`;

const PackageOptions: React.FunctionComponent<any> = (props) => {
  let packageOptions = [];
  const { loading, data, error } = useQuery(PRODUCTS, { client: EspoClient });

  if (data) {
    const { upgrades } = data;
    packageOptions = upgrades.map((val) => ({ text: val.name, value: val.id }));
  }
  if (error) {
    console.log(error);
  }
  const { handleOnChange, handleDropdownChange, state, packageOptionsRef } =
    React.useContext<any>(FormContext);

  return (
    <div ref={packageOptionsRef}>
      <Header
        dividing
        content="Package Options"
        as="h2"
        className="left-align"
      />
      <Form.Field error={!state?.upgrade} className="left-align">
        <Label className="no-background-label">
          Upgrade: <span className="color-red-regular">*</span>
        </Label>
        <Form.Dropdown
          required
          selection
          id="upgradeDropdown"
          placeholder="Select Upgrade"
          name="upgrade"
          value={state?.upgrade?.value}
          onChange={handleDropdownChange}
          options={packageOptions}
        />
        {!state?.upgrade?.value ? (
          <Label
            pointing
            basic
            color="red"
            className="center-fix"
            content="Please make a selection."
          />
        ) : (
          ""
        )}
      </Form.Field>
      <Form.Field className="left-align">
        <Label
          content="Special Instructions:"
          className="no-background-label"
        />
        <TextArea
          name="specialInstructions"
          placeholder="Special Instructions"
          value={state?.specialInstructions}
          onChange={handleOnChange}
        />
      </Form.Field>
      <Divider />
      <Form.Group className="left-align">
        <Form.Field>
          <Label content="Name: " className="no-background-label" />
          <Form.Input
            error={!state?.firstName}
            required
            name="firstName"
            placeholder="First Name"
            value={state?.firstName}
            onChange={handleOnChange}
          />
          {!state?.firstName ? (
            <Label
              basic
              pointing
              color="red"
              className="center-fix no-background-label"
              content="Please add some details."
            />
          ) : (
            ""
          )}
        </Form.Field>
        <Form.Field>
          <Label className="no-background-label">&nbsp;</Label>
          <Form.Input
            error={!state?.lastName}
            required
            name="lastName"
            placeholder="Last Name"
            value={state?.lastName}
            onChange={handleOnChange}
          />
          {!state?.lastName ? (
            <Label
              basic
              pointing
              color="red"
              className="center-fix no-background-label"
              content="Please add some details."
            />
          ) : (
            ""
          )}
        </Form.Field>
      </Form.Group>
    </div>
  );
};

PackageOptions.defaultProps = {};

export default PackageOptions;
