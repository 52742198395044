import React from 'react';
import { Header, Form, Label, Dropdown } from 'semantic-ui-react';

// Interfaces
import { IUpgradesContactInformationProps } from '../../../../../Interfaces/Interfaces';
import { FormContext } from '../../../../HOC/FormHOC/context';

import { states, countries } from '../../../../../utils/dropdownOptions';

const ContactInformation: React.FunctionComponent<any> = () => {
    const { handleOnChange, handleDropdownChange, state, contactInformationRef } = React.useContext<any>(FormContext);

    return (
        <div ref={contactInformationRef}>
            <Header dividing content='Contact Information' />
            <Form.Group widths='2'>
                <Form.Field width='8'>
                    <Label className='no-background-label' content='Company Name' />
                    <Form.Input error={!state?.companyName} required name='companyName' placeholder='Company Name' value={state?.companyName} onChange={handleOnChange} />
                    {(!state?.companyName) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field width='8'>
                    <Label className='no-background-label' content='Contact:' />
                    <Form.Input error={!state?.contact} required name='contact' placeholder='Contact' value={state?.contact} onChange={handleOnChange} />
                    {(!state?.contact) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width='16'>
                    <Label className='no-background-label' content='Website URL:' />
                    <Form.Input name='websiteURL' placeholder='Website URL' value={state?.websiteURL} onChange={handleOnChange} />
                </Form.Field>
            </Form.Group>

            <Form.Group widths='2'>
                <Form.Field width='12'>
                    <Label className='no-background-label' content='Address:' />
                    <Form.Input name='contactInformationStreetAddress' placeholder='Street Address' value={state?.contactInformationStreetAddress} onChange={handleOnChange} />
                </Form.Field>
                <Form.Field width='4'>
                    <Label className='no-background-label' style={{ backgroundColor: 'transparent' }}>&nbsp;</Label>
                    <Form.Input  name='contactInformationApt' placeholder='Apt #' value={state?.contactInformationApt} onChange={handleOnChange} />
                </Form.Field>
            </Form.Group>
            <Form.Group widths='3'>
                <Form.Field >
                    <Label className='no-background-label' content='City' />
                    <Form.Input name='contactInformationCity' placeholder='City' value={state?.contactInformationCity} onChange={handleOnChange} />
                </Form.Field>
                <Form.Field>
                    <Label className='no-background-label' content='State' />
                    <Dropdown name='contactInformationState' selection placeholder='State' options={states} value={state?.contactInformationState?.value} onChange={handleDropdownChange} />
                </Form.Field>
                <Form.Field >
                    <Label className='no-background-label' content='Country' />
                    <Dropdown name='contactInformationCountry' selection placeholder='Country' options={countries} value={state?.contactInformationCountry?.value} onChange={handleDropdownChange} />
                </Form.Field>
                <Form.Field >
                    <Label className='no-background-label' content='Zip Code' />
                    <Form.Input placeholder='Zip Code' name='contactInformationZipcode' value={state?.contactInformationZipcode} onChange={handleOnChange} />
                </Form.Field>
            </Form.Group>
            <Form.Group widths='2'>
                <Form.Field>
                    <Label className='no-background-label' content='Email' />
                    <Form.Input error={!state?.contactInformationEmail} required placeholder='Email' name='contactInformationEmail' value={state?.contactInformationEmail} onChange={handleOnChange} />
                    {(!state?.contactInformationEmail) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field>
                    <Label className='no-background-label' content='Phone' />
                    <Form.Input error={!state?.contactInformationPhone} required placeholder='Phone' name='contactInformationPhone' value={state?.contactInformationPhone} onChange={handleOnChange}/>
                    {(!state?.contactInformationPhone) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
            </Form.Group>

        </div>
    );
}

export default ContactInformation;