// Packages
import React, { useReducer, useEffect } from "react";
import { decode } from "jsonwebtoken";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

// Context Components
import UserContext from "../Context/context";
import UserReducer from "../Reducers/Reducer";
import {
  GET_USER,
  SET_USER,
  SET_LOADING,
  SET_ERROR,
  SET_ERROR_MODAL,
  LOGOUT_USER
} from "../Types/types";
// User State Component
const UserState = props => {
  // The initial state before reducer.
  const initialState = {
    user: null,
    login: false,
    isReset: 0,
    loading: false,
    error: false,
    errorModal: false
  };

  // Use Reducer Hook
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const setLoading = loading => {
    if (loading) {
      dispatch({ type: SET_LOADING, payload: loading });
    } else {
      dispatch({ type: SET_LOADING, payload: true });
    }
  };

  const setError = error => dispatch({ type: SET_ERROR, payload: error });
  const setErrorModal = () => dispatch({ type: SET_ERROR_MODAL });

  /**
   * @name GetUser
   * @module Login.js
   * @param {String} username - Username from form submission. (Login page)
   * @param {String} password - Password from form submission. (Login page)
   * @desc - (Login Page) - Logs in the user and sets Json web tokens in local storage.
   * @returns {dispatch} - dispatches response to the reducer.
   */
  const getUser = async (username, password) => {
    try {
      // Set loading
      setLoading(true);

      // Create body var.
      const body = {
        userlogin: username,
        password
      };

      // Axios response
      let response = await axios({
        method: "POST",
        url: "api/auth/login",
        data: body
      });

      // If error
      if (response.data.errors) {
        setErrorModal();
        toast.error(response.data.errors[0].message);

        return setError(response.data.errors[0].message);
      } else if (response.data.Error) {
        setErrorModal();
        toast.error(response.data.Error);
        return setError("ERROR");
      } else {
        // Decode the jwt
        const token = await decode(response.data.token);
        // De-structure the user object.
        const { user } = token;
        // Set both tokens to local storage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        // Send user, & axios response to reducer.
        return dispatch({
          type: GET_USER,
          payload: { response: response.data, user }
        });
      }
    } catch (err) {
      console.log(err.message);
      return setError(err.message);
    }
  };

  /**
   * @name CheckUser - Set the user into state with the local storage token.
   * @module App.js
   * @desc - Checks if the token exists in local storage, and if not logout the user.
   * @returns {dispatch} - Dispatches the user to the reducer.
   */
  const checkUser = () => {
    let token: any = localStorage.getItem("token");
    if (token) {
      token = decode(token);
      dispatch({ type: SET_USER, payload: token.user });
    } else {
      logoutUser(null);
    }
  };

  // ==============================
  // JWT Authentication
  // ==============================

  /**
   * @name IsAuthenticated
   * @module App.js
   * @desc Used in Private Route component - Calls functions to check if the user's tokens have expired and force a logout.
   * @returns {boolean}
   */
  const isAuthenticated = () => {
    try {
      let token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");

      if (token && refreshToken) {
        checkIfTokensExpired(token, refreshToken);
        checkLoggedInProp();

        token = decode(token);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
      return logoutUser(err);
    }
  };

  /**
   * @name CheckIfTokensExpired
   * @module App.js
   * @param {object} Token - The token from local storage.
   * @param {object} RefreshToken - The second token which if expired, will invalidate both tokens
   * @desc If both tokens have expired, will force user logout. Otherwise, the refresh token (if valid) will create a new first token.
   * @returns {null} - Sets new tokens in local storage.
   */
  const checkIfTokensExpired = (token, refreshToken) => {
    // TODAYS DATE IN MOMENT JS
    const unixToday = Date.now().valueOf() / 1000;
    const todaysDate = moment.unix(unixToday);

    // Decodes the token into a json object.
    const decodedToken = decode(token);

    //The token's expiration date.
    const tokenExpires = moment.unix(decodedToken.exp);

    // Decodes refresh token into json object.
    const decodedRefreshToken = decode(refreshToken);

    // The refresh token's expiration date.
    const refreshTokenExpires = moment.unix(decodedRefreshToken.exp);

    // Check if the FIRST token has expired.
    if (todaysDate >= tokenExpires) {
      // If the FIRST token has expired, fetch and replace the FIRST token.
      fetch("api/auth/checkUser", {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
          "x-refresh-token": refreshToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer" // no-referrer, *client
      })
        .then(data => data.json())
        .then(data => {
          localStorage.setItem("token", data.token);
          localStorage.setItem("refreshToken", data.refreshToken);
        })
        .catch(error => console.error(error.message));
    }

    // Check if the SECOND token has expired.
    if (todaysDate >= refreshTokenExpires) {
      // If the refresh token has expired, both tokens are destroyed and the user is logged out.
      logoutUser(null);
    } else {
      // Both tokens are still valid.
    }
  };

  // Check user login.
  const checkLoggedInProp = () => {
    // if (!login && !user) throw new Error("User is not logged in");
  };

  /**
   * @name LogoutUser
   * @param {error} - Error object.
   * @desc Clears local storage removing all tokens and also logs out user in redux state.
   * @returns {boolean} - false
   */
  const logoutUser = err => {
    localStorage.clear();
    return dispatch({ type: LOGOUT_USER });
  };

  // CONSIDER USE EFFECT HOOK
  // Error handling
  // componentDidCatch(error, info) {
  //  setState({ hasError: true, error: error, info: info });
  // }

  /**
   * @name CHECK_USER_PERMISSIONS
   * @param {*} token
   * @param {*} refreshToken
   * @desc Checks the users permissions and defines CASL limitations. (not currently in use)
   */
  // Check the user's permissions
  // const checkUserPermissions = async () => {
  //   const variables = { employeeId: props.user.id };
  //   try {
  //     const permissions = await getEmployeePermissions(
  //       SequelizeClient,
  //       variables
  //     );
  //     setState({
  //       Permissions: _.merge(
  //         state.Permissions,
  //         permissions.getEmployeePermissions
  //       )
  //     });
  //   } catch (error) {
  //     setState({ error: error });
  //   }
  // };


  // RETURN THE PROVIDER WHICH WILL BE IMPORTED INTO APP.JS AS GITHUBSTATE
  //THE PROVIDER HAS TO WRAP AROUND THE ENTIRE APP
  return (
    <UserContext.Provider
      value={{
    // State
    user: state.user,
      login: state.login,
        isReset: state.isReset,
          error: state.error,
            errorModal: state.errorModal,
              loading: state.loading,
                // Functions
                getUser,
                checkUser,
                logoutUser,
                isAuthenticated,
      }
}>
   { props.children }
  </UserContext.Provider>
  );
};

export default UserState;
