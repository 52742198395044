import React, { useRef, useEffect, useState } from 'react';
import { Container, Grid, Segment, Header, Label, Form, TextArea, Button, Icon, List, Divider, Progress } from 'semantic-ui-react';

// Component
const RevisionPage = ({ id, title, details, currentFile, uploading, requirements, error, successful, files, upload, cancel, index, fileOnChange, handlePageTitleOnChange, handlePageDetailsOnChange, fileUpload, cancelFileUpload, removeFile }) => {

    const chooseFileRef = useRef(null);

    return (
        <Grid.Column>
            <Segment className='height-100' textAlign='left'>
                <Header dividing content={`Page #${index + 1}`} as='h3' />

                <Form.Field data-pageid={id}>
                    {/* Needed padding because it was pushing out a little bit */}
                    <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='Page title or link to page:' />
                    <Form.Input placeholder='Page title / link' name={`${id}[title]`} data-pageid={id} onChange={handlePageTitleOnChange} value={title} />
                </Form.Field>

                <Form.Field data-pageid={id}>
                    <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='Details to update: ' />
                    <TextArea placeholder='Details to update' name={`${id}[details]`} data-pageid={id} value={details} onChange={handlePageDetailsOnChange} />
                </Form.Field>

                <Form.Field>
                    <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='File Upload' />
                    <br />
                    <input
                        data-pageid={id}
                        type='file'
                        id='file'
                        name={`${id}[file]`}
                        ref={chooseFileRef}
                        onChange={fileOnChange}
                        style={{
                            opacity: '0',
                            position: 'absolute',
                            pointerEvents: 'none',
                            // alternative to pointer-events, compatible with all browsers, just make it impossible to find
                            width: '1px',
                            height: '1px',
                        }} />
                    <Button basic size='small' htmlFor='file' type='button' data-pageid={id} onClick={() => chooseFileRef?.current?.click?.()}>
                        <Icon name='paperclip' />
                    Choose File
                    </Button>

                Files must be smaller than 10MB
        </Form.Field>
                <List animated verticalAlign='middle' celled>
                    <List.Item>
                        <Divider />
                        {
                            (currentFile)
                                ? <div style={{ float: 'right' }} className='content'>
                                    <Button basic size='tiny' type='button' data-pageid={id} onClick={cancelFileUpload} >cancel</Button>
                                    <Button size='tiny' color='blue' type='button' name={`${id}upload`} data-pageid={id} onClick={fileUpload} >
                                        <Icon name='upload' />upload</Button>
                                </div>
                                : ''
                        }

                        <div className='content content-upload-fix'>{(currentFile) ? currentFile?.name : ''}</div>
                        <Divider />

                        <Progress style={{ marginBottom: '0px' }} active size='small' percent={uploading} />
                        <Container fluid textAlign='center'>
                            <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='Uploading Files' />
                        </Container>

                        <Progress style={{ marginBottom: '0px' }} size='small' warning percent={requirements} />
                        <Container textAlign='center'>
                            <Label style={{ backgroundColor: 'transparent' }} content="Your file didn't meet the requirements." />
                        </Container>

                        <Progress style={{ marginBottom: '0px' }} size='small' error percent={error} />
                        <Container textAlign='center'>
                            <Label style={{ backgroundColor: 'transparent' }} content="There was an error." />
                        </Container>

                        <Progress style={{ marginBottom: '0px' }} size='small' success percent={successful} />
                        <Container textAlign='center'>
                            <Label style={{ backgroundColor: 'transparent' }} content="Upload Successful!" />
                        </Container>
                    </List.Item>
                </List>

                <List animated relaxed divided verticalAlign='middle'>
                    {files?.map(file => { return <FileListItem file={file} pageId={id} removeFile={removeFile} /> })}
                </List>
            </Segment>
        </Grid.Column>
    );
};


const FileListItem = ({ file, pageId, removeFile }: any) => {

    return (
        <List.Item data-fileid={file?.name}>
            <div className='content' style={{ float: 'right' }}>
                <Button size='mini' compact basic={true} color='red' type='button' content='remove' data-pageid={pageId} data-filename={file?.name} onClick={removeFile} />
            </div>

            <div className='content content-list-fix' style={{ float: 'left' }}>
                <Icon name='file' />
                {file?.name}
            </div>
        </List.Item>
    );
};


export default RevisionPage;