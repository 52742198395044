import React, { useEffect, useState, useContext } from 'react';

import { _InitialRealEstateMRState } from '../State/initialState';

import axios from 'axios';


import { rootURL } from '../../../config/config';

import { toast } from 'react-toastify';

import { useAfterFormSubmit } from '../../../customHooks/afterFormSubmit';
import { IRealEstateMarketingReviewState } from '../Interfaces/Interfaces';

import UserContext from '../../../context/UserContext/Context/context';

const RealEstateMarketingReviewContainer = (props) => {

    const { user } = useContext(UserContext)

    const [state2, setState] = useState<IRealEstateMarketingReviewState>(_InitialRealEstateMRState);

    // custom hooks
    const [afterFormSubmit] = useAfterFormSubmit();

    const validateForm = () => {
        const formValidated = Object.keys(state2).every((key) => {
            if (state2[key] === undefined) {
            }
            return state2[key] !== undefined
        }) && state2?.keywordApprovalTime;
        return formValidated;
    };

    useEffect(() => {
        console.log('State: ', state2);
    }, [state2]);

    const onSubmit = () => {

        try {
            const formValidated = validateForm();
            if (!formValidated) {
                throw new Error('Fill in the missing fields!');
            }

            setState((prevState) => Object.assign({}, { ...prevState, formDisabled: true }));

            const form = document.querySelector('#RealEstateMarketingReviewForm') as HTMLFormElement;
            const formData = new FormData(form);

            // Set client ID
            formData.append('clientID', user?.accountId);

            axios.post(`${rootURL}/realEstateMarketingReview/createRealEstateMarketingReviewEmail`, formData)
                .then(result => afterFormSubmit())
                .catch(error => {
                    toast.error(error.message);
                    console.warn('Error: ', error)
                });

        } catch (error) {
            toast.error(error.message);
        }

    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prevState) => Object.assign({}, { ...prevState, [name]: value }));
    };

    const onDateChange = (date: Date) => setState((prevState) => Object.assign({}, { ...prevState, keywordApprovalTime: date }))

    const onCheckboxClick = (checkedValue: undefined | boolean) => (_, { checked, name }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: (checkedValue !== undefined) ? checkedValue : checked }))
    const onReferredAsClick = (_, { checked, name }) => setState((prevState) => Object.assign({}, {
        ...prevState,
        referredAs_broker: false,
        referredAs_realEstateAgent: false,
        referredAs_realtor: false,
        referredAs_other: false,
        [name]: checked,
    }))

    return props.children({ onSubmit, onChange, onDateChange, onCheckboxClick, onReferredAsClick, state2 })

}

export default RealEstateMarketingReviewContainer;