import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, FormField } from 'semantic-ui-react';

// Interfaces
import { ICheckboxProps } from '../../../Interfaces/Interfaces';

const FormFieldCheckbox: React.FunctionComponent<ICheckboxProps> =  (props) => {

    return (
        <Form.Field error={props.error}>
            <Form.Checkbox {...props} />
        </Form.Field>
    );
};

FormFieldCheckbox.propTypes = {
    label: PropTypes.element.isRequired,
};

FormFieldCheckbox.defaultProps = {
    radio: true,
};

export default FormFieldCheckbox;
