import React, { useContext, useEffect, useReducer, useState } from 'react';

import { _initialState } from '../state/initialState';
import { IBusinessMarketingReviewState } from '../Interfaces/Interfaces';

import axios from 'axios';

// Context
import { FormContext } from '../../HOC/FormHOC/context';

// config
import { rootURL } from '../../../config/config';

import { toast } from 'react-toastify';

import { useAfterFormSubmit } from '../../../customHooks/afterFormSubmit';

import UserContext from '../../../context/UserContext/Context/context';

const BusinessMarketingReviewContainer = (props) => {

    const { user } = useContext(UserContext);

    const [state2, setState] = useState<IBusinessMarketingReviewState>(_initialState)

    const [afterFormSubmit] = useAfterFormSubmit();

    const handleOnSubmit = () => {
        const form = document.querySelector('#BusinessMarketingReviewForm') as HTMLFormElement;
        const formData = new FormData(form);

        // Add clientid
        formData.set('clientID', user?.accountId);

         setState((prevState) => Object.assign({}, { ...prevState, disableForm: true }));

        axios.post(`${rootURL}/businessMarketingReview/createBusinessMarketingReviewEmail`, formData, {
            headers: {
                'x-token': localStorage.getItem('token'),
                'x-refresh-token': localStorage.getItem('refreshToken'),
            }
        })
            .then(result => afterFormSubmit())
            .catch(error => {
                toast.error(error.message);
                console.warn('Error: ', error)
            })
    };

    const handleOnChange = ({ target: { value } }, { name }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: value }))

    const handleCheckboxOnClick = (checkedValue: undefined | boolean) => (_, { checked, name }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: (checkedValue !== undefined) ? checkedValue : checked }))
    const isAreaOfIntrestChecked = (name: string) => (state2?.areasOfIntrest as [any])?.includes(name);

    const handleDateOnChange = (date) => setState((prevState) => Object.assign({}, { ...prevState, keywordApprovalTime: date }));

    return (
        <FormContext.Provider value={{ handleOnChange }}>
            {props.children({ handleOnSubmit, handleDateOnChange, handleOnChange, handleCheckboxOnClick, isAreaOfIntrestChecked, state2 })}
        </FormContext.Provider>
    );
}

export default BusinessMarketingReviewContainer;