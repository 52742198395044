import React from 'react';
import { Form, Header, Grid, Message, Segment } from 'semantic-ui-react';

// Date picker
import DatePicker from 'react-datepicker';

// Interfaces
import { IRealEstateMarketingReviewState } from './Interfaces/Interfaces';


// Container
import RealEstateMarketingReviewContainer from './Container/RealEstateMarketingReviewContainer';

// Reusable components
import FormHeader from '../ReusableComponents/FormHeader/FormHeader';
import ContactHeaderBar from "../ReusableComponents/ContactHeaderBar/ContactHeaderBar";
import Note from '../ReusableComponents/Note/Note';
import DoubleFormSegment from '../ReusableComponents/DoubleFormSegment/DoubleFormSegment';
import FormFieldInput from '../ReusableComponents/FormFieldInput/FormFieldInput';
import FormFieldCheckbox from '../ReusableComponents/FormFieldCheckbox/FormFieldCheckbox';
import WarningLabel from '../ReusableComponents/WarningLabel/WarningLabel';
import SegmentHeader from '../ReusableComponents/SegmentHeader/SegmentHeader';
import FormTextArea from '../ReusableComponents/FormTextArea/FormTextArea';
import RenderWrapper from '../ReusableComponents/RenderWrapper/RenderWrapper';

const RealEstateMarketingReview = (props) => {

    return (
        <RealEstateMarketingReviewContainer>
            {
                ({ onSubmit, onDateChange, onChange, onCheckboxClick, onReferredAsClick, state2 }: { state2: IRealEstateMarketingReviewState, onSubmit: (arg0: any) => any, onChange: (arg0: any) => any, onDateChange: (arg0: any) => true, onCheckboxClick: (data?: any) => any, onReferredAsClick: (data: any) => any }) =>
                    <>
                        <ContactHeaderBar Header='Real Estate Marketing Review' />
                        <FormHeader
                            header='Real Estate Marketing Review Form'
                            message={<p>The purpose of your marketing review is to help us make sure that we know a little more about you and your business. We will be asking you a series of questions which is very helpful to us in every step of the process with researching key term topics and the custom content writing.</p>}
                        />
                        <Form id='RealEstateMarketingReviewForm' onSubmit={onSubmit} style={{ textAlign: 'left' }}>



                            {/* ============================================= (START) REAL ESTATE MARKETING REVIEW ============================================================= */}

                            <DoubleFormSegment
                                leftColumn={
                                    <>
                                        <Header dividing content={'Contact Information'} textAlign="left" as='h2' />

                                        <Form.Group style={{ textAlign: 'left' }} widths='2'>
                                            <FormFieldInput
                                                required={true}
                                                label={<label>Customer Service Rep Name: </label>}
                                                name='customerServiceRepName'
                                                placeholder='Customer Service Rep Name'
                                                warningLabelProps={{
                                                    content: "Please enter 'CS Rep. Name'",
                                                }}
                                                onChange={onChange}
                                                value={state2?.customerServiceRepName}
                                            />

                                            <FormFieldInput
                                                required
                                                label={<label>Name: </label>}
                                                name='name'
                                                placeholder='Name'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Name'.",
                                                }}
                                                onChange={onChange}
                                                value={state2?.name}
                                            />
                                        </Form.Group>

                                        <FormFieldInput
                                            required
                                            label={<label>Email:</label>}
                                            name='email'
                                            type='email'
                                            placeholder='Email'
                                            warningLabelProps={{
                                                content: "Please enter a 'Email '.",
                                            }}
                                            onChange={onChange}
                                            value={state2?.email}
                                        />
                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                type='number'
                                                required
                                                label={<label>Phone (to be used on site): </label>}
                                                name='phone'
                                                placeholder='Phone'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Phone number'.",
                                                }}
                                                onChange={onChange}
                                                value={state2?.phone}
                                            />
                                            <FormFieldInput
                                                type='number'
                                                label={<label>Secondary Phone (to be used on site): </label>}
                                                name='secondaryPhone'
                                                placeholder='Secondary Phone'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Secondary Phone number'.",
                                                }}
                                                onChange={onChange}
                                                value={state2?.secondaryPhone}
                                            />
                                        </Form.Group>
                                        <FormFieldInput
                                            label={<label>Hours of Operation </label>}
                                            name='hoursOfOperation'
                                            placeholder='Hours of Operation'
                                            warningLabelProps={{
                                                content: "Please enter a 'Hours of Operation'.",
                                            }}
                                            onChange={onChange}
                                            value={state2?.hoursOfOperation}
                                        />
                                        <FormFieldInput
                                            label={<label>How do you want to be referred to? (I, us, we, group, etc.)</label>}
                                            name='referredTo'
                                            placeholder='How do you want to be referred to'
                                            warningLabelProps={{
                                                content: "Please enter a 'How do you want to be referred to'.",
                                            }}
                                            onChange={onChange}
                                            value={state2?.referredTo}
                                        />
                                        <Form.Group className={''} grouped style={{ textAlign: 'left' }}>
                                            <label>What do you want to be referred to as? <span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_broker} data-testid='ReferredAsBroker' radio label={<label>Broker</label>} name='referredAs_broker' value='Broker' />
                                            <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_realEstateAgent} data-testid='ReferredAsRealEstateAgent' radio label={<label>Real Estate Agent</label>} name='referredAs_realEstateAgent' value='Real Estate Agent' />
                                            <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_realtor} data-testid='ReferredAsRealtor' radio label={<label>REALTOR&reg;</label>} name='referredAs_realtor' value='Realtor' />
                                            <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_other} data-testid='ReferredAsOther' radio label={<label>Other</label>} name='referredAs_other' value='Other' />

                                            <FormFieldInput label={<label>Other: </label>} name='referredAs_otherInput' placeholder='Other' disabled={(!state2?.referredAs_other)} onChange={onChange} value={state2?.referredAs_otherInput} />
                                        </Form.Group>
                                    </>
                                }
                                rightColumn={<>
                                    <Grid.Column style={{ textAlign: 'left' }}>
                                        <Header dividing content={'Social Media Information'} as='h2' />
                                        <Message>
                                            <strong>Are you registered with any of the following?</strong> If so, please list
              your existing link.
            </Message>

                                        {/* TODO: These components are uncontrolled */}

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>Facebook</label>}
                                                name='facebook'
                                                placeholder='Facebook Link'
                                                onChange={onChange}
                                                error={false}
                                            />
                                            <FormFieldInput
                                                label={<label>Instagram</label>}
                                                name='instagram'
                                                placeholder='Instagram Link'
                                                onChange={onChange}
                                                error={false}
                                            />
                                        </Form.Group>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>Twitter: </label>}
                                                name='twitter'
                                                placeholder='Twitter Link'
                                                onChange={onChange}
                                                error={false}
                                            />
                                            <FormFieldInput
                                                label={<label>YouTube: </label>}
                                                name='youTube'
                                                placeholder='YouTube Link'
                                                onChange={onChange}
                                                error={false}
                                            />
                                        </Form.Group>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>LinkedIn: </label>}
                                                name='linkedIn'
                                                placeholder='LinkedIn Link'
                                                onChange={onChange}
                                                error={false}
                                            />
                                            <FormFieldInput
                                                label={<label>Third-party Blog: </label>}
                                                name='blog'
                                                placeholder='Blog Link'
                                                onChange={onChange}
                                                error={false} />
                                        </Form.Group>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>Skype: </label>}
                                                name='skype'
                                                placeholder='Skype Link'
                                            //    onChange={onChange}
                                            />
                                        </Form.Group>

                                    </Grid.Column></>}

                            />
                            {/* ============================================= (END) REAL ESTATE MARKETING REVIEW =============================================================== */}



                            {/* ===========================================================================(START) WEBSITE INFORMAITON ==================================================================================================== */}
                            <Segment textAlign='left' id='WebsiteInformation'>
                                {/* TODO: Create reusable component, possibly for grid with segment and header */}
                                <Grid>
                                    <Grid.Column className='margin-padding-fix'>
                                        <Header dividing content='Website Information' as='h2' />
                                    </Grid.Column>
                                </Grid>
                                <Grid columns={2}>

                                    <Grid.Column>
                                        <FormFieldInput
                                            name='clientWebsite'
                                            label={<label>Client Website: </label>}
                                            placeholder='Client Website'
                                            warningLabelProps={{ content: "Please enter 'Client ID'." }}
                                            onChange={onChange}
                                            value={state2?.clientWebsite}
                                        />

                                        <FormFieldInput
                                            name='originalWebsite'
                                            label={<label>How long have you owned your original website?
                        <span className="color-red-regular">*</span></label>}
                                            placeholder='How long have you owned your original website?'
                                            warningLabelProps={{
                                                className: 'center-fix',
                                                content: 'Please add some details.'
                                            }}
                                            onChange={onChange}
                                            value={state2?.originalWebsite}
                                        />
                                        <Form.Group grouped error={state2?.additionalWebsitesList === undefined}>
                                            <label>Do you have additional websites relevant to this business?<span className="color-red-regular">*</span></label>
                                            <Form.Checkbox data-testid='AdditionalWebsitesYesCheckbox' radio label='Yes' name='additionalWebsites' value='Yes' checked={state2?.additionalWebsites} onClick={onCheckboxClick(true)} />
                                            <Form.Checkbox data-testid='AdditionalWebsitesNoCheckbox' radio label='No' name='additionalWebsites' value='No' checked={(!state2?.additionalWebsites && state2?.additionalWebsites !== undefined)} onClick={onCheckboxClick(false)} />
                                        </Form.Group>
                                        {
                                            (state2?.additionalWebsites)
                                                ? <FormTextArea
                                                    disabled={false}
                                                    label={<label>Please list them here: </label>}
                                                    name='additionalWebsitesList'
                                                    placeholder='Additional websites'
                                                    rows={8}
                                                    onChange={onChange}
                                                    value={state2?.additionalWebsitesList}
                                                />
                                                : ''
                                        }
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Group grouped error={state2?.customURL === undefined}>
                                            <label>URL Requested?<span className="color-red-regular">*</span></label>
                                            <Form.Checkbox data-testid='URLRequestedYesCheckbox' radio label='Yes' name='customURL' value='Yes' checked={state2?.customURL} onClick={onCheckboxClick(true)} />
                                            <Form.Checkbox data-testid='URLRequestedNoCheckbox' radio label='No' name='customURL' value='No' checked={(!state2?.customURL && state2?.customURL !== undefined)} onClick={onCheckboxClick(false)} />
                                        </Form.Group>
                                        <Message>
                                            <strong>What URL you're interested in or a general idea of what it could be.</strong>
                                            <br />
                                            <em> (ex. "something that contains Texas" or "my current domain name but with .net")</em>
                                            <br /><br />
                        We will try to find availability from the top of your list downwards.
                    </Message>
                                        {
                                            (state2?.customURL)
                                                ? <FormTextArea
                                                    disabled={false}
                                                    label={<label>Please enter your suggestions here: </label>}
                                                    name='customURLSuggestions'
                                                    placeholder='Custom URL'
                                                    rows={8}
                                                    onChange={onChange}
                                                    value={state2?.customURLSuggestions}
                                                />
                                                : ''
                                        }
                                    </Grid.Column>

                                </Grid>
                            </Segment>
                            {/* ===========================================================================(END) WEBSITE INFORMAITON ==================================================================================================== */}


                            {/* ============================================================================================== (START) BUSINESS INFORMATION =============================================================================================*/}
                            <Segment textAlign='left' id='BusinessInformation'>
                                <Grid>
                                    <Grid.Column className='margin-padding-fix'>
                                        <Header dividing content='Business Information' as='h2' />
                                    </Grid.Column>
                                </Grid>
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Form.Group grouped>
                                            <label>Do you stand alone in your business?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox data-testid='StandAloneYes' radio label='Yes' name='standAlone' value='Yes' checked={state2?.standAlone} onClick={onCheckboxClick(true)} />
                                            <FormFieldCheckbox data-testid='StandAloneNo' radio label='No' name='standAlone' value='No' checked={(!state2?.standAlone && state2?.standAlone !== undefined)} onClick={onCheckboxClick(false)} />
                                            <WarningLabel visable={(state2?.standAlone === undefined)} />
                                        </Form.Group>
                                        <Form.Group grouped>
                                            <label>Are you the owner of the business?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox data-testid='BusinessOwnerYes' radio label='Yes' name='businessOwner' value='Yes' checked={state2?.businessOwner} onClick={onCheckboxClick(true)} />
                                            <FormFieldCheckbox data-testid='BusinessOwnerNo' radio label='No' name='businessOwner' value='No' checked={(!state2?.businessOwner && state2?.businessOwner !== undefined)} onClick={onCheckboxClick(false)} />
                                            <WarningLabel visable={(state2?.businessOwner === undefined)} />
                                        </Form.Group>
                                        {
                                            (state2?.businessOwner)
                                                ? <FormFieldInput
                                                    id='HowLongHaveYouOwnedYourBusiness'
                                                    label={<label>How long have you owned your business?</label>}
                                                    placeholder='How long have you owned your business'
                                                    warningLabelProps={{ content: 'Please enter details' }}
                                                    error={false}
                                                    onChange={onChange}
                                                    name='timeOwningBusiness'
                                                    value={state2?.timeOwningBusiness}
                                                />
                                                : ''
                                        }

                                        <FormFieldInput
                                            id='BrokerageOrCompanyName'
                                            label={<label>What brokerage or company do you work for?</label>}
                                            placeholder='Name of brokerage or company'
                                            warningLabelProps={{ content: 'Please enter details' }}
                                            onChange={onChange}
                                            name='brokerageOrCompany'
                                            value={state2?.brokerageOrCompany}
                                        />
                                        <Form.Group grouped>
                                            <label>Are you a Broker?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox data-testid='BrokerYes' radio label='Yes' name='broker' value='Yes' checked={state2?.broker} onClick={onCheckboxClick(true)} />
                                            <FormFieldCheckbox data-testid='BrokerNo' radio label='No' name='broker' value='No' checked={(!state2?.broker && state2?.broker !== undefined)} onClick={onCheckboxClick(false)} />
                                            <WarningLabel visable={(state2?.broker === undefined)} />
                                        </Form.Group>

                                        {/* <Form.Group grouped id='ReferredAs'>
                                            <label>What do you want to be referred to as?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox label={<label>Broker</label>} checked={state2?.} name='broker' />
                                            <FormFieldCheckbox label={<label>Real Estate Agent</label>} checked={false} name='realEstateAgent' />
                                            <FormFieldCheckbox label={<label>Realtor</label>} checked={false} name='realtor' />
                                            <FormFieldCheckbox label={<label>Other</label>} checked={false} name='other_checkbox' />
                                            <WarningLabel />
                                            <FormFieldInput
                                                disabled={false}
                                                id='Other'
                                                label={<label>Other</label>}
                                                placeholder='Other'
                                                name='other'
                                                warningLabelProps={{ content: 'Please enter details' }}
                                                error={false}
                                                onChange={onChange}
                                            />
                                        </Form.Group> */}
                                        <Form.Group grouped id='HowDoYouWantToSoundInContent' className={(false) ? 'error' : ''}>
                                            <label>How do you want to sound in the content?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox data-testid='ContentSingular' radio label={<label>Singular ( I, me, my, etc)</label>} value='Singular' name='content_singular' checked={state2?.content_singular} onClick={onCheckboxClick()} />
                                            <FormFieldCheckbox data-testid='ContentGroup' radio label={<label>Part of a group ( we, us, team, etc)</label>} value='Group' name='content_group' checked={state2?.content_group} onClick={onCheckboxClick()} />
                                            <FormFieldCheckbox data-testid='ContentOther' radio label={<label>Other</label>} name='content_other' value='Other' checked={state2?.content_other} onClick={onCheckboxClick()} />
                                            <WarningLabel visable={(!state2?.content_singular && !state2?.content_group && !state2?.content_other)} />
                                            {
                                                (state2.content_other)
                                                    ? <>
                                                        <FormTextArea label={<label>Other</label>} name="singleFamily_zipCodes" value={state2?.content_otherInput} onChange={onChange} />
                                                    </>
                                                    : ''
                                            }
                                        </Form.Group>
                                    </Grid.Column>

                                    {/* Second Column */}
                                    <Grid.Column>
                                        <FormFieldInput
                                            id='RealEstateAgentNames'
                                            label={<label>Real Estate Agent name(s) to be used on the website:</label>}
                                            name="realEstateAgentNames"
                                            placeholder="Real Estate Agent name(s)"
                                            warningLabelProps={{ content: 'Please enter details' }}
                                            onChange={onChange}
                                            value={state2?.realEstateAgentNames}
                                        />

                                        <Form.Group grouped>
                                            <label>Do you need your license number displayed?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox data-testid='DisplayLicenseNumberYes' radio label='Yes' name='displayLicenseNumber' value='Yes' checked={state2?.displayLicenseNumber} onClick={onCheckboxClick(true)} />
                                            <FormFieldCheckbox data-testid='DisplayLicenseNumberNo' radio label='No' name='displayLicenseNumber' value='No' checked={(!state2?.displayLicenseNumber && state2?.displayLicenseNumber !== undefined)} onClick={onCheckboxClick(false)} />
                                            <WarningLabel visable={(state2?.displayLicenseNumber === undefined)} />

                                            <RenderWrapper isVisable={state2?.displayLicenseNumber}>
                                                <FormFieldInput
                                                    id='LicenseNumber'
                                                    placeholder='License Number'
                                                    label={<label>Enter license number:</label>}
                                                    warningLabelProps={{ content: 'Please enter details' }}
                                                    // error={false}
                                                    name='licenseNumber'
                                                    onChange={onChange} />
                                            </RenderWrapper>

                                        </Form.Group>

                                        <Form.Group grouped >
                                            <label>Do you work with first time home buyers?<span className="color-red-regular">*</span></label>
                                            <FormFieldCheckbox data-testid='FirstTimeYes' radio label='Yes' name='firstTimeHome' value='Yes' checked={state2?.firstTimeHome} onClick={onCheckboxClick(true)} />
                                            <FormFieldCheckbox data-testid='FirstTimeNo' radio label='No' name='firstTimeHome' value='No' checked={(state2?.firstTimeHome === false)} onClick={onCheckboxClick(false)} />
                                            <WarningLabel visable={(state2?.firstTimeHome === undefined)} />
                                            {
                                                (state2?.firstTimeHome)
                                                    ? <>
                                                        <FormTextArea label={<label>List of cities and/or zip codes:</label>} name='firstTimeHome_zipCodes' value={state2?.firstTimeHome_zipCodes} onChange={onChange} /></>
                                                    : ''
                                            }
                                        </Form.Group>

                                        <FormFieldInput
                                            id='PercentageOfBuyersAndSellersKeyPhrases'
                                            label={<label>What percentage of buyers and sellers key phrases would you like us to generate?</label>}
                                            placeholder='Enter percentage (ex. 60/40 buyer/seller)'
                                            warningLabelProps={{ content: 'Please enter details' }}
                                            onChange={onChange}
                                            name='percentageGenerate'
                                            value={state2?.percentageGenerate}
                                        />
                                        <Form.Field id='SpecialNotesKeyphrase'>
                                            <label>Special notes for key phrase generation:</label>
                                            <Form.TextArea placeholder='Special Notes' name="specialNotesKeyphrase" value={state2?.specialNotesKeyphrase} onChange={onChange} rows='9' />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                            </Segment >
                            {/* ============================================================================================== (END) BUSINESS INFORMATION =============================================================================================*/}

                            {/* ============================================================================================== (START) SERVICE AREA INFORMATION =============================================================================================*/}

                            <Segment style={{ textAlign: 'left' }} id='ServiceAreaInformation'>
                                <SegmentHeader header='Service Area Information' />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        {/*  */}
                                        <Message>
                                            <Header dividing as='h3' content='Special areas for real estate:' />
                                            <br />


            These properties usually yield a higher sales commission for you. Not only a higher commission, but people
            are usually ready to purchase.

            <Grid columns='2'>
                                                {/* TODO: Implement conditional error displays */}
                                                <Grid.Column>
                                                    <Form.Group grouped>
                                                        <label>Country Club Communities?<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='CountryClubYes' radio label='Yes' name='countryClub' value='Yes' checked={state2?.countryClub} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='CountryClubNo' radio label='No' name='countryClub' value='No' checked={(state2?.countryClub !== undefined && !state2?.countryClub)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.countryClub === undefined)} />

                                                        {
                                                            (state2?.countryClub)
                                                                ? <><FormTextArea label={<label>List names of the communities: </label>} name='countryClub_communities' value={state2?.countryClub_communities} onChange={onChange} />
                                                                    <FormTextArea label={<label>List of cities and/or zip codes: </label>} name="countryClub_zipCodes" value={state2?.countryClub_zipCodes} onChange={onChange} /></>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>

                                                        <label>Golf Course Communities?<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='GolfCourseYes' radio label='Yes' name='golfCourse' value='Yes' checked={state2?.golfCourse} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='GolfCourseNo' radio label='No' name='golfCourse' value='No' checked={(state2?.golfCourse !== undefined && !state2?.golfCourse)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.golfCourse === undefined)} />
                                                        {
                                                            (state2?.golfCourse)
                                                                ? <>
                                                                    <FormTextArea label={<label>List names of the communities:</label>} name='golfCourse_communities' value={state2?.golfCourse_communities} onChange={onChange} />
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name='golfCourse_zipCodes' value={state2?.golfCourse_zipCodes} onChange={onChange} /></>
                                                                : ''
                                                        }

                                                    </Form.Group>

                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form.Group grouped>


                                                        <label>Gated / Guard Gated Subdivisions?<span className="color-red-regular">*</span></label>
                                                        {/* @ts-ignore */}
                                                        <FormFieldCheckbox name='GatedSubdivisionYes' radio label='Yes' name='gatedSubdivision' value='Yes' checked={state2?.gatedSubdivision} onClick={onCheckboxClick(true)} />
                                                        {/* @ts-ignore */}
                                                        <FormFieldCheckbox name='GatedSubdivisionNo' radio label='No' name='gatedSubdivision' value='No' checked={(state2?.gatedSubdivision !== undefined && !state2?.gatedSubdivision)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.gatedSubdivision === undefined)} />
                                                        {
                                                            (state2?.gatedSubdivision)
                                                                ? <>
                                                                    <FormTextArea label={<label>List names of the communities: </label>} name='gatedSubdivision_communities' value={state2?.gatedSubdivision_communities} onChange={onChange} />
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name='gatedSubdivision_zipCodes' value={state2?.gatedSubdivision_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }

                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Over-50 Communities?<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='OverFifteyYes' radio label='Yes' name='overFiftey' value='Yes' checked={state2?.overFiftey} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='OverFifteyNo' radio label='No' name='overFiftey' value='No' checked={(state2?.overFiftey !== undefined && !state2?.overFiftey)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.overFiftey === undefined)} />

                                                        {
                                                            (state2.overFiftey)
                                                                ? <>
                                                                    <FormTextArea label={<label>List names of the communities:</label>} name='overFiftey_communities' value={state2?.overFiftey_communities} onChange={onChange} />
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="overFiftey_zipCodes" value={state2?.overFiftey_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid>
                                        </Message>
                                        <Message>
                                            <Header dividing content='Areas and special Key Phrase topics for research:' as='h3' />
                                            <Grid columns={2}>
                                                <Grid.Column>
                                                    <Form.Group grouped>
                                                        <label>Single Family<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='singleFamilyYes' radio label='Yes' name='singleFamily' value='Yes' checked={state2?.singleFamily} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='singleFamilyNo' radio label='No' name='singleFamily' value='No' checked={(state2?.singleFamily !== undefined && !state2?.singleFamily)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.singleFamily === undefined)} />

                                                        {
                                                            (state2.singleFamily)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="singleFamily_zipCodes" value={state2?.singleFamily_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Town Homes<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='townHomesYes' radio label='Yes' name='townHomes' value='Yes' checked={state2?.townHomes} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='townHomesNo' radio label='No' name='townHomes' value='No' checked={(state2?.townHomes !== undefined && !state2?.townHomes)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.townHomes === undefined)} />

                                                        {
                                                            (state2.townHomes)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="townHomes_zipCodes" value={state2?.townHomes_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Condos<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='condosYes' radio label='Yes' name='condos' value='Yes' checked={state2?.condos} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='condosNo' radio label='No' name='condos' value='No' checked={(state2?.condos !== undefined && !state2?.condos)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.condos === undefined)} />

                                                        {
                                                            (state2.condos)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="condos_zipCodes" value={state2?.condos_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Commercial<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='commercialYes' radio label='Yes' name='commercial' value='Yes' checked={state2?.commercial} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='commercialNo' radio label='No' name='commercial' value='No' checked={(state2?.commercial !== undefined && !state2?.commercial)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.commercial === undefined)} />

                                                        {
                                                            (state2.commercial)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="commercial_zipCodes" value={state2?.commercial_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Raw Land<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='rawLandYes' radio label='Yes' name='rawLand' value='Yes' checked={state2?.rawLand} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='rawLandNo' radio label='No' name='rawLand' value='No' checked={(state2?.rawLand !== undefined && !state2?.rawLand)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.rawLand === undefined)} />

                                                        {
                                                            (state2.rawLand)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="rawLand_zipCodes" value={state2?.rawLand_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Investment<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='investmentYes' radio label='Yes' name='investment' value='Yes' checked={state2?.investment} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='investmentNo' radio label='No' name='investment' value='No' checked={(state2?.investment !== undefined && !state2?.investment)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.investment === undefined)} />

                                                        {
                                                            (state2.investment)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="investment_zipCodes" value={state2?.investment_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Luxury Homes<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='luxuryYes' radio label='Yes' name='luxury' value='Yes' checked={state2?.luxury} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='luxuryNo' radio label='No' name='luxury' value='No' checked={(state2?.luxury !== undefined && !state2?.luxury)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.luxury === undefined)} />

                                                        {
                                                            (state2.luxury)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="luxury_zipCodes" value={state2?.luxury_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Foreclosures<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='foreclosuresYes' radio label='Yes' name='foreclosures' value='Yes' checked={state2?.foreclosures} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='foreclosuresNo' radio label='No' name='foreclosures' value='No' checked={(state2?.foreclosures !== undefined && !state2?.foreclosures)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.foreclosures === undefined)} />

                                                        {
                                                            (state2.foreclosures)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="foreclosures_zipCodes" value={state2?.foreclosures_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                </Grid.Column>
                                                <Grid.Column>



                                                    <Form.Group grouped>
                                                        <label>Short Sales<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='shortSalesYes' radio label='Yes' name='shortSales' value='Yes' checked={state2?.shortSales} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='shortSalesNo' radio label='No' name='shortSales' value='No' checked={(state2?.shortSales !== undefined && !state2?.shortSales)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.shortSales === undefined)} />

                                                        {
                                                            (state2.shortSales)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="shortSales_zipCodes" value={state2?.shortSales_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Waterfront<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='waterfrontYes' radio label='Yes' name='waterfront' value='Yes' checked={state2?.waterfront} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='waterfrontNo' radio label='No' name='waterfront' value='No' checked={(state2?.waterfront !== undefined && !state2?.waterfront)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.waterfront === undefined)} />

                                                        {
                                                            (state2.waterfront)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="waterfront_zipCodes" value={state2?.waterfront_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Relocation Specialist<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='relocationYes' radio label='Yes' name='relocation' value='Yes' checked={state2?.relocation} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='relocationNo' radio label='No' name='relocation' value='No' checked={(state2?.relocation !== undefined && !state2?.relocation)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.relocation === undefined)} />

                                                        {
                                                            (state2.relocation)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="relocation_zipCodes" value={state2?.relocation_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Staging<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='stagingYes' radio label='Yes' name='staging' value='Yes' checked={state2?.staging} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='stagingNo' radio label='No' name='staging' value='No' checked={(state2?.staging !== undefined && !state2?.staging)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.staging === undefined)} />

                                                        {
                                                            (state2?.staging)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="staging_zipCodes" value={state2?.staging_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Minimal<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='minimalYes' radio label='Yes' name='minimal' value='Yes' checked={state2?.minimal} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='minimalNo' radio label='No' name='minimal' value='No' checked={(state2?.minimal !== undefined && !state2?.minimal)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.minimal === undefined)} />

                                                        {
                                                            (state2.minimal)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="minimal_zipCodes" value={state2?.minimal_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>New Construction<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='newConstructionYes' radio label='Yes' name='newConstruction' value='Yes' checked={state2?.newConstruction} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='newConstructionNo' radio label='No' name='newConstruction' value='No' checked={(state2?.newConstruction !== undefined && !state2?.newConstruction)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.newConstruction === undefined)} />

                                                        {
                                                            (state2.newConstruction)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="newConstruction_zipCodes" value={state2?.newConstruction_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Downsizing<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='downsizingYes' radio label='Yes' name='downsizing' value='Yes' checked={state2?.downsizing} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='downsizingNo' radio label='No' name='downsizing' value='No' checked={(state2?.downsizing !== undefined && !state2?.downsizing)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.downsizing === undefined)} />

                                                        {
                                                            (state2.downsizing)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="downsizing_zipCodes" value={state2?.downsizing_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group grouped>
                                                        <label>Top Schools<span className="color-red-regular">*</span></label>
                                                        <FormFieldCheckbox data-testid='topSchoolsYes' radio label='Yes' name='topSchools' value='Yes' checked={state2?.topSchools} onClick={onCheckboxClick(true)} />
                                                        <FormFieldCheckbox data-testid='topSchoolsNo' radio label='No' name='topSchools' value='No' checked={(state2?.topSchools !== undefined && !state2?.topSchools)} onClick={onCheckboxClick(false)} />
                                                        <WarningLabel visable={(state2?.topSchools === undefined)} />

                                                        {
                                                            (state2.topSchools)
                                                                ? <>
                                                                    <FormTextArea label={<label>List of cities and/or zip codes:</label>} name="topSchools_zipCodes" value={state2?.topSchools_zipCodes} onChange={onChange} />
                                                                </>
                                                                : ''
                                                        }
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid>
                                        </Message>
                                    </Grid.Column>
                                    <Grid.Column>

                                        {/*  */}

                                        <FormTextArea label={<label>Primary Servicing Areas (list in order of importance)</label>} name="primaryServiceAreas" value={state2?.primaryServiceAreas} onChange={onChange} />
                                        <FormTextArea
                                            label={<label>Do you have any listings that you would like to see on search engines that may make it easier for
                    the buyer to find this address? If so, please list below.</label>}
                                            name="buyerFindAddress"
                                            value={state2?.buyerFindAddress}
                                            onChange={onChange}
                                        />
                                        <FormTextArea
                                            label={<label>What would you like to say to capture a clients attention on your website?</label>}
                                            name="captureClientsAttention"
                                            value={state2?.captureClientsAttention}
                                            onChange={onChange}
                                        />
                                        <FormTextArea
                                            label={<label>What is unique about you that separates you from other agents and brokers?</label>}
                                            name="uniqueSeperates"
                                            value={state2?.uniqueSeperates}
                                            onChange={onChange}
                                        />
                                        <FormTextArea
                                            label={<label>Any additional information you would like us to note on the homepage content?</label>}
                                            name="additionalInformation"
                                            value={state2?.additionalInformation}
                                            onChange={onChange}
                                        />
                                        <FormTextArea
                                            label={<label>Special Notes For Content:</label>}
                                            name="specialContentNotes"
                                            value={state2?.specialContentNotes}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid>

                            </Segment>
                            {/* ============================================================================================== (END) SERVICE AREA INFORMATION =============================================================================================*/}
                            <Note header='Note: '>
                                <br />
                    Please keep in mind when our analysts review your account, they will generate certain topics or areas as needed.
                    We must follow certain guidelines set forth by the search engines.
        <br /><br />
                    Any single word may only be used a certain amount of times to avoid content spamming. This includes cities,
                    states, and zipcodes. At this time mention any other services, products, or areas. We must make sure that we do
                    not oversaturate your custom content.
        <br /><br />
                    We want to thank you for taking the time completing your marketing review today! We would like to set your
                    appointment up now for your keyword approval.
        <br /><br />
                    Remember we now have you scheduled in our content and programming departments so it is urgent that you set this
                    time aside. The process should take around 30 to 45 minutes. Please make sure to be at your computer.
                </Note>
                            <Segment>
                                <Grid textAlign='center'>
                                    <Grid.Row>
                                        <Header dividing className='center-fix' content={'Submit Business Marketing Review Form'} as='h2' />
                                    </Grid.Row>
                                    <Grid.Row>
                                        {/* TODO: Setting <Message> component prop warning makes the component invisable  */}
                                        <Grid.Column width='7'>
                                            <Message color='yellow'>
                                                <Message.Header>NOTE FOR CUSTOMER SERVICE REP:</Message.Header>
                                                <p>Once complete, print out the email you get from submitting this form.</p>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Column width='4'>
                                        <Form.Field error={(!state2?.keywordApprovalTime)}>
                                            <label>Keyword Approval Appointment Time: <span className="color-red-regular">*</span> </label>
                                            {/* <Form.Input name="submit[appointmentTime]" placeholder="Appointment Time" {...props} /> */}
                                            <DatePicker
                                                selected={state2?.keywordApprovalTime}
                                                onChange={onDateChange}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                name='date'
                                            // inline
                                            // timeIntervals={15}
                                            // timeCaption="time"
                                            />
                                            <WarningLabel visable={(!state2?.keywordApprovalTime)} content="Please Enter 'Appointment Time' " />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Row>
                                        <Form.Button disabled={state2?.formDisabled} color='green' tabindex='0' content='Submit Form' />
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form>
                    </>

            }
        </RealEstateMarketingReviewContainer>
    );
};

export default RealEstateMarketingReview;
