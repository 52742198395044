import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Header,
  Segment,
  Form,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import axios from "axios";
import { NavLink, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";

import UserContext from "../context/UserContext/Context/context";
import { toast } from "react-toastify";

import { rootURL } from "../config/config";

const Login = ({ user, auth, checkUser, ...props }) => {
  // const { getUser, checkToken, user, isReset }: any = useContext(UserContext);
  const [state, setState] = useState({ username: "", password: "" });

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  useEffect(() => {
    const { token, refToken } = props?.match?.params;
    if (token && refToken) {
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refToken);
      props.history.push("/account");
    }
  }, []);

  // Effect hook for redirects.
  // useEffect(() => {
  //   /**
  //    * @name CheckToken
  //    * @desc Checks the local storage for user token and if found, redirects to home page.
  //    * @returns {redirect}
  //    */
  //   const checkToken = async () => {
  //     let token: any = localStorage.getItem("token");
  //     token = await jwt.decode(token);
  //     if (isReset) {
  //       props.history.push("/passwordupdate");
  //     } else if (token?.user) {
  //       props.history.push("/account");
  //     }
  //   };

  //   checkToken();
  // }, [login]);

  const login = () => {
    const url = "/login";
    axios
      .post(rootURL + url, { ...state })
      .then((result) => {
        if (result.status === 200) {
          const { token, refreshToken } = result?.data || {};
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("refreshToken", result.data.refreshToken);
          props.history.push("/account");
        } else localStorage.clear();
      })
      .catch((error) => {
        toast.error(`Login Unsuccessful: Wrong Credentials`, {
          position: "top-right",
          autoClose: 3000,
        });
        console.log("Error: ", error);
        localStorage.clear();
      });
  };

  React.useEffect(() => {
    //  console.log(Date.now() >= jwt.decode(localStorage.getItem('token')).exp  * 1000)
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  // check if token is not expired, if not, redirect to the account page

  if (
    localStorage.getItem("token") &&
    jwt.decode(localStorage.getItem("token")) &&
    !(jwt.decode(localStorage.getItem("token")).exp < new Date().getTime())
  )
    return <Redirect to="/account" />;

  return (
    <>
      <div className="container with-color">
        <div
          style={{
            textAlign: "left",
            color: "white",
            padding: "15px",
            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          }}>
          <h3>Client Login</h3>
        </div>
        <Segment textAlign="left" className="login-container">
          <Segment padded="very" textAlign="left" className="login">
            <Header textAlign="center" as="h2">
              Please Sign In
            </Header>
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <label>
                  <Icon name="user" />
                  Username
                </label>
                <Form.Input
                  type="text"
                  name="username"
                  required
                  onChange={onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Icon name="user" />
                  Password
                </label>
                <Form.Input
                  type="password"
                  name="password"
                  required
                  onChange={onChange}
                />
              </Form.Field>
              <Button size="massive" fluid color="green">
                Log In
              </Button>
            </Form>
            <NavLink to={{ pathname: "/requestpassword" }}>
              I Forgot My Password
            </NavLink>
          </Segment>
        </Segment>
      </div>
    </>
  );
};

export default withRouter(Login as any);
