import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import moment from 'moment';
import { Modal, Header, Button } from 'semantic-ui-react';
import UserContext from "../context/UserContext/Context/context";
const Footer = props => {
  const { user, logoutUser }: any = React.useContext(UserContext);
  const [currentYear, setCurrentYear] = React.useState<any>();

  React.useEffect(() => {
    setCurrentYear(moment().year())
  }, [])

  return (
    <>
      <footer className="with-color">
        <div className="footer-left-fill">Copyright © {currentYear} Client Site Support</div>
        <div className="footer-center-fill">
          {(user) ? <><NavLink to={{ pathname: "/account" }}>Account</NavLink> -{" "} </> : <><NavLink to={{ pathname: "/login" }}>Login</NavLink> -{" "} </>}
         

          <Modal trigger={<a style={{ color: '#b7b7b7', cursor: 'pointer' }}>Support -{" "}</a>} style={{ color: '#b7b7b7' }}>
            <Modal.Header>Customer Support</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Header>Please call Customer Service at 1-866-983-2824</Header>
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <NavLink to={{ pathname: "/requestPassword" }}>
            Change Password
          </NavLink>{" "}
          {
            (user)
              ? <>-{" "}
                <NavLink onClick={logoutUser} to={{ pathname: "/" }}>
                  Logout
            </NavLink></>
              : ''
          }

        </div>
      </footer>








    </>
  );
};

export default withRouter(Footer);
