import React from 'react';
import { Label } from 'semantic-ui-react';

const MakeSelectionLabel = (props) => {
    if (props.error) {
       return <Label pointing color='red' basic content='Please make selection' />
    };
    return null;
}

MakeSelectionLabel.defaultProps = {
    error: true,
}
export default MakeSelectionLabel;
