import React from 'react';
import { Grid, Form, Dropdown, Checkbox, Button, Message } from 'semantic-ui-react';

// Interfaces
import { IReferralProps } from '../../../Interfaces/Interfaces';

/**
 * @description Handles the Referral Information form
 * @param {Props} props 
 */
const ReferralInformationForm: React.FunctionComponent<IReferralProps> = ({ name, phone, email, typeOfBusiness, howDoYouKnowEachOther }) => {
    return (
        <>
                <Grid.Column width={4}>
                    <Form.Field>
                        <Form.Input label='Name' name='name' placeholder='Full Name' {...name} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label='Phone' name='phone' placeholder='Phone' {...phone} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label='Email' name='email' placeholder='Email' {...email} />
                    </Form.Field>
                    {/* TODO: Find out what options to put in type of business dropdown */}
                    <Form.Field>
                        <label>Type of Business:</label>
                        <Dropdown selection placeholder='Select Business Type' {...typeOfBusiness} />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form.Group grouped className='margin-fix'>
                        <label>How do you know each other?</label>
                        <Form.Field>
                            <Checkbox radio label='Friends' name='friends' {...howDoYouKnowEachOther.friends} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox radio label='Coworkers' name='coworkers' {...howDoYouKnowEachOther.coworkers} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox radio label='Business Partners' name='businessPartners' {...howDoYouKnowEachOther.businessPartners} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox radio label='Competitors' name='competitors' {...howDoYouKnowEachOther.competitors} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox radio label='Family' name='family' {...howDoYouKnowEachOther.family} />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox radio label='Through my local Chamber of Commerce' name='localChamberOfCommerce' {...howDoYouKnowEachOther.localChamberOfCommerce} />
                        </Form.Field>
                    </Form.Group>
                    <Grid textAlign='center'>
                        <Grid.Row>
                            <Button color='green' tabindex='0' content='Send Referral' />
                        </Grid.Row>
                        <Message positive className='margin-fix'>
                        Thank you for your patronage from all of us at Client Site Support.
                        </Message>
                    </Grid>
                </Grid.Column>

        </>
    );
};

export default ReferralInformationForm;