import { IRealEstateMarketingReviewState } from '../Interfaces/Interfaces';

export const _InitialRealEstateMRState: IRealEstateMarketingReviewState = {
    customerServiceRepName: '',
    name: '', email: '',
    phone: '',
    secondaryPhone: '',
    hoursOfOperation: '',
    referredTo: '',
    referredAs_broker: false,
    referredAs_realEstateAgent: false,
    referredAs_realtor: false,
    referredAs_other: false,
    referredAs_otherInput: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youTube: '',
    linkedIn: '',
    blog: '',
    skype: '',
    clientWebsite: '',
    originalWebsite: '',
    additionalWebsites: undefined,
    additionalWebsitesList: '',
    standAlone: undefined,
    businessOwner: undefined,
    timeOwningBusiness: '',
    broker: undefined,
    content_singular: false,
    content_group: false,
    content_other: false,
    content_otherInput: '',
    customURL: undefined,
    customURLSuggestions: '',
    brokerageOrCompany: '',
    realEstateAgentNames: '',
    displayLicenseNumber: undefined,
    licenseNumber: '',
    firstTimeHome: undefined,
    firstTimeHome_zipCodes: '',
    percentageGenerate: '',
    specialNotesKeyphrase: '',
    countryClub: undefined,
    countryClub_communities: '',
    countryClub_zipCodes: '',
    gatedSubdivision: undefined,
    gatedSubdivision_communities: '',
    gatedSubdivision_zipCodes: '',
    golfCourse: undefined,
    golfCourse_communities: '',
    golfCourse_zipCodes: '',
    overFiftey: undefined,
    overFiftey_communities: '',
    overFiftey_zipCodes: '',
    singleFamily: undefined,
    singleFamily_communities: '',
    singleFamily_zipCodes: '',
    shortSales: undefined,
    shortSales_communities: '',
    shortSales_zipCodes: '',
    townHomes: undefined,
    townHomes_communities: '',
    townHomes_zipCodes: '',
    waterfront: undefined,
    waterfront_communities: '',
    waterfront_zipCodes: '',
    condos: undefined,
    condos_communities: '',
    condos_zipCodes: '',
    relocation: undefined,
    relocation_communities: '',
    relocation_zipCodes: '',
    commercial: undefined,
    commercial_communities: '',
    commercial_zipCodes: '',
    staging: undefined,
    staging_communities: '',
    staging_zipCodes: '',
    rawLand: undefined,
    rawLand_communities: '',
    rawLand_zipCodes: '',
    minimal: undefined,
    minimal_communities: '',
    minimal_zipCodes: '',
    newConstruction: undefined,
    newConstruction_communities: '',
    newConstruction_zipCodes: '',
    investment: undefined,
    investment_communities: '',
    investment_zipCodes: '',
    downsizing: undefined,
    downsizing_communities: '',
    downsizing_zipCodes: '',
    luxury: undefined,
    luxury_communities: '',
    luxury_zipCodes: '',
    topSchools: undefined,
    topSchools_communities: '',
    topSchools_zipCodes: '',
    foreclosures: undefined,
    foreclosures_communities: '',
    foreclosures_zipCodes: '',
    primaryServiceAreas: '',
    buyerFindAddress: '',
    captureClientsAttention: '',
    uniqueSeperates: '',
    additionalInformation: '',
    specialContentNotes: '',
    keywordApprovalTime: '',
    formDisabled: false,
}