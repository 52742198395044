import React from 'react';
import { Header, Dropdown, Button, Segment, Grid, Form } from 'semantic-ui-react';

// Interfaces
import { PaymentMethodProps } from '../Interfaces/Interfaces';
import MakeSelectionLabel from '../../ReusableComponents/MakeSelectionLabel/MakeSelectionLabel';


/**
 * @description Component for selecting payment type
 * @param {Props} props 
 */
const RecurringPaymentMethod: React.FunctionComponent<any> = ({ paymentMethod, options, handleDropdownOnChange }) => {
    return (
        <>
            <div className='body'>
                <Segment>
                    <Grid>
                        <Grid.Column>
                            <Form style={{ textAlign: 'left' }}>
                                <Header dividing content='Recurring Payment Method' />
                                <Form.Field width='4'>
                                <Dropdown selection placeholder='Select Payment Type' name='paymentMethod' options={options} value={paymentMethod?.value} onChange={handleDropdownOnChange} />
                                {(paymentMethod) ? '' : <MakeSelectionLabel />}
                                {/* <Button content='Next Step' color='blue' /> */}
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
        </>
    );
};

RecurringPaymentMethod.defaultProps = {
    options: [{ text: 'Visa', value: 1 }, { text: 'Master Card', value: 2 }, { text: 'American Express', value: 3 }, { text: 'Discover', value: 4 }],
}

export default RecurringPaymentMethod;