import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Segment,
  Icon,
  Button,
  Divider,
  Modal,
  Header,
  List
} from "semantic-ui-react";

const ErrorBoundary = props => {
  const [state, setState] = useState<any>({ error: null, errorInfo: null });

  const onClick = () => {
    setState({ ...state, goBack: true });
  };
  const showErrors = () => {
    return ModalModalExample();
  };
  const ModalModalExample = () => (
    <Modal
      centered
      trigger={
        <Button size="massive" color="yellow" onClick={showErrors}>
          Show Errors
        </Button>
      }
    >
      <Header textAlign="center" as="h1">
        All Errors
      </Header>
      <Modal.Content>
        <Modal.Description>
          <h2>{state.error && state.error.toString()}</h2>
          <br />
        </Modal.Description>
        <Modal.Description>{ListExampleBasic()}</Modal.Description>
      </Modal.Content>
    </Modal>
  );

  const ListExampleBasic = () => (
    <List>
      <h2>
        <details style={{ whiteSpace: "pre-wrap" }}>
          <br />
          {state.errorInfo.componentStack}
        </details>
      </h2>
    </List>
  );

  const { errorInfo, goBack } = state;
  if (goBack) {
    return <Redirect to="/" />;
  }
  if (errorInfo) {
    return (
      <Fragment>
        <Container fluid>
          <Segment size="massive" padded="very" piled textAlign="center">
            <div>
              <Icon name="warning sign" size="massive" color="red" />
            </div>
            <h1>Something went wrong !!!</h1>

            <Divider section />

            <div>
              <Button size="massive" color="grey" onClick={onClick}>
                Return Home
              </Button>
              {ModalModalExample()}
            </div>
          </Segment>
          {/* <Segment textAlign='center'>
							<details style={{ whiteSpace: "pre-wrap" }}>
								{state.error && state.error.toString()}
								<br />
								{state.errorInfo.componentStack}
							</details>
						</Segment> */}
        </Container>
      </Fragment>
    );
  }
  // Render children if there's no error
  return props.children;
};

export default ErrorBoundary;
