import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Message, Form, Button, Label } from 'semantic-ui-react';

// DatePicker
import DatePicker from 'react-datepicker';

// Interfaces 
import { IFormSubmitProps } from '../../../Interfaces/Interfaces';

import "react-datepicker/dist/react-datepicker.css";

const SubmitForm: React.FunctionComponent<IFormSubmitProps> = ({ header, labelContent, displayNote, children, date, handleDateChange, disabled, ...props }) => {

    return (
        <Segment>
            <Grid textAlign='center'>
                <Grid.Row>
                    <Header dividing className='center-fix' content={header} as='h2' />
                </Grid.Row>
                <Grid.Row>
                    {/* TODO: Setting <Message> component prop warning makes the component invisable  */}
                    {
                        (displayNote)
                            ? <Grid.Column width='7'>
                                <Message color='yellow'>
                                    <Message.Header>NOTE FOR CUSTOMER SERVICE REP:</Message.Header>
                                    <p>Once complete, print out the email you get from submitting this form.</p>
                                </Message>
                            </Grid.Column>
                            : ''
                    }
                </Grid.Row>
                {children}
                <Grid.Row>
                    <Button disabled={disabled} color='green' tabindex='0' content='Submit Form' />
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

SubmitForm.propTypes = {
    header: PropTypes.string.isRequired,
    labelContent: PropTypes.string,
    displayNote: PropTypes.bool,
};

SubmitForm.defaultProps = {
    labelContent: "Please enter 'Appointment Time'.",
    displayNote: true,
    disabled: false,
};

export default SubmitForm;