import React from 'react';
import { Segment, Grid, Header, Form, Label, Checkbox, Button } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';

// Interfaces
import { IConfirmUpgradeOrder } from '../../../../../Interfaces/Interfaces';

import { FormContext } from '../../../../HOC/FormHOC/context';

const ConfirmUpgradeOrder: React.FunctionComponent<any> = ({ signature, date, agreeToTermsAndConditions, ...props }) => {

    const { handleDateChange, handleOnChange, handleSingleCheckboxClick } = React.useContext<any>(FormContext);

    return (
        <Segment>
            {/* TODO: Aligned */}
            <Grid className='center-fix'>
                <Grid.Row className='center-fix'>
                    <Header dividing content='Confirm Upgrade Order' className='center-fix' as='h2' />
                </Grid.Row>
                <Grid.Column width={9}>
                    <Form.Group widths='2'>
                        <Form.Field error={!signature}>
                            <label>
                                <span className="" data-tooltip="Please enter your First and Last name.">
                                    <i className="question circle blue outline icon"></i>
                                </span>Signature: <span className="color-red-regular">*</span>
                            </label>
                            <Form.Input required name='signature' placeholder='signature' value={signature} onChange={handleOnChange} />
                            {(!signature) ? <Label className='no-background-label center-fix' color='red' pointing basic content='Please Sign the Form.' /> : ''}
                        </Form.Field>
                        <Form.Field error={!date}>
                            <label>
                                Date: <span className="color-red-regular">*</span>
                            </label>
                            {/* <Form.Input name='confirmOrder[date]' placeholder='Date' {...date} /> */}
                            <DatePicker onChange={handleDateChange} selected={date} name='confirmDate' dateFormat="MMMM d, yyyy h:mm aa" showTimeSelect />
                            {(!date) ? <Label pointing color='red' basic className='center-fix' content='Please add the Date.' /> : ''}
                        </Form.Field>
                        {/* <Form.Field>
                            <label>Your IP Address: </label>
                            <Form.Input name='confirmOrder[ipAddress]' placeholder='Your IP Address' maxlength='3' {...ipAddress} />
                        </Form.Field> */}
                    </Form.Group>
                </Grid.Column>
                <Grid.Column width={11} className='center-fix-check'>
                    <Checkbox label={
                        <label>
                            <strong>
                                <span className="color-red-regular">*</span> I have read and agree to the above Terms &amp;
                      Conditions. I understand that this is a final purchase, non-refundable charge.
                            </strong>
                        </label>
                    }
                        name='agreeToTermsAndConditions'
                        value='yes'
                        checked={agreeToTermsAndConditions}
                        onClick={handleSingleCheckboxClick}
                    />

                    {
                        (agreeToTermsAndConditions)
                            ? ''
                            : <Label pointing color='red' basic>
                                Please Read and Agree to the 'Terms &amp; Conditions' before Submitting the Order.
                </Label>
                    }
                </Grid.Column>
                <Grid.Row>
                    <Button disabled={props.formDisabled} color='green' tabindex='0' content='Submit Order' />
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default ConfirmUpgradeOrder;
