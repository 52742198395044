import React from 'react';
import { Message, Grid, Header, Table, List } from 'semantic-ui-react';

const Inclusions = (props) => {

    return (
        <>
            <Message>
                <Grid columns={2}>
                    <Grid.Column width={11}>
                        <Table padded='very' className='height-fix'>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header content='Inclusions:' />
                                        <List bulleted>
                                            <List.Item>Contact form</List.Item>
                                            <List.Item>Basic web logo</List.Item>
                                            <List.Item>Adding client-supplied links</List.Item>
                                            <List.Item>Adding client-supplied framed IDX</List.Item>
                                            <List.Item>Instagram plugin if client has an Instagram</List.Item>
                                        </List>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Header>&nbsp;</Header>
                                        <List bulleted>
                                            <List.Item>Account with photos and login information</List.Item>
                                            <List.Item>Starter custom content</List.Item>
                                            <List.Item>Javascript additions if client-supplied</List.Item>
                                            <List.Item>New plugin installation that doesn't involve IDX</List.Item>
                                        </List>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Table padded='very' className='height-fix'>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header content='Extras at Additional Cost:' />
                                        <List bulleted>
                                            <List.Item>SSL installations</List.Item>
                                            <List.Item>iHomefinder IDX integration</List.Item>
                                            <List.Item>A print production version of the logo</List.Item>
                                            <List.Item>YouTube/Instagram/Twitter account setups</List.Item>
                                        </List>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid>
            </Message>
        </>
    );
};

export default Inclusions;
