import React, { useState, useEffect, useContext, useRef } from "react";

// axios
import axios from "axios";

import _ from "lodash";
import { rootURL } from "../../../config/config";

import UserContext from "../../../context/UserContext/Context/context";

import { toast } from "react-toastify";

import SimpleReactValidator from "simple-react-validator";
const simpleValidator = new SimpleReactValidator();
const KeywordApprovalContainer = (props) => {
  const { user } = useContext(UserContext);
  const [keyterms, setKeyterms] = useState<any>([]);
  const [formIsEnabled, setFormIsEnabled] = useState<boolean | null>(null);
  const [keytermsLimit, setKeytermsLimit] = useState<number>();
  const [addedKeyterms, setAddedKeyterms] = useState([]);
  const [totalKeyterms, setTotalKeyterms] = useState();
  const [submitKeyterms, setSubmitKeyterms] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>("");
  const [error, setError] = useState("");

  // Reset React Validator
  useEffect(() => {
    simpleValidator.hideMessages();
    simpleValidator.purgeFields();
    forceUpdate();
  }, []);

  useEffect(() => {
    if (user) {
      axios
        .post(`${rootURL}/api/keyterms`, { accountId: user?.accountId })
        .then((results) => {
          const { keyterms, keytermsLimit } = results?.data || {};

          // use to disable espo aproved keyterms
          setKeyterms(
            keyterms.map((keyterm) =>
              keyterm.approved ? { ...keyterm, espoApproved: true } : keyterm
            )
          );
          setTotalKeyterms(keyterms?.length);
          setKeytermsLimit(keytermsLimit);
        })
        .catch((error) => {
          console.warn("Error: ", error);
        });
    }
  }, [user]);

  useEffect(() => {
    setAddedKeyterms(keyterms?.filter((keyterm) => keyterm.approved));
  }, [keyterms]);

  useEffect(() => {
    if (
      addedKeyterms &&
      keytermsLimit &&
      addedKeyterms?.length === keytermsLimit
    ) {
      setFormIsEnabled((prevFormEnabled) => {
        if (
          prevFormEnabled === null &&
          addedKeyterms?.length === keytermsLimit
        ) {
          return false;
        }
        return true;
      });
    }
  }, [addedKeyterms, keytermsLimit]);

  // Handle form submission to avoid race condition
  useEffect(() => {
    if (addedKeyterms && keytermsLimit && submitKeyterms && user) {
      if (simpleValidator.allValid()) {
        // Trim & remove unnecessary whitespace
        // const sig = signature.trim().replace(/\s+/g, " ");

        // // Set requirements
        // const moreThanTwoWords = new RegExp(/([a-zA-Z0-9]+\s?\b){3,}/g);
        // const lessThanTwoWords = new RegExp(/^[a-zA-Z0-9_]+$/g);

        // if (lessThanTwoWords.test(sig) || moreThanTwoWords.test(sig)) {
        //   setError(
        //     "The signature field must follow the <first name> <last name> format."
        //   );
        //   setSubmitKeyterms(false);
        // } else {                  
          simpleValidator.hideMessages();
          simpleValidator.purgeFields();
          forceUpdate();
          setSubmitKeyterms(false);
          setError("");
          axios
            .post(`${rootURL}/keywordApproval/createKeywordApprovalEmail`, {
              approvedKeyterms: addedKeyterms,
              unapprovedKeyterms: keyterms.filter(
                (keyterm) => !keyterm?.approved
              ),
              clientID: user.accountId,
              signature,
            })
            .then((result) => {
              // TODO: Possible race condition?
              if (addedKeyterms?.length === keytermsLimit) {
                setFormIsEnabled(false);
              }
              toast.success("Form Submitted!");
            })
            .catch((error) => console.log(error.message));
        // }
      } else {
        simpleValidator.showMessages();                
        setSubmitKeyterms(false);
        setError("");
        forceUpdate();
      }
    }
  }, [addedKeyterms, keytermsLimit, submitKeyterms, user]);

  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
  const forceUpdate = useForceUpdate();

  const addKeyword = (e) => {
    const keywordId = e.target.getAttribute("data-keywordid");

    setKeyterms(
      keyterms.map((keyterm) => {
        if (keyterm.id === keywordId) return { ...keyterm, approved: true };
        return keyterm;
      })
    );

    setAddedKeyterms([
      ...addedKeyterms,
      keyterms.find(({ id }) => id === keywordId),
    ]);
  };

  const removeKeyword = (e) => {
    const keywordId = e.target.getAttribute("data-keywordid");
    setKeyterms(
      keyterms.map((keyterm) => {
        if (keyterm.id === keywordId) return { ...keyterm, approved: false };
        return keyterm;
      })
    );

    setAddedKeyterms(addedKeyterms.filter(({ id }) => id !== keywordId));
  };

  const onSubmit = () => {
    setSubmitKeyterms(true);
  };

  return props.children({
    keyterms,
    setKeyterms,
    addedKeyterms,
    totalKeyterms,
    keytermsLimit,
    formIsEnabled,
    addKeyword,
    removeKeyword,
    signature,
    setSignature,
    onSubmit,
    simpleValidator,
    error,
  });
};

export default KeywordApprovalContainer;
