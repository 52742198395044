import React from 'react';
import { Form, TextArea, Label } from 'semantic-ui-react';

// Interfaces 
import { IFormTextAreaProps } from '../../../Interfaces/Interfaces';

const FormTextArea: React.FunctionComponent<any> = ({ label, error, warningLabelProps, ...props }) => {

    if (props.visable) {
        return (
            <Form.Field className={(error) ? 'error' : ''}>
                {label}
                <TextArea error={error} {...props} disabled={props?.checkboxDisable} />
                {/* Turn this into reusable component */}
                {(error && !props?.checkboxDisable) ? <Label pointing color='red' className='center-fix' basic {...warningLabelProps} /> : ''}
            </Form.Field>
        );
    }

    return null;
};

FormTextArea.defaultProps = {
    rows: 6,
    visable: true,
    warningLabelProps: {
        content: 'Please enter details',
    }
};

export default FormTextArea;
