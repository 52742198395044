import React from 'react';
import { Message, Header, Grid } from 'semantic-ui-react';

const RevisionsNote = (props) => {

    return (
        <Message>
            <Grid columns={1}>
                <Grid.Column textAlign='left'>
                    <Header content='Note:' />
                    <br/>
                        The purpose of this form is to give you the opportunity to review your site and to request revisions. Upon
                        completion of the revisions, this form will act as the approval that your site has been completed.
          <br/><br/>
                            Please take your time when completing the form, and be detailed with changes needed. You will see that we have
                            several sections for you to note your revisions.
          <br/><br/>
                                Please fill out each of the sections that apply, and feel free to call Client Services with any questions.
          <br/><br/>
                                    Our goal is always to make sure that you are 100% satisfied with the revisions made. We will call you as soon
                                    as they are completed to advise you accordingly. Please make sure to call us back if you are unavailable to
                                    take our call.
          <br/><br/>
                                        If, in the future, you should require any additional changes, please submit your revisions so an estimate can
                                        be generated. If future changes are determined to be extensive and time-consuming, a discounted hourly service
                                        fee may apply. This does not apply to minor fixes.
          <br/><br/>
                                            If no revisions are needed in a section, please note “none” in each area that applies.
          <br/><br/>
                                                Lastly, we want to say “Thank you!” We appreciate your business and we will do everything we can to provide as
                                                much exposure as possible to help your business grow!
                                      
                </Grid.Column>
            </Grid>
        </Message>
                                            );
                                        };
                                        
                                        export default RevisionsNote;
