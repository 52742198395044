import React from 'react';
import { Message, List, Button, Grid, Divider, Header } from 'semantic-ui-react';

const SelectedKeyterms = ({ keytermsLimit, addedKeyterms, totalKeyterms, removeKeyword, formIsEnabled }) => {

    return (
        <>

            {/* Selected keyterms Header */}
            <Message attached className='padding-fix'>
                <Grid columns={2} stackable textAlign='center'>
                    <Divider vertical content='out of' />
                    <Grid.Row verticalAlign='middle' className='greeb'>
                        <Grid.Column>
                            <Header as='h1' content={addedKeyterms.length} />
                        </Grid.Column>
                        <Grid.Column>
                            <Header as='h1' content={keytermsLimit} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Message>

            <Message attached positive>
                <List divided verticalAlign='middle' style={{ textAlign: 'left' }} id='SelectedKeyterms'>
                    {
                        addedKeyterms?.map(({ id, name, ...keyterm }, index) => {
                            return (
                                <List.Item className='item-fix' data-keywordid={id} key={id}>
                                    <div className='right floated content'>
                                        <Button type='button' basic color='red' size='tiny' data-keywordid={id} onClick={removeKeyword} disabled={((formIsEnabled === false) || keyterm?.espoApproved)}>
                                            remove
                                </Button>
                                    </div>
                                    <span className='custom-lable'>{index + 1}</span>
                                    <div className='content content-fix'>{name}</div>
                                </List.Item>
                            )
                        })
                    }
                </List>
            </Message>
        </>
    );
};

export default SelectedKeyterms;
