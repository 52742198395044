import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Header, Button, Popup, Form, Label } from 'semantic-ui-react';

import axios from 'axios';
import { rootURL } from '../../../config/config';

import { toast } from 'react-toastify';

const ContactHeaderBar = ({ Header: content, handleContactButtonClick }) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        const contactForm = document.querySelector('#ContactForm') as HTMLFormElement;
        const formData = new FormData(contactForm);

        axios.post(`${rootURL}/api/contact`, formData)
        .then((results) => { toast.success('Message Sent!'); })
        .catch((error) => { toast.error(error.message); });

        contactForm.reset();
    };

    return (

        <Container className='with-color' fluid>
            <Grid.Row stretched>
                <Grid columns={2}>
                    <Grid.Column floated='left' verticalAlign='middle' textAlign='left'>
                        <Header content={content} as='h3' style={{ color: 'white' }} />
                    </Grid.Column>
                    <Grid.Column className='right aligned'>
                        <Popup
                            content={
                                <Form id='ContactForm' onSubmit={handleSubmit}>
                                    <Form.Field>
                                        <Label content='Name' />
                                        <Form.Input name='name' />
                                    </Form.Field>
                                    <Form.Field>
                                        <Label content='Email' />
                                        <Form.Input name='email' />
                                    </Form.Field>
                                    <Form.Field>
                                        <Label content='Message' />
                                        <Form.TextArea name='message' />
                                    </Form.Field>
                                    <Form.Field>
                                        <Button content='Submit' color='red' type='submit' />
                                    </Form.Field>
                                </Form>
                            }
                            on="click"
                            // pinned
                            basic
                            trigger={<Button color='red' content='Contact Us' type='button' />} />
                    </Grid.Column>
                </Grid>
            </Grid.Row>
        </Container>

    );
};

ContactHeaderBar.propTypes = {
    Header: PropTypes.string.isRequired,
    handleButtonClick: PropTypes.func,
};

ContactHeaderBar.defaultProps = {
    handleContactButtonClick: () => true
}

export default ContactHeaderBar;
