import React from 'react';
import { Header, Form, Label } from 'semantic-ui-react';

import { FormContext } from '../../../../HOC/FormHOC/context';

const PaymentInformation: React.FunctionComponent<any> = (props) => {
   
    const { handleOnChange, state } = React.useContext<any>(FormContext);
   
    return (
        <>
            <Header dividing content='Payment Information' />
            <Form.Group widths='2'>
                <Form.Field>
                    <Label content='Retail Price:' className='no-background-label' />
                    <Form.Input error={!state?.retailPrice} required name='retailPrice' placeholder='Retail Price' value={state?.retailPrice} onChange={handleOnChange} />
                    {(!state?.retailPrice) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field error={!state?.discountPrice}>
                    <Label className='no-background-label' content={<>Discount Price: <span className="color-red-regular">*</span></>} />
                    <Form.Input required name='discountPrice' placeholder='Discount Price' value={state?.discountPrice} onChange={handleOnChange} />
                 {(!state?.discountPrice) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
            </Form.Group>
            <Form.Group widths='2'>
                <Form.Field>
                    <Label className='no-background-label' content={<> Transaction Type:  </>} />
                    <Form.Input error={!state?.transactionType} required name='transactionType' placeholder='Transaction Type' value={state?.transactionType} onChange={handleOnChange} />
                    {(!state?.transactionType) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field>
                    <Label className='no-background-label' content='Amount:' />
                    <Form.Input error={!state?.amount} required name='amount' placeholder='Amount' value={state?.amount} onChange={handleOnChange} />
                    {(!state?.amount) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
            </Form.Group>
        </>
    );
}

export default PaymentInformation;