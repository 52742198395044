import React, { useContext, useEffect, useState } from 'react';
// lodash
import _ from 'lodash';

import uniqueId from 'lodash/uniqueId';

import axios from 'axios';
import { rootURL } from '../../../config/config';

import { toast } from 'react-toastify';

import { isRevisionsCode, getRevisionsPageNumber } from '../../../utils/formCodes';

import { useAfterFormSubmit } from '../../../customHooks/afterFormSubmit';
import { RevisionsState } from '../Interfaces/Interfaces';

import { getRevisionFormType } from '../../../utils/formCodes';

// UserContext 
import UserContext from '../../../context/UserContext/Context/context';
import { Console } from 'console';



const RevisionsContainer = (props) => {

    const { user } = useContext(UserContext);

    const [afterFormSubmit] = useAfterFormSubmit();

    const [state2, setState] = useState<RevisionsState>({ revisionType: '', formCode: '', isValidCode: false, pageCount: 0, pages: [], updateDNSInformation: undefined, websiteDomain: '', username: '', password: '', signature: '', email: '', authorize: undefined, formDisabled: false, displayErrorModal: false, errorModalMessage: '' });

    useEffect(() => {
        console.log('State: ', state2);
    }, [state2]);

    const validateForm = () => {
        const validState = Object.values(state2).every(value => (value !== undefined)) && (state2?.authorize);

        // Either one page filled out || dns information page
        const isPageFilledOut = state2?.pages?.some((page) => (page?.title && page?.details));
        const isDNSFilledOut = state2?.updateDNSInformation && state2?.websiteDomain && state2?.username && state2?.password;
        // for above errors, display modal;

        if (!isPageFilledOut && !isDNSFilledOut) {
            setState((prevState) => Object.assign({}, { ...prevState, displayErrorModal: true, errorModalMessage: 'You must fill out at least a single revision page, or DNS information.' }));
            throw new Error('You must fill out at least a single revision page, or DNS information.');
        }

        if (!validState) {
            throw new Error('Finish filling out the form!');
        }
    };

    const onSubmit = () => {
        try {
            validateForm();

            setState((prevState) => Object.assign({}, { ...prevState, formDisabled: true }));

            const form = document.querySelector('#RevisionsForm') as HTMLFormElement;
            const formData = new FormData(form);

            // Set form code
            formData.append('formCode', state2?.formCode);

            // Set client Id
            formData.append('clientID', user?.accountId);

            // Set Revision Type
            formData.append('revisionType', state2?.revisionType);

            Array.from(formData.keys()).map((key) => {
                if (key.includes('[file]')) {
                    formData.delete(key);
                }
            });

            // Set all of the form pages && files to the formData object
            state2?.pages?.map((page) => {
                // Set only the name value for the files to map in the email template
                page.files.map((file) => formData.append('file[]', file))
            });

            formData.append('pages', JSON.stringify(state2?.pages.map((page) => Object.assign({}, { ...page, files: page?.files?.map(({ name }) => name) }))));

            //TODO: Remove this in prod
            axios.post(`${rootURL}/revision/createRevisionEmail`, formData)
                .then(result => afterFormSubmit())
                .catch(error => {
                    toast.error(error.message);
                    console.warn('Error: ', error)
                })
        } catch (error) {
            if (!error.message.includes('revision')) {
                toast.error(error.message);
            }
        }

    };


    // React hooks

    useEffect(() => {
        if (state2?.isValidCode && state2?.formCode) {
            setState((prevState) => Object.assign({}, { ...prevState, revisionType: getRevisionFormType(state2?.formCode) }));
        }
    }, [state2?.isValidCode])

    // Calculate the number of revision pages from the form code
    useEffect(() => {
        const { formCode, isValidCode } = state2;
        return setState((prevState) => Object.assign({}, { ...prevState, pageCount: (isValidCode) ? getRevisionsPageNumber(formCode) : 0 }))
    }, [state2.isValidCode]);

    useEffect(() => {
        const { formCode, isValidCode, pageCount } = state2;
        if (isValidCode && pageCount) {
            return setState((prevState) =>
                Object.assign({}, {
                    ...prevState,
                    pages: Array.from(Array(prevState.pageCount))
                        .map(() => Object.assign({}, { id: uniqueId('Page_'), title: '', details: '', currentFile: '', files: [], uploading: 0, requirements: 0, error: 0, successful: 0 })
                        )
                }))
        }
    }, [state2.pageCount, state2.isValidCode]);

    const resetModal = () => setState((prevState) => Object.assign({}, { ...prevState, displayErrorModal: false, errorModalMessage: '' })); 

    const handlePageTitleOnChange = (e) => {
        const { value, name } = e.target || {};
        setState((prevState) => {
            return Object.assign({}, {
                ...prevState,
                pages: prevState.pages.map((page) => {
                    if (name && name.includes(page.id)) {
                        return { ...page, title: value };
                    }
                    else return page;
                })
            })
        }
        );
    };

    const handlePageDetailsOnChange = (e) => {
        const { value, name } = e.target || {};
        setState((prevState) => {
            return Object.assign({}, {
                ...prevState,
                pages: prevState.pages.map((page) => {
                    if (name && name.includes(page.id)) {
                        return { ...page, details: value };
                    }
                    else return page;
                })
            })
        }
        );
    };

    const fileOnChange = (e) => {
        const { value, name, files } = e.target || {};

        const meetsFileSizeRequirements = (file) => { return (file?.size / 1024 / 1024) < 10 };
        setState((prevState) => {
            return Object.assign({}, {
                ...prevState,
                pages: prevState.pages.map((page) => {
                    if (name && name.includes(page.id)) {
                        return { ...page, currentFile: (meetsFileSizeRequirements(files?.[0])) ? files?.[0] : '', uploading: (meetsFileSizeRequirements(files?.[0])) ? 100 : 0, requiremens: (meetsFileSizeRequirements(files?.[0])) ? 0 : 100, error: (meetsFileSizeRequirements(files?.[0])) ? 0 : 100, successful: 0 };
                    }
                    else return page;
                })
            })
        }
        );
    };

    const fileUpload = (e) => {
        const { value, files, name } = e.target || {};

        setState((prevState) => {
            return Object.assign({}, {
                ...prevState,
                pages: prevState.pages.map((page) => {
                    if (name && name.includes(page.id)) {
                        return { ...page, files: [...page?.files, page?.currentFile], currentFile: '', successful: page?.currentFile && 100, uploading: 0 };
                    }
                    else return page;
                })
            })
        }
        );
    };

    const cancelFileUpload = (e) => {
        const { value, name, files } = e.target || {};
        const pageId = e.target.getAttribute('data-pageid');
        setState((prevState) => {
            return Object.assign({}, {
                ...prevState,
                pages: prevState.pages.map((page) => {
                    if (pageId.includes(page.id)) {
                        return { ...page, currentFile: '', uploading: 0, requiremens: 0, error: 0, successful: 0 };
                    }
                    else return page;
                })
            })
        }
        );
    };

    const removeFile = (e) => {
        const { value, name, files } = e.target || {};

        const filename = e.target.getAttribute('data-filename');
        const pageId = e.target.getAttribute('data-pageid');

        setState((prevState) => {
            return Object.assign({}, {
                ...prevState,
                pages: prevState.pages.map((page) => {
                    if (pageId.includes(page.id)) {
                        return { ...page, files: page.files.filter((file) => file.name !== filename) };
                    }
                    else return page;
                })
            })
        });
    }

    const handleFormCodeInputOnChange = ({ target: { value, name } }) => {
        setState((prevState) => Object.assign({}, { ...prevState, [name]: value, isValidCode: isRevisionsCode(value) }));
    };

    const handleInputOnChange = ({ target: { value, name } }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: value }));

    const onCheckboxClick = (checkedValue: undefined | boolean) => (_, { checked, name }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: (checkedValue !== undefined) ? checkedValue : checked }))
    const onChange = ({ target: { value } }, { name }) => setState((prevState) => Object.assign({}, { ...prevState, [name]: value }));

    return props.children({ fileOnChange, handleInputOnChange, handlePageTitleOnChange, handlePageDetailsOnChange, handleFormCodeInputOnChange, fileUpload, cancelFileUpload, removeFile, onCheckboxClick, onChange, resetModal, state2, onSubmit });
}

export default RevisionsContainer;