import React from 'react';
import PropTypes from 'prop-types';
import { List, Header, Message } from 'semantic-ui-react';

const Instructions = ({ header, keytermsLimit }) => {

    return(
        <Message style={{ textAlign: 'left' }}>
        <Header dividing content={header} />
        <List bulleted>
            <List.Item>Please select <strong>{keytermsLimit}</strong> Keywords below.</List.Item>
            <List.Item>Confirm and Submit selection on the right.</List.Item>
            <List.Item><em>Contact Customer Service if you need help or have any questions.</em></List.Item>
        </List>
        </Message>
    );
};

Instructions.propTypes = {
    header: PropTypes.string.isRequired,
};

export default Instructions;
