import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export function useAfterFormSubmit() {
    const history = useHistory();
    const location = useLocation();

    /**
     * TODO: Issue with the recurring order form template / variables is causing server error that prevents form submission! 8-27-2020
     */

    const afterFormSubmit = () => {
        const currentLocation = location.pathname;
        history.push('/empty');
        history.push(currentLocation);
        toast.success('Form Submitted!');
    };

    return [afterFormSubmit];
};