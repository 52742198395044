import React from "react";
import {
  Header,
  Table,
  Grid,
  Segment,
  Divider,
  Popup,
} from "semantic-ui-react";
import Image from "./Image";

import Chart from "chart.js";
import "chartjs-plugin-annotation";

// Lodash
import _ from "lodash";

// Axios
import axios from "axios";

// Apollo
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import moment from "moment";

// Apollo Client
import { EspoClient } from "../App";
import { rootURL, espoURL } from "../config/config";
import ContactHeaderBar from "./ReusableComponents/ContactHeaderBar/ContactHeaderBar";

import RenderWrapper from "./ReusableComponents/RenderWrapper/RenderWrapper";
import Loader from "./Loader";

const CLIENT_BY_CLIENT_ID = gql`
  query ($clientID: String!) {
    clientByClientID(clientID: $clientID) {
      id
      name
    }
  }
`;

export const Home = ({ user, ...props }) => {
  // const { loading, data, error } = useQuery(CLIENT_BY_CLIENT_ID, { client: EspoClient, variables: { clientID: '15550' } });

  const [accountId, setAccountId] = React.useState();
  const [account, setAccount] = React.useState<any>();

  // Performance Graph
  const [gurantee, setGurantee] = React.useState<number>();
  const [performanceGraph, setPerformanceGraph] = React.useState<Chart>();

  // New State
  const [totalFPPs, setTotalFPPs] = React.useState<string>();
  const [totalOthers, setTotalOthers] = React.useState<number>();
  const [searchEngineFPPs, setSearchEngineFPPs] = React.useState<any>();
  const [rankReports, setRankReports] = React.useState<any[]>();
  const [rankingSummary, setRankingSummary] = React.useState<
    [
      {
        ones: string | number;
        others: string | number;
        date: string;
        account_no: string;
      }
    ]
  >();
  const [cumulativeRankingSummary, setcumulativeRankingSummary] =
    React.useState<
      [
        {
          ones: string | number;
          min_date: string | number;
          date: string;
          account_no: string;
        }
      ]
    >();
  const [autoScaleYAxisMax, setAutoScaleYAxisMax] = React.useState<number>(0);

  const [displayPerformanceGraph, setDisplayPerformanceGraph] =
    React.useState<boolean>(false);

  const [website, setWebsite] = React.useState("");
  const [currentDate, setCurrentDate] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    console.log(
      "bool: ",
      totalFPPs &&
        account?.guarantee &&
        parseInt(totalFPPs) <= parseInt(account?.guarantee)
    );

    console.log("total fpp", totalFPPs);
    console.log("total fpp", account?.guarantee);
    console.log(parseInt(account?.guarantee));
    console.log(parseInt(totalFPPs));
  }, [totalFPPs, account]);

  // TODO: Combine with duplicate hook further down in this file
  React.useEffect(() => {
    if (accountId) {
      axios
        .post(`${rootURL}/account/rankings`, { accountId })
        .then((results) => {
          setTotalFPPs(results?.data?.totalFPP);
          setTotalOthers(results?.data?.totalOthers);
          setSearchEngineFPPs(results?.data?.FPPBySearchEngine);
          setRankReports(results?.data?.rankReports);
          setRankingSummary(results?.data?.rankingSummary);
          setcumulativeRankingSummary(results?.data?.cumulativeRankingSummary);

          // extras
          setCurrentDate(results?.data?.currentDate);
          setWebsite(results?.data?.website);

          setLoading(false);
        })
        .catch((error) => console.log("Error: ", error));
    }
  }, [accountId]);

  React.useEffect(() => {
    if (user) {
      setAccountId(user?.accountId);
    }
  }, [user]);

  React.useEffect(() => {
    // calculate & set y auto scale max
    const largestCummulativeFPP =
      Math.ceil(
        parseInt(
          cumulativeRankingSummary?.[cumulativeRankingSummary.length - 1]
            ?.ones as any
        ) / 100
      ) * 100;
    if (largestCummulativeFPP) {
      return setAutoScaleYAxisMax(largestCummulativeFPP);
    }
  }, [cumulativeRankingSummary]);

  React.useEffect(() => {
    // Check that these values have been rendered
    if (
      account &&
      rankingSummary &&
      cumulativeRankingSummary &&
      autoScaleYAxisMax &&
      totalFPPs
    ) {
      setDisplayPerformanceGraph(true);
      // Performance Graph
      setPerformanceGraph(
        new Chart(
          document.getElementById("PerformanceGraph") as HTMLCanvasElement,
          {
            // the type of chart we want to create
            type: "bar",

            data: {
              labels: rankingSummary?.map(({ date }) => date),
              datasets: [
                {
                  label: "Weekly FPP's",
                  backgroundColor: "rgb(253, 125, 127)",
                  data: rankingSummary?.map(({ ones }) => ones) as any,
                },
                {
                  label: "Total FPP's",
                  backgroundColor: "rgb(128, 127, 255)",
                  data: cumulativeRankingSummary?.map(({ ones }) => ones),
                },
              ],
            },
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      max: autoScaleYAxisMax,
                    },
                  },
                ],
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
              annotation: {
                events: ["click"],
                annotations: [
                  {
                    drawTime: "afterDatasetsDraw",
                    id: "hline",
                    type: "line",
                    mode: "horizontal",
                    scaleID: "y-axis-0",
                    value: account?.guarantee,
                    borderColor: "black",
                    borderWidth: 5,
                    label: {
                      backgroundColor: "red",
                      content: "Guarantee",
                      enabled: true,
                    },
                    onClick: function (e) {
                      // The annotation is is bound to the `this` variable
                      // console.log("Annotation", e.type, this);
                    },
                  },
                ],
              },
            },
          }
        )
      );
    }
  }, [
    account,
    totalFPPs,
    rankingSummary,
    cumulativeRankingSummary,
    autoScaleYAxisMax,
  ]);

  React.useEffect(() => {
    if (accountId) {
      axios
        .post(`${rootURL}/account`, { accountId })
        .then((results) => {
          setAccount(results?.data?.account);
        })
        .catch((error) => console.warn("Error: ", error));
    }
  }, [accountId]);

  const googleKeywordUrl = (keyword, googleRank) => {
    const start = googleRank > 0 ? (googleRank - 1) * 10 : 0;
    return `https://google.com/search?q=${keyword
      .trim()
      .replace(" ", "+")}&start=${start}`;
  };

  const yahooKeywordUrl = (keyword, yahooRank) => {
    const start = yahooRank > 0 ? (yahooRank - 1) * 10 : 0;
    return `https://search.yahoo.com/search;_ylt=Awr9IMtceL1a0HsAoy5XNyoA?p=${keyword
      .trim()
      .replace(" ", "+")}&start=${start}`;
  };

  const bingKeywordUrl = (keyword, bingRank) => {
    const start = bingRank > 0 ? (bingRank - 1) * 10 : 0;
    return `https://bing.com/search?q=${keyword
      .trim()
      .replace(" ", "+")}&start=${start}`;
  };

  return (
    <>
      {/* <Loader loading={(loading)} /> */}

      <ContactHeaderBar Header="Account Information" />
      <div className="body">
        <Segment>
          <Grid columns="2">
            <Grid.Column>
              <form className="ui form">
                <Header as="h2" textAlign="left">
                  Contact Information
                </Header>
                <Divider />

                <Table padded definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="right">Name:</Table.Cell>
                      <Table.Cell>{account?.name}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell textAlign="right">Address:</Table.Cell>
                      <Table.Cell>{account?.billingAddressStreet}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell textAlign="right">City:</Table.Cell>
                      <Table.Cell>{account?.billingAddressCity}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell textAlign="right">State:</Table.Cell>
                      <Table.Cell>{account?.billingAddressState}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell textAlign="right">Zip:</Table.Cell>
                      <Table.Cell>
                        {account?.billingAddressPostalCode}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell textAlign="right">Primary Phone:</Table.Cell>
                      <Table.Cell>{account?.phoneNumber}</Table.Cell>
                    </Table.Row>

                    {/* TODO: Add conditinal rendering fo rth esecondary phone number */}
                    {/* <Table.Row>
                        <Table.Cell textAlign="right">Second Phone:</Table.Cell>
                        <Table.Cell>(123) 456-7890</Table.Cell>
                      </Table.Row> */}

                    {/* <Table.Row>
                        <Table.Cell textAlign="right">Fax:</Table.Cell>
                        <Table.Cell>(123) 456-7890</Table.Cell>
                      </Table.Row> */}

                    <Table.Row>
                      <Table.Cell textAlign="right">Email:</Table.Cell>
                      <Table.Cell>{account?.emailAddress}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </form>
            </Grid.Column>

            <Grid.Column>
              <Header as="h2" textAlign="left" className="ui dividing header">
                Performance Graph
              </Header>
              <Divider />
              <Segment textAlign="left" padded="very" className="ui message">
                {totalFPPs && account?.guarantee ? (
                  <Header as="h3">Congratulations!</Header>
                ) : (
                  ""
                )}

                <RenderWrapper isVisable={account?.guarantee && totalFPPs}>
                  Your campaign achieved a total of{" "}
                  <span className="color-red">{totalFPPs}</span> first page
                  placements so far.
                  <br />
                </RenderWrapper>

                {account?.guarantee ? (
                  <>
                    Your guarantee is for{" "}
                    <span className="color-red">{account?.guarantee}</span>.
                  </>
                ) : (
                  "Your guarantee is not specified, please contact your customer service rep."
                )}

                <RenderWrapper
                  isVisable={
                    totalFPPs &&
                    account?.guarantee &&
                    parseInt(totalFPPs) > parseInt(account?.guarantee)
                  }>
                  <br />
                  We have exceeded the guarantee by{" "}
                  <span className="color-red">
                    {parseInt(totalFPPs) - parseInt(account?.guarantee)}
                  </span>{" "}
                  and counting!
                </RenderWrapper>
              </Segment>
              <br />
              <canvas id="PerformanceGraph"></canvas>
              <Image path="../images/graph.JPG" />
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment>
          <Grid padded columns="2">
            <Grid.Column width="9">
              <Header as="h2" textAlign="left" className="ui dividing header">
                Total First Page Placements as of{" "}
                <span className="color-orange">{currentDate}</span>
              </Header>
              <Divider />

              <Table size="small" compact celled stackable collapsing>
                <Table.Header>
                  <Table.Row>
                    <Popup
                      content="Google"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            title="Google"
                            path="../images/google2.jpg"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Yahoo"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/yahoo2.jpg"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Bing"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/Bing-icon2.jpg"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="AOL"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/aol2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Swagl"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/swagl2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Webcrawler"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/toplogo_sm2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Lewtsy"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/lewtsy2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Find It Right"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/finditright2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Ecosia"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/ecosia2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Kajoe"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/kajoe2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Avant"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/Avant-Browser-20172.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Izito"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/izito2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="FSN Search"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/fsnsearch2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="Jooper"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/jooper2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                    <Popup
                      content="SearX"
                      trigger={
                        <Table.HeaderCell>
                          <Image
                            className="icons"
                            path="../images/searx2.jpg"
                            title="Google"
                          />
                        </Table.HeaderCell>
                      }
                    />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row className="center aligned">
                    <Table.Cell>{searchEngineFPPs?.Google || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Yahoo || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Bing || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.AOL || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Swagl || "-"}</Table.Cell>
                    <Table.Cell>
                      {searchEngineFPPs?.Webcrawler || "-"}
                    </Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Lewtsy || "-"}</Table.Cell>
                    <Table.Cell>
                      {searchEngineFPPs?.FindItRight || "-"}
                    </Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Ecosia || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Kajoe || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Avant || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Izito || "-"}</Table.Cell>
                    <Table.Cell>
                      {searchEngineFPPs?.FsnSearch || "-"}
                    </Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.Jooper || "-"}</Table.Cell>
                    <Table.Cell>{searchEngineFPPs?.SearchX || "-"}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width="7">
              <Header as="h2" textAlign="left" className="ui dividing header">
                Ranking Summary
              </Header>
              <Divider />

              <Table
                definition
                compact
                celled
                className="ui definition compact celled table">
                <Table.Body>
                  <Table.Row
                  //   className="center aligned"
                  >
                    <Table.Cell>Summary</Table.Cell>
                    {rankingSummary?.map(({ date }) => (
                      <Table.Cell key={date} textAlign="center">
                        {date}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  <Table.Row className="center aligned">
                    <Table.Cell>Ones</Table.Cell>
                    {rankingSummary?.map(({ date, ones }) => (
                      <Table.Cell key={date} textAlign="center">
                        {ones}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  <Table.Row className="center aligned">
                    <Table.Cell>Others</Table.Cell>
                    {rankingSummary?.map(({ date, others }) => (
                      <Table.Cell key={date} textAlign="center">
                        {others}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Segment>

        {/* <div className="ui grid">
              <div className="nine wide column"> */}
        <form className="ui form"></form>

        {/* <div className="seven wide column"> */}
        <form className="ui form"></form>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}

        <Segment className="ui segment">
          <div className="ui grid">
            <div className="column">
              <form className="ui form">
                <h2 className="ui dividing header">
                  Rank Report Details for{" "}
                  <span className="ui blue header">{website}</span> as of{" "}
                  <span className="color-orange">{currentDate}</span>
                </h2>
                <Divider />

                <Table definition celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Popup
                        content="Google"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              title="Google"
                              path="../images/google2.jpg"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Yahoo"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/yahoo2.jpg"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Bing"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/Bing-icon2.jpg"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="AOL"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/aol2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Swagl"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/swagl2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Webcrawler"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/toplogo_sm2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Lewtsy"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/lewtsy2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Find It Right"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/finditright2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Ecosia"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/ecosia2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Kajoe"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/kajoe2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Avant"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/Avant-Browser-20172.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Izito"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/izito2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="FSN Search"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/fsnsearch2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="Jooper"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/jooper2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                      <Popup
                        content="SearX"
                        trigger={
                          <Table.HeaderCell>
                            <Image
                              className="icons"
                              path="../images/searx2.jpg"
                              title="Google"
                            />
                          </Table.HeaderCell>
                        }
                      />
                    </Table.Row>
                  </Table.Header>
                  {
                    //TODO:  Move the table body out of the render method to up here???
                    rankReports?.map?.((keyword) => (
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell textAlign="right">
                            {keyword?.keyword}
                          </Table.Cell>
                          <Table.Cell>
                            {document.referrer === "https://phoenix.wms2006.com/" ? (
                              <a
                                href={googleKeywordUrl(
                                  keyword?.keyword,
                                  keyword?.Google
                                )}
                                target="_blank">
                                {keyword?.Google || "-"}
                              </a>
                            ) : document.referrer === "https://phoenixdev.wms2006.com/" ? (
                              <a
                                href={googleKeywordUrl(
                                  keyword?.keyword,
                                  keyword?.Google
                                )}
                                target="_blank">
                                {keyword?.Google || "-"}
                              </a>
                            ) : (
                              keyword?.Google || "-"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {document.referrer === "https://phoenix.wms2006.com/" ? (
                              <a
                                href={yahooKeywordUrl(
                                  keyword?.keyword,
                                  keyword?.Yahoo
                                )}
                                target="_blank">
                                {keyword?.Yahoo || "-"}
                              </a>
                            ) : document.referrer === "https://phoenixdev.wms2006.com/" ? (
                              <a
                                href={yahooKeywordUrl(
                                  keyword?.keyword,
                                  keyword?.Yahoo
                                )}
                                target="_blank">
                                {keyword?.Yahoo || "-"}
                              </a>
                            ) : (
                              keyword?.Yahoo || "-"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {
                            document.referrer === "https://phoenix.wms2006.com/" ? (
                              <a
                                href={bingKeywordUrl(
                                  keyword?.keyword,
                                  keyword?.Bing
                                )}
                                target="_blank">
                                {keyword?.Bing || "-"}
                              </a>
                            ) : document.referrer === "https://phoenixdev.wms2006.com/" ? (
                              <a
                                href={bingKeywordUrl(
                                  keyword?.keyword,
                                  keyword?.Bing
                                )}
                                target="_blank">
                                {keyword?.Bing || "-"}
                              </a>
                            ) : (
                              keyword?.Bing || "-"
                            )
                            }
                          </Table.Cell>

                          <Table.Cell>{keyword?.AOL || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Swagl || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Webcrawler || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Lewtsy || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.FindItRight || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Ecosia || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Kajoe || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Avant || "-"}</Table.Cell>
                          <Table.Cell>{keyword?.Izito}</Table.Cell>
                          <Table.Cell>{keyword?.FsnSearch}</Table.Cell>
                          <Table.Cell>{keyword?.Jooper}</Table.Cell>
                          <Table.Cell>{keyword?.SearchX || "-"}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    ))
                  }
                </Table>
              </form>
            </div>
          </div>
        </Segment>
      </div>
    </>
  );
};

export default Home;
