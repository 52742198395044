import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';

// Interfaces
import { IHeader } from '../../../Interfaces/Interfaces';

const HeaderBar: React.FunctionComponent<IHeader> = ({ header }) => {
    return (
        <Container className='with-color' fluid>
            <Grid.Row stretched>
                <Grid columns={2}>
                    <Grid.Column textAlign='left' verticalAlign='middle'>
                        <Header as='h3' style={{ color: 'white' }} content={header} />
                    </Grid.Column>
                </Grid>
            </Grid.Row>
        </Container>
    );
}

export default HeaderBar;