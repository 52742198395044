import React from 'react';
import { Label } from 'semantic-ui-react';

const ErrorLabel = (props) => {
    if (props.error) {
        return <Label style={{ width: '100%', textAlign: 'center' }}  pointing color='red' basic content='Please Enter Data' />
    }

    return null;

};

export default ErrorLabel;
