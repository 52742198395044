import React from 'react';
import { Grid, Header, Form, Label, Dropdown } from 'semantic-ui-react';

// Interfaces
import { ICheckInformation } from '../../../../../Interfaces/Interfaces';

// Context
import { FormContext } from '../../../../HOC/FormHOC/context';

// @ts-ignore
const CheckInformation: React.FunctionComponent<any> = ({ checkType, bankName, nameOnCheck, routingNumber, accountNumber, checkNumber, isVisable, checkTypeOptions }) => {

    const { handleOnChange, handleDropdownChange, handlePaymentTypeOnClick } = React.useContext<any>(FormContext);

    if (isVisable) {
        return (
            <Grid columns={1}>
                <Grid.Column>
                    <Header dividing content='Check Information' as='h2' />
                    <Form.Group widths={3}>
                        <Form.Field>
    
                            <Label className='no-background-label' content='Check Type:' />
                            <Dropdown selection name='checkType' options={checkTypeOptions} value={checkType?.value} onChange={handleDropdownChange} />
                        </Form.Field>
                        <Form.Field>
                            <Label className='no-background-label' content='Bank Name' />
                            <Form.Input name='bankName' placeholder='Bank Name' value={bankName} onChange={handleOnChange} />
                        </Form.Field>
                        <Form.Field>
                            <Label className='no-background-label' content='Name on Check' />
                            <Form.Input name='nameOnCheck' placeholder='Name on Check' value={nameOnCheck} onChange={handleOnChange} />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths={3}>
                        <Form.Field>
                            <Label className='no-background-label' content='Routing Number:' />
                            <Form.Input name='routingNumber' placeholder='Routing Number' value={routingNumber} onChange={handleOnChange} />
                        </Form.Field>
                        <Form.Field>
                            <Label className='no-background-label' content='Account Number: ' />
                            <Form.Input name='accountNumber' placeholder='Account Number' value={accountNumber} onChange={handleOnChange} />
                        </Form.Field>
                        <Form.Field>
                            <Label className='no-background-label' content='Check Number:' />
                            <Form.Input name='checkNumber' placeholder='Check Number' value={checkNumber} onChange={handleOnChange} />
                        </Form.Field>
                    </Form.Group>
                </Grid.Column>
            </Grid>
        );
    }

    return '';

};

CheckInformation.defaultProps = {
    checkTypeOptions: [{ text: 'Check Type', value: 0, }, { text: 'Business', value: 1 }, { text: 'Personal', value: 2 }],
}
export default CheckInformation;
