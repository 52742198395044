import React from 'react';
import Note from '../../ReusableComponents/Note/Note';

const BusinessMarketingReviewNote = (props) => {

    return (
        <>
            <Note header='Note: '>
                <br />
                Please keep in mind when our analysts review your account, they will generate certain topics or areas as needed.
            We must follow certain guidelines set forth by the search engines.
        <br /><br />
                Any single word may only be used a certain amount of times to avoid content spamming. This includes cities,
                states, and zipcodes. At this time mention any other services, products, or areas. We must make sure that we do
                not oversaturate your custom content.
        <br /><br />
                We want to thank you for taking the time completing your marketing review today! We would like to set your
                appointment up now for your keyword approval.
        <br /><br />
                Remember we now have you scheduled in our content and programming departments so it is urgent that you set this
                time aside. The process should take around 30 to 45 minutes. Please make sure to be at your computer.
        </Note>
        </>
    );
};

export default BusinessMarketingReviewNote;
