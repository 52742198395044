import React from 'react';
import { Header, Form, Dropdown } from 'semantic-ui-react';

// Interfaces
import { BillingInformationProps } from '../Interfaces/Interfaces';

// dropdown options
import { countries, states } from '../../../utils/dropdownOptions';

import ErrorLabel from '../../ReusableComponents/ErrorLabel/ErrorLabel';

const BillingInformation: React.FunctionComponent<any> = ({ firstName, lastName, streetAddress, apt, state, country, email, zipCode, city, specialInstructions, handleOnChange, handleDropdownOnChange }) => {
    return (
        <>
            <Header dividing textAlign='left' content='Billing Information' as='h2' />
            <Form.Group widths='2'>
                <Form.Field>
                    <label style={{ textAlign: 'left' }}>Name:</label>
                    <Form.Input required name="firstName" placeholder="First Name" onChange={handleOnChange} value={firstName} />
                    <ErrorLabel error={!firstName} />
                </Form.Field>
                <Form.Field>
                    <label style={{ textAlign: 'left' }}>&nbsp;</label>
                    <Form.Input required name="lastName" placeholder="Last Name" onChange={handleOnChange} value={lastName} />
                    <ErrorLabel error={!lastName} />
                </Form.Field>
            </Form.Group>
            <Form.Group widths='2'>
                <Form.Field width='8'>
                    <label style={{ textAlign: 'left' }}>Billing Address:</label>
                    <Form.Input required name="streetAddress" placeholder="Street Address" onChange={handleOnChange} value={streetAddress} />
                    <ErrorLabel error={!streetAddress} />
                </Form.Field>
                <Form.Field width='4'>
                    <label style={{ textAlign: 'left' }}>&nbsp;</label>
                    <Form.Input name="apt" placeholder="Apt #" onChange={handleOnChange} value={apt} />
                </Form.Field>
                <Form.Field widths='4'>
                    <label style={{ textAlign: 'left' }}>&nbsp;</label>
                    <Form.Input required name="zipCode" placeholder="Zip Code" onChange={handleOnChange} value={zipCode} />
                    <ErrorLabel error={!zipCode} />
                </Form.Field>
            </Form.Group>
            <Form.Group widths='3'>
                <Form.Field>
                    <label style={{ textAlign: 'left' }}>City:</label>
                    <Form.Input required fluid name='city' placeholder='City' onChange={handleOnChange} value={city} />
                    <ErrorLabel error={!city} />
                </Form.Field>
                <Form.Field>
                    <label style={{ textAlign: 'left' }}>State:</label>
                    <Dropdown selection fluid name='state' placeholder='State' options={states} onChange={handleDropdownOnChange} value={state?.value} />
                    <ErrorLabel error={!state?.value} />
                </Form.Field>
                <Form.Field>
                    <label style={{ textAlign: 'left' }}>Country:</label>
                    <Dropdown selection fluid name='country' placeholder='Country' options={countries} onChange={handleDropdownOnChange} value={country.value} />
                    <ErrorLabel error={!country?.value} />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field>
                    <label style={{ textAlign: 'left' }}>Email:</label>
                    <Form.Input type='email' name="email" placeholder="Email" onChange={handleOnChange} value={email} />
                    <ErrorLabel error={!email} />
                </Form.Field>
            </Form.Group>
            <Form.Group widths='16'>
                <Form.Field width='16'>
                    <label style={{ textAlign: 'left' }}>Special Instructions</label>
                    <Form.TextArea rows='9' name='specialInstructions' placeholder='Special Instructions' onChange={handleOnChange} value={specialInstructions} />
                </Form.Field>
            </Form.Group>
        </>
    );
};

BillingInformation.defaultProps = {
    state: {
        options: [
            { text: 'Alabama', value: 'AL' },
        ],
    },
    country: {
        options: [
            { text: 'Afganistan', value: 1 },
        ]
    },
}

export default BillingInformation;