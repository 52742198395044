import React, { useReducer, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";

// reducer

// initial state
import { rootURL } from "../../../config/config";
import { FormContext } from "../../HOC/FormHOC/context";

import { toast } from "react-toastify";

import { useAfterFormSubmit } from "../../../customHooks/afterFormSubmit";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const simpleValidator = new SimpleReactValidator();

const UpgradesContainer = (props) => {
  const [state, setState] = React.useState<any>({
    cvv: "",
    formDisabled: false,
    paymentMethod: null,
  });

  const [afterFormSubmit] = useAfterFormSubmit();

  // Reset React Validator
  useEffect(() => {    
    simpleValidator.purgeFields();
    simpleValidator.hideMessages();
    forceUpdate();    
  }, [state.creditDebitCard, state.check]);

  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
  const forceUpdate = useForceUpdate();

  // Refs
  const packageOptionsRef = useRef(null);
  const paymentMethodRef = useRef(null);
  const billingInformationRef = useRef(null);
  const contactInformationRef = useRef(null);

  const executeScroll = (ref) => scrollToRef(ref);

  const onSubmit = () => {
    // if (!state?.creditDebitCard && !state?.check) return paymentMethodRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // if (!state?.upgrade?.value) return packageOptionsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // if (!state?.billingInformationState || !state?.billingInformationCountry) return billingInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // if (!state?.contactInformationState || !state?.contactInformationCountry) return contactInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setState((prevState) =>
      Object.assign({}, { ...prevState, formDisabled: true })
    );

    if (simpleValidator.allValid()) {
      simpleValidator.hideMessages();
      simpleValidator.purgeFields();
      forceUpdate();
      return axios
        .post(`${rootURL}/upgrades/createUpgradesEmail`, state)
        .then((result) => afterFormSubmit())
        .catch((error) => {
          toast.error(error.message);
          console.warn("Error: ", error);
        });
    } else {
      simpleValidator.showMessages();
      forceUpdate();
      setState((prevState) =>
        Object.assign({}, { ...prevState, formDisabled: false })
      );
    }
  };

  const handleDateChange = (date_value) => {
    const date = date_value;
    setState((prevState) => Object.assign({}, { ...prevState, date }));
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleDropdownChange = (_, data) => {
    const name = data.name;
    const value = data.value;
    const options = data.options;
    setState((prevState) =>
      Object.assign(
        {},
        {
          ...prevState,
          [name]: options.find((optionItems) => optionItems.value === value),
        }
      )
    );
  };

  const handlePaymentTypeOnClick = (e, data) => {
    const name = data.name;
    const checked = data.checked;
    setState((prevState) =>
      Object.assign(
        {},
        {
          ...prevState,
          creditDebitCard: name === "creditDebitCard" ? true : false,
          check: name === "check" ? true : false,
        }
      )
    );
  };

  const handleSingleCheckboxClick = (_, { checked, name }) => {
    setState((prevState) =>
      Object.assign({}, { ...prevState, [name]: checked })
    );
  };

  return (
    <FormContext.Provider
      value={{
        handleOnChange,
        handleDropdownChange,
        handlePaymentTypeOnClick,
        handleDateChange,
        handleSingleCheckboxClick,
        state,
        paymentMethodRef,
        packageOptionsRef,
        billingInformationRef,
        contactInformationRef,
      }}>
      {props.children({
        onSubmit,
        handleDateChange,
        state,
        forceUpdate,
        simpleValidator,
      })}
    </FormContext.Provider>
  );
};

export default UpgradesContainer;
