export const formCodes = {
    RealEstateCodes: [
        'w201q',
        'w203q',
        'w205q',
        'w207q',
        'w210q',
    ],
    MLMCodes: [
        '741255',
        '743255',
        '745255',
        '747255',
        '740255',
    ],
    firstRevisions: [
        'revision42110',
        'revision42107',
        'revision42105',
        'revision42103',
        'revision42101',
    ],
    finalRevisions: [
        'revision42f10',
        'revision42f07',
        'revision42f05',
        'revision42f03',
        'revision42f01',
    ],
    paidRevisions: [
        'revision42p10',
        'revision42p07',
        'revision42p05',
        'revision42p03',
        'revision42p01',
    ]
};

export const isValidCode = (formCode) => Object.keys(formCodes)
    .map(key => formCodes[key])
    .flat()
    .some(code => (code === formCode));

export const isRevisionsCode = (formCode) => {
    const { firstRevisions, finalRevisions, paidRevisions } = formCodes;
    return [...paidRevisions, ...firstRevisions, ...finalRevisions].some(code => (code === formCode));
};

export const isRealEstateQuestionnaireCode = (formCode) => formCodes.RealEstateCodes.some(code => (code === formCode));
export const isMLMQuestionnaireCode = (formCode) => formCodes.MLMCodes.some(code => (code === formCode));

export const isWebsiteQuestionnaireCode = (formCode) => (isRealEstateQuestionnaireCode(formCode) || isMLMQuestionnaireCode(formCode));

export const getWebQPageNumber = (formCode: string) => {
    // is real estate code
    if (isRealEstateQuestionnaireCode(formCode)) {
        // is mlm code
        return parseInt(formCode.substring(2, 4));
    }
    // is mlm code
    else if (isMLMQuestionnaireCode(formCode)) {
        return (formCode.charAt(2) != '0') ? parseInt(formCode.charAt(2)) : 10
    }
    // is invalid code
    return 0;
};

export const getRevisionsPageNumber = (formCode: string) => {
    const { firstRevisions, finalRevisions, paidRevisions } = formCodes;
    const revisionsCodes = firstRevisions.concat(finalRevisions).concat(paidRevisions);
    if (revisionsCodes.includes(formCode)) {
        const pageNumber = parseInt(formCode[formCode.length - 2] + formCode[formCode.length - 1]);
        return pageNumber;
    }
    return 0;
}

export const getRevisionType = (formCode: string) => {
    if (formCode.includes('421')) return RevisionType.First;
    if (formCode.includes('42f')) return RevisionType.Final;
    if (formCode.includes('42p')) return RevisionType.Paid;
    else throw new Error('Invalid Revision Code!');
};

export const getRevisionFormType = (formCode: string) => {
    const revisionType = formCode.includes('421') && '1st' || formCode.includes('42f') && 'Final' || formCode.includes('42p') && 'Paid' || '';
    return `${revisionType} Revision ${getRevisionsPageNumber(formCode)}-Page Form`
};

export enum RevisionType {
    First = 1,
    Final = 2,
    Paid = 3,
};

