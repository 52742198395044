export const termsAndConditions = {
    1: `I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
    <br /> <br />
    As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
    I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect.
    <br /> <br />
    Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
    I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
    All prices unless otherwise specified are in U.S. funds.
    <br /> <br />
    If any pictures or copy from your site are used, the buyer indemnifies the company with regards to any liability resulting therefrom.
    Your newly purchased custom website will be hosted on our servers until you provide us with the information required to transfer the site to any domain name of your choosing.
    Custom site is yours to keep.
    <br /> <br />
    Hosting is included for 6 months. After 6 months, contact your customer service representative in order to renew. If you choose to host elsewhere after the 6 months, we will be happy to provide you the files for your site.
    Any accidental changes done by the client can be fixed after a quote of a nominal fee.
    <br /> <br />
    If the client desires to have IDX (Internet Data Exchange) associated with their site, the client must provide it.
    This purchase does not include blog upkeep, RETs feeds, or the installation of IDX-related plugins.
    Should you require any additional changes after the 2 free revisions given, please submit your revisions to customer service so an estimate can be generated. If future changes are determined to be extensive and time-consuming, a discounted hourly service fee may apply.  The cost is $125.00 an hour with a minimum purchase of 4 hours.`,
    2: `I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
    <br /> <br />
    As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
    I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. 
    <br /> <br />
    Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
    I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
    All prices unless otherwise specified are in U.S. funds.
    <br /> <br />
    If any pictures or copy from your site are used, the buyer indemnifies the company with regards to any liability resulting therefrom.
    Your newly purchased custom website will be hosted on our servers until you provide us with the information required to transfer the site to any domain name of your choosing.
    Custom site is yours to keep.
    <br /> <br />
    Hosting is included for 6 months. After 6 months, contact your customer service representative in order to renew. If you choose to host elsewhere after the 6 months, we will be happy to provide you the files for your site.
    Any accidental changes done by the client can be fixed after a quote of a nominal fee.
    <br /> <br />
    If the client desires to have IDX (Internet Data Exchange) associated with their site, the client must provide it.
    This purchase does not include blog upkeep, RETs feeds, or the installation of IDX-related plugins.
    Should you require any additional changes after the 2 free revisions given, please submit your revisions to customer service so an estimate can be generated. If future changes are determined to be extensive and time-consuming, a discounted hourly service fee may apply.  The cost is $125.00 an hour with a minimum purchase of 4 hours.
    `,
    3: `
    I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
    <br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. 
<br /> <br />
Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
<br /> <br />
All prices unless otherwise specified are in U.S. funds.
If any pictures or copy from your site are used, the buyer indemnifies the company with regards to any liability resulting therefrom.
Your newly purchased custom website will be hosted on our servers until you provide us with the information required to transfer the site to any domain name of your choosing.
Custom site is yours to keep.
<br /> <br />
Hosting is included for 6 months. After 6 months, contact your customer service representative in order to renew. If you choose to host elsewhere after the 6 months, we will be happy to provide you the files for your site.
Any accidental changes done by the client can be fixed after a quote of a nominal fee.
<br /> <br />
If the client desires to have IDX (Internet Data Exchange) associated with their site, the client must provide it.
This purchase does not include blog upkeep, RETs feeds, or the installation of IDX-related plugins.
Should you require any additional changes after the 2 free revisions given, please submit your revisions to customer service so an estimate can be generated. If future changes are determined to be extensive and time-consuming, a discounted hourly service fee may apply.  The cost is $125.00 an hour with a minimum purchase of 4 hours.

    `,
    4: `I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
    <br /> <br />
    As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
    I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. 
    <br /> <br />
    Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
    I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
    <br /> <br />
    All prices unless otherwise specified are in U.S. funds.
    If any pictures or copy from your site are used, the buyer indemnifies the company with regards to any liability resulting therefrom.
    Your newly purchased custom website will be hosted on our servers until you provide us with the information required to transfer the site to any domain name of your choosing.
    Custom site is yours to keep.
    <br /> <br />
    Hosting is included for 6 months. After 6 months, contact your customer service representative in order to renew. If you choose to host elsewhere after the 6 months, we will be happy to provide you the files for your site.
    Any accidental changes done by the client can be fixed after a quote of a nominal fee.
    <br /> <br />
    If the client desires to have IDX (Internet Data Exchange) associated with their site, the client must provide it.
    This purchase does not include blog upkeep, RETs feeds, or the installation of IDX-related plugins.
    Should you require any additional changes after the 2 free revisions given, please submit your revisions to customer service so an estimate can be generated. If future changes are determined to be extensive and time-consuming, a discounted hourly service fee may apply.  The cost is $125.00 an hour with a minimum purchase of 4 hours.
      `,
      5: `
      I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
      <br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
<br /> <br />
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
All prices unless otherwise specified are in U.S. funds.
<br /> <br />
If any pictures or copy from your site are used, the buyer indemnifies the company with regards to any liability resulting therefrom.
Your newly purchased custom website will be hosted on our servers until you provide us with the information required to transfer the site to any domain name of your choosing.
Custom site is yours to keep.
<br /> <br />
Hosting is included for 6 months. After 6 months, contact your customer service representative in order to renew. If you choose to host elsewhere after the 6 months, we will be happy to provide you the files for your site.
Any accidental changes done by the client can be fixed after a quote of a nominal fee.
<br /> <br />
If the client desires to have IDX (Internet Data Exchange) associated with their site, the client must provide it.
This purchase does not include blog upkeep, RETs feeds, or the installation of IDX-related plugins.
Should you require any additional changes after the 2 free revisions given, please submit your revisions to customer service so an estimate can be generated. If future changes are determined to be extensive and time-consuming, a discounted hourly service fee may apply.  The cost is $125.00 an hour with a minimum purchase of 4 hours.
      `,
      6: `
      
      I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
      <br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
<br /> <br />
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
<br /> <br />
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
The Upgrade that you purchased for additional content will be added to your site and the progress will be able to be seen through access to the Website Locator Service.
All prices unless otherwise specified are in U.S. funds.
      `,
      7: `
      I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
      <br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
<br /> <br />
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
<br /> <br />
The Upgrade that you purchased for additional content will be added to your site and the progress will be able to be seen through access to the Website Locator Service.
All prices unless otherwise specified are in U.S. funds.
      `,
    8: `
    I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
    <br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
<br /> <br />
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
<br /> <br />
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
Renewal keeps your campaign site and custom site active.
All prices unless otherwise specified are in U.S. funds.
    `,

9: `
I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
<br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
<br /> <br />
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
<br /> <br />
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
The SSL certificate that you have purchased is good for the length of your Campaign, not to exceed 12 months.
All prices unless otherwise specified are in U.S. funds.

`,

10: `

I may upgrade my service to allow for additional relevant services at any time for an additional fee. Service cannot be downgraded.
<br /> <br />
As the authorized decision-maker for my firm, I understand that the account that I provide for payment will be charged for the appropriate fees according to the package I have requested above. I hereby represent that I am the authorized user for the account that I have provided for payment.
I understand that once this order form is received since this is a service, a no refund, no cancellation policy is now in effect. 
<br /> <br />
Any and all collection costs will be added to the purchase price of this service. The venue for litigation will be Clark County Nevada.
I understand this is custom work designed solely for my business which is why there are no refunds, and I understand that any collection activity can result in legal fees and charges up 3 times the amount of the initial sale, not including damages.
<br /> <br />
iHomefinder service is for one (1) year and will require renewal to keep active.
All prices unless otherwise specified are in U.S. funds.
`,
}