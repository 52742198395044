export const states = [
    { text: 'AB', value: 59 },
    { text: 'AK', value: 2 },
    { text: 'AL', value: 1 },
    { text: 'AR', value: 4 },
    { text: 'AZ', value: 3 },
    { text: 'BC', value: 60 },
    { text: 'CA', value: 5 },
    { text: 'CO', value: 6 },
    { text: 'CT', value: 7 },
    { text: 'DC', value: 9 },
    { text: 'DE', value: 8 },
    { text: 'FL', value: 10 },
    { text: 'GA', value: 11 },
    { text: 'HI', value: 12 },
    { text: 'IA', value: 16 },
    { text: 'ID', value: 13 },
    { text: 'IL', value: 14 },
    { text: 'IN', value: 15 },
    { text: 'KS', value: 17 },
    { text: 'KY', value: 18 },
    { text: 'LA', value: 19 },
    { text: 'MA', value: 22 },
    { text: 'MB', value: 57 },
    { text: 'MD', value: 21 },
    { text: 'ME', value: 20 },
    { text: 'MI', value: 23 },
    { text: 'MN', value: 24 },
    { text: 'MO', value: 26 },
    { text: 'MS', value: 25 },
    { text: 'MT', value: 27 },
    { text: 'NB', value: 54 },
    { text: 'NC', value: 34 },
    { text: 'ND', value: 35 },
    { text: 'NE', value: 28 },
    { text: 'NH', value: 30 },
    { text: 'NJ', value: 31 },
    { text: 'NM', value: 32 },
    { text: 'NS', value: 53 },
    { text: 'NT', value: 62 },
    { text: 'NU', value: 63 },
    { text: 'NV', value: 29 },
    { text: 'NY', value: 33 },
    { text: 'OH', value: 36 },
    { text: 'OK', value: 37 },
    { text: 'ON', value: 56 },
    { text: 'OR', value: 38 },
    { text: 'PA', value: 39 },
    { text: 'PE', value: 52 },
    { text: 'QC', value: 55 },
    { text: 'RI', value: 40 },
    { text: 'SC', value: 41 },
    { text: 'SD', value: 42 },
    { text: 'SK', value: 58 },
    { text: 'TN', value: 43 },
    { text: 'TX', value: 44 },
    { text: 'UT', value: 45 },
    { text: 'VA', value: 47 },
    { text: 'VT', value: 46 },
    { text: 'WA', value: 48 },
    { text: 'WI', value: 50 },
    { text: 'WV', value: 49 },
    { text: 'WY', value: 51 },
    { text: 'YT', value: 61 }
  ]

export const countries = [
  { text: 'United States', value: 1 },
  { text: 'Canada', value: 2 },
  { text: 'Aland Islands', value: 3 },
  { text: 'Albania', value: 4 },
  { text: 'Algeria', value: 5 },
  { text: 'American Samoa', value: 6 },
  { text: 'Andorra', value: 7 },
  { text: 'Angola', value: 8 },
  { text: 'Anguilla', value: 9 },
  { text: 'Antigua', value: 10 },
  { text: 'Argentina', value: 11 },
  { text: 'Armenia', value: 12 },
  { text: 'Aruba', value: 13 },
  { text: 'Australia', value: 14 },
  { text: 'Austria', value: 15 },
  { text: 'Azerbaijan', value: 16 },
  { text: 'Bahamas', value: 17 },
  { text: 'Bahrain', value: 18 },
  { text: 'Bangladesh', value: 19 },
  { text: 'Barbados', value: 20 },
  { text: 'Belarus', value: 21 },
  { text: 'Belgium', value: 22 },
  { text: 'Belize', value: 23 },
  { text: 'Benin', value: 24 },
  { text: 'Bermuda', value: 25 },
  { text: 'Bhutan', value: 26 },
  { text: 'Bolivia', value: 27 },
  { text: 'Bosnia', value: 28 },
  { text: 'Botswana', value: 29 },
  { text: 'Bouvet Island', value: 30 },
  { text: 'Brazil', value: 31 },
  { text: 'British Virgin Islands', value: 32 },
  { text: 'Brunei', value: 33 },
  { text: 'Bulgaria', value: 34 },
  { text: 'Burkina Faso', value: 35 },
  { text: 'Burma', value: 36 },
  { text: 'Burundi', value: 37 },
  { text: 'Caicos Islands', value: 38 },
  { text: 'Cambodia', value: 39 },
  { text: 'Cameroon', value: 40 },
  { text: 'Cape Verde', value: 41 },
  { text: 'Cayman Islands', value: 42 },
  { text: 'Central African Republic', value: 43 },
  { text: 'Chad', value: 44 },
  { text: 'Chile', value: 45 },
  { text: 'China', value: 46 },
  { text: 'Christmas Island', value: 47 },
  { text: 'Cocos Islands', value: 48 },
  { text: 'Colombia', value: 49 },
  { text: 'Comoros', value: 50 },
  { text: 'Congo Brazzaville', value: 51 },
  { text: 'Congo', value: 52 },
  { text: 'Cook Islands', value: 53 },
  { text: 'Costa Rica', value: 54 },
  { text: 'Cote Divoire', value: 55 },
  { text: 'Croatia', value: 56 },
  { text: 'Cuba', value: 57 },
  { text: 'Cyprus', value: 58 },
  { text: 'Czech Republic', value: 59 },
  { text: 'Denmark', value: 60 },
  { text: 'Djibouti', value: 61 },
  { text: 'Dominica', value: 62 },
  { text: 'Dominican Republic', value: 63 },
  { text: 'Ecuador', value: 64 },
  { text: 'Egypt', value: 65 },
  { text: 'El Salvador', value: 66 },
  { text: 'England', value: 67 },
  { text: 'Equatorial Guinea', value: 68 },
  { text: 'Eritrea', value: 69 },
  { text: 'Estonia', value: 70 },
  { text: 'Ethiopia', value: 71 },
  { text: 'European Union', value: 72 },
  { text: 'Falkland Islands', value: 73 },
  { text: 'Faroe Islands', value: 74 },
  { text: 'Fiji', value: 75 },
  { text: 'Finland', value: 76 },
  { text: 'France', value: 77 },
  { text: 'French Guiana', value: 78 },
  { text: 'French Polynesia', value: 79 },
  { text: 'French Territories', value: 80 },
  { text: 'Gabon', value: 81 },
  { text: 'Gambia', value: 82 },
  { text: 'Georgia', value: 83 },
  { text: 'Germany', value: 84 },
  { text: 'Ghana', value: 85 },
  { text: 'Gibraltar', value: 86 },
  { text: 'Greece', value: 87 },
  { text: 'Greenland', value: 88 },
  { text: 'Grenada', value: 89 },
  { text: 'Guadeloupe', value: 90 },
  { text: 'Guam', value: 91 },
  { text: 'Guatemala', value: 92 },
  { text: 'Guinea-Bissau', value: 93 },
  { text: 'Guinea', value: 94 },
  { text: 'Guyana', value: 95 },
  { text: 'Haiti', value: 96 },
  { text: 'Heard Island', value: 97 },
  { text: 'Honduras', value: 98 },
  { text: 'Hong Kong', value: 99 },
  { text: 'Hungary', value: 100 },
  { text: 'Iceland', value: 101 },
  { text: 'India', value: 102 },
  { text: 'Indian Ocean Territory', value: 103 },
  { text: 'Indonesia', value: 104 },
  { text: 'Iran', value: 105 },
  { text: 'Iraq', value: 106 },
  { text: 'Ireland', value: 107 },
  { text: 'Israel', value: 108 },
  { text: 'Italy', value: 109 },
  { text: 'Jamaica', value: 110 },
  { text: 'Japan', value: 111 },
  { text: 'Jordan', value: 112 },
  { text: 'Kazakhstan', value: 113 },
  { text: 'Kenya', value: 114 },
  { text: 'Kiribati', value: 115 },
  { text: 'Kuwait', value: 116 },
  { text: 'Kyrgyzstan', value: 117 },
  { text: 'Laos', value: 118 },
  { text: 'Latvia', value: 119 },
  { text: 'Lebanon', value: 120 },
  { text: 'Lesotho', value: 121 },
  { text: 'Liberia', value: 122 },
  { text: 'Libya', value: 123 },
  { text: 'Liechtenstein', value: 124 },
  { text: 'Lithuania', value: 125 },
  { text: 'Luxembourg', value: 126 },
  { text: 'Macau', value: 127 },
  { text: 'Macedonia', value: 128 },
  { text: 'Madagascar', value: 129 },
  { text: 'Malawi', value: 130 },
  { text: 'Malaysia', value: 131 },
  { text: 'Maldives', value: 132 },
  { text: 'Mali', value: 133 },
  { text: 'Malta', value: 134 },
  { text: 'Marshall Islands', value: 135 },
  { text: 'Martinique', value: 136 },
  { text: 'Mauritania', value: 137 },
  { text: 'Mauritius', value: 138 },
  { text: 'Mayotte', value: 139 },
  { text: 'Mexico', value: 140 },
  { text: 'Micronesia', value: 141 },
  { text: 'Moldova', value: 142 },
  { text: 'Monaco', value: 143 },
  { text: 'Mongolia', value: 144 },
  { text: 'Montenegro', value: 145 },
  { text: 'Montserrat', value: 146 },
  { text: 'Morocco', value: 147 },
  { text: 'Mozambique', value: 148 },
  { text: 'Namibia', value: 149 },
  { text: 'Nauru', value: 150 },
  { text: 'Nepal', value: 151 },
  { text: 'Netherlands Antilles', value: 152 },
  { text: 'Netherlands', value: 153 },
  { text: 'New Caledonia', value: 154 },
  { text: 'New Guinea', value: 155 },
  { text: 'New Zealand', value: 156 },
  { text: 'Nicaragua', value: 157 },
  { text: 'Niger', value: 158 },
  { text: 'Nigeria', value: 159 },
  { text: 'Niue', value: 160 },
  { text: 'Norfolk Island', value: 161 },
  { text: 'North Korea', value: 162 },
  { text: 'Northern Mariana Islands', value: 163 },
  { text: 'Norway', value: 164 },
  { text: 'Oman', value: 165 },
  { text: 'Pakistan', value: 166 },
  { text: 'Palau', value: 167 },
  { text: 'Palestine', value: 168 },
  { text: 'Panama', value: 169 },
  { text: 'Paraguay', value: 170 },
  { text: 'Peru', value: 171 },
  { text: 'Philippines', value: 172 },
  { text: 'Pitcairn Islands', value: 173 },
  { text: 'Poland', value: 174 },
  { text: 'Portugal', value: 175 },
  { text: 'Puerto Rico', value: 176 },
  { text: 'Qatar', value: 177 },
  { text: 'Reunion', value: 178 },
  { text: 'Romania', value: 179 },
  { text: 'Russia', value: 180 },
  { text: 'Rwanda', value: 181 },
  { text: 'Saint Helena', value: 182 },
  { text: 'Saint Kitts and Nevis', value: 183 },
  { text: 'Saint Lucia', value: 184 },
  { text: 'Saint Pierre', value: 185 },
  { text: 'Saint Vincent', value: 186 },
  { text: 'Samoa', value: 187 },
  { text: 'San Marino', value: 188 },
  { text: 'Sandwich Islands', value: 189 },
  { text: 'Sao Tome', value: 190 },
  { text: 'Saudi Arabia', value: 191 },
  { text: 'Senegal', value: 192 },
  { text: 'Serbia', value: 193 },
  { text: 'Serbia', value: 194 },
  { text: 'Seychelles', value: 195 },
  { text: 'Sierra Leone', value: 196 },
  { text: 'Singapore', value: 197 },
  { text: 'Slovakia', value: 198 },
  { text: 'Slovenia', value: 199 },
  { text: 'Solomon Islands', value: 200 },
  { text: 'Somalia', value: 201 },
  { text: 'South Africa', value: 202 },
  { text: 'South Korea', value: 203 },
  { text: 'Spain', value: 204 },
  { text: 'Sri Lanka', value: 205 },
  { text: 'Sudan', value: 206 },
  { text: 'Suriname', value: 207 },
  { text: 'Svalbard', value: 208 },
  { text: 'Swaziland', value: 209 },
  { text: 'Sweden', value: 210 },
  { text: 'Switzerland', value: 211 },
  { text: 'Syria', value: 212 },
  { text: 'Taiwan', value: 213 },
  { text: 'Tajikistan', value: 214 },
  { text: 'Tanzania', value: 215 },
  { text: 'Thailand', value: 216 },
  { text: 'Timorleste', value: 217 },
  { text: 'Togo', value: 218 },
  { text: 'Tokelau', value: 219 },
  { text: 'Tonga', value: 220 },
  { text: 'Trinidad', value: 221 },
  { text: 'Tunisia', value: 222 },
  { text: 'Turkey', value: 223 },
  { text: 'Turkmenistan', value: 224 },
  { text: 'Tuvalu', value: 225 },
  { text: 'Uganda', value: 226 },
  { text: 'Ukraine', value: 227 },
  { text: 'United Arab Emirates', value: 228 },
  { text: 'Uruguay', value: 229 },
  { text: 'Us Minor Islands', value: 230 },
  { text: 'Us Virgin Islands', value: 231 },
  { text: 'Uzbekistan', value: 232 },
  { text: 'Vanuatu', value: 233 },
  { text: 'Vatican City', value: 234 },
  { text: 'Venezuela', value: 235 },
  { text: 'Vietnam', value: 236 },
  { text: 'Wallis and Futuna', value: 237 },
  { text: 'Western Sahara', value: 238 },
  { text: 'Yemen', value: 239 },
  { text: 'Zambia', value: 240 },
  { text: 'Zimbabwe', value: 241 }
]

export const months = [
    { text: 'January', value: '01' },
    { text: 'February', value: '02' },
    { text: 'March', value: '03' },
    { text: 'April', value: '04' },
    { text: 'May', value: '05' },
    { text: 'June', value: '06' },
    { text: 'July', value: '07' },
    { text: 'August', value: '08' },
    { text: 'September', value: '09' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' },
];

export const cardYears = [
    { text: '2020', value: '2020' },
    { text: '2021', value: '2021' },
    { text: '2022', value: '2022' },
    { text: '2023', value: '2023' },
    { text: '2024', value: '2024' },
    { text: '2025', value: '2025' },
    { text: '2026', value: '2026' },
    { text: '2027', value: '2027' },
    { text: '2028', value: '2028' },
    { text: '2029', value: '2029' },
    { text: '2030', value: '2030' },
    { text: '2031', value: '2031' },
    { text: '2032', value: '2032' },
    { text: '2033', value: '2033' },
    { text: '2034', value: '2034' },
    { text: '2035', value: '2035' },
    { text: '2036', value: '2036' },
    { text: '2037', value: '2037' },
    { text: '2038', value: '2038' },
    { text: '2039', value: '2039' },
];