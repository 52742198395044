import React from 'react';
import { Grid, Header, List } from 'semantic-ui-react';


const PurchaseAgreement = ({ promoCode, monthsAgreedTo, billingDate, hostingFee, maintenanceFee }) => {
    return (
        <>
            <Grid columns='1' textAlign='left'>
                <Grid.Column>
                    <Header dividing content='Purchase Agreement' as='h2' />
                The recurring fees of {hostingFee} and $10.00 per month will appear each month on or about the {billingDate?.text} of the month per
          your selection above, and the recurring fees will continue for a total of {monthsAgreedTo} months per
          your selection.
          <br /><br />
          Unlike other companies that attempt to continue to charge the recurring fees after the agreement is complete,
          we do NOT do that! This means that your last monthly recurring fee will be on the {billingDate?.text} after
          which time your site will no longer be visible for the world to see!
          <br /><br />
          In order to keep your site hosted and maintained after this agreement concludes, you must choose to allow the
          monthly maintenance fee to continue. We will contact you prior to the {billingDate?.text} to ask for your
          permission at that time.
          <br /><br />
          For {monthsAgreedTo} months, the {hostingFee} monthly recurring hosting fee and the $10.00 monthly maintenance
          fee will show each month on your statement as follows:
          <br /><br />
          {hostingFee} for hosting fees

          $10.00 for maintenance fees
          <br /><br />
          By signing below, you are affirming that you are authorized to use this credit card, and you understand that
          you will see a monthly recurring fee of {hostingFee} and a monthly fee of $10.00 per month for your hosting and
          maintenance fee. By signing below, you are agreeing that these 2 monthly fees cannot be cancelled and are
          non-refundable since they are a vital part of your campaign at a promotional price.
          <br /><br />
          Please make sure that your credit card is current and available to process these two charges monthly. If a
          charge does not go through, your site does not display. Instead, prospects will see a banner that says:
          <br /><br />
          "Pardon our Dust while we make upgrades to make your experience more enjoyable." During this unpaid period,
          your site will not be available for the world to see. If this occurs, please contact us immediately and advise
          as to whether to rerun the charge, or you may choose to supply a new card.

<Header dividing content={<>Terms &amp; Condtions</>} as='h2' />
For {monthsAgreedTo} months, the {hostingFee} monthly recurring hosting fee and the $10.00 monthly maintenance
          fee will show each month on your statement as follows:

<Header as='h4' content={
                        <>
                            {hostingFee} for hosting fees<br />
                                $10.00 for maintenance fees
    </>
                    } />
          While it is our intention for your site to be up 100% of the time, circumstances beyond our control may
          occasionally intervene. In addition, if we are working on your site for upgrades or for any reason, it may
          temporarily display as "pardon our dust while we upgrade."

          <Header as='h3' content='Privacy Policy' />

          This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally
          Identifiable Information’ (PII) is being used online. PII, as described in US privacy law and information
          security, is information that can be used on its own or with other information to identify, contact, or locate
          a single person, or to identify an individual in context. Please read our privacy policy carefully to get a
          clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable
          Information in accordance with our website.

<Header as='h3' content='What personal information do we collect from the people that visit our blog, website or app?' />
When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address,
          mailing address, phone number, credit card information or other details to help you with your experience.

          <Header as='h3' content='When do we collect information?' />

          We collect information from you when you register on our site, place an order, fill out a form or enter
          information on our site.
          Provide us with feedback on our products or services

          <Header as='h3' content='How do we use your information?' />

          We may use the information we collect from you when you register, make a purchase, sign up for our newsletter,
          respond to a survey or marketing communication, surf the website, or use certain other site features in the
          following ways:

          <List>
                        <List.Item>
                            To personalize your experience and to allow us to deliver the type of content and product offerings in
                            which you are most interested.
              </List.Item>
                        <List.Item>
                            To improve our website in order to better serve you.
              </List.Item>
                        <List.Item>
                            To allow us to better service you in responding to your customer service requests.
              </List.Item>
                        <List.Item>
                            To quickly process your transactions.
              </List.Item>
                        <List.Item>
                            To ask for ratings and reviews of services or products
              </List.Item>
                        <List.Item>
                            To follow up with them after correspondence (live chat, email or phone inquiries)
              </List.Item>
                    </List>

                    <Header as='h3' content="Do we use ‘cookies’?" />

Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive
          through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize
          your browser and capture and remember certain information. For instance, we use cookies to help us remember
          and process the items in your shopping cart. They are also used to help us understand your preferences based
          on previous or current site activity, which enables us to provide you with improved services. We also use
          cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better
          site experiences and tools in the future.

          <br />
                    <br />
                    <strong>We use cookies to:</strong>

                    <List>
                        <List.Item>Help remember and process the items in the shopping cart.</List.Item>
                        <List.Item>Understand and save user’s preferences for future visits.</List.Item>
                        <List.Item>Keep track of advertisements.</List.Item>
                        <List.Item>Compile aggregate data about site traffic and site interactions in order to offer better site
                        experiences and tools in the future. We may also use trusted third-party services that track this
              information on our behalf.</List.Item>
                    </List>

          You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off
          all cookies. You do this through your browser settings. Since browser is a little different, look at your
          browser’s Help Menu to learn the correct way to modify your cookies.
          <br /><br />

                    <strong>If users disable cookies in their browser:</strong>
          If you turn cookies off, some of the features that make your site experience more efficient may not function
          properly. Some of the features that make your site experience more efficient and may not function properly.

          <Header as='h3' content='Third-party disclosure' />

          We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information
          unless we provide users with advance notice. This does not include website hosting partners and other parties
          who assist us in operating our website, conducting our business, or serving our users, so long as those
          parties agree to keep this information confidential. We may also release information when it’s release is
          appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or
          safety.

          However, non-personally identifiable visitor information may be provided to other parties for marketing,
          advertising, or other uses.

          <Header as='h3' content='Third-party links' />

          Occasionally, at our discretion, we may include or offer third-party products or services on our website.
          These third-party sites have separate and independent privacy policies. We therefore have no responsibility or
          liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity
          of our site and welcome any feedback about these sites.

          <Header as='h3' content='Google' />

          Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place
          to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en

          We have not enabled Google AdSense on our site but we may do so in the future.

          <Header as='h3' content='COPPA (Children Online Privacy Protection Act)' />

          When it comes to the collection of personal information from children under the age of 13 years old, the
          Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United
          States’ consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and
          online services must do to protect children’s privacy and safety online.

          We do not specifically market to children under the age of 13 years old.

          <Header as='h3' content='CAN SPAM Act' />

          The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial
          messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough
          penalties for violations.

          <br /><br /><strong>We collect your email address in order to:</strong><br />
          • Send you additional information related to your product and/or service<br /><br />
                    <strong>To be in accordance with CANSPAM, we agree to the following:</strong><br />

          If at any time you would like to unsubscribe from receiving future emails, you can email us at
          and we will promptly remove you from ALL correspondence.

          <Header as='h3' content='Contacting Us' />

          If there are any questions regarding this privacy policy, you may contact us using the information below.
          <br /><br />
                    <strong>Last Edited on:</strong> 2017-02-24

                </Grid.Column>
            </Grid>
        </>
    );
}

export default PurchaseAgreement;