import React from "react";
import { Container, Form, Grid, Segment, Button } from "semantic-ui-react";
// Components
import Instructions from "./Instructions/Instructions";
import KeywordSelectionForm from "./KeywordSelectionForm/KeywordSelectionForm";
import SelectedKeyterms from "./SelectedKeyterms/SelectedKeyterms";

// Container
import KeywordApprovalContainer from "./Container/KeywordApprovalContainer";
import { Label } from "semantic-ui-react";
// Reuseable Components
import ContactHeaderBar from "../ReusableComponents/ContactHeaderBar/ContactHeaderBar";
import UserContext from "../../context/UserContext/Context/context";

const KeywordApproval = (props) => {
  const { user } = React.useContext<any>(UserContext);

  return (
    <KeywordApprovalContainer>
      {({ keyterms, addedKeyterms, simpleValidator, ...state }) => (
        <>
          <ContactHeaderBar
            Header="Keyword Approval"
            handleContactButtonClick={() => true}
          />
          <Form onSubmit={state?.onSubmit}>
            <Grid columns={2}>
              <Grid.Column>
                <KeywordSelectionForm
                  addedKeyterms={addedKeyterms}
                  keytermsLimit={state?.keytermsLimit}
                  setKeyterms={state?.setKeyterms}
                  keyterms={keyterms}
                  addKeyword={state.addKeyword}
                  totalKeyterms={state.totalKeyterms}
                  formIsEnabled={state?.formIsEnabled}
                />
              </Grid.Column>
              <Grid.Column>
                <SelectedKeyterms
                  keytermsLimit={state?.keytermsLimit}
                  addedKeyterms={addedKeyterms}
                  totalKeyterms={state.totalKeyterms}
                  removeKeyword={state.removeKeyword}
                  formIsEnabled={state?.formIsEnabled}
                />
              </Grid.Column>
            </Grid>
            <Form.Field error={!state?.signature}>
              <label>
                <span
                  className=""
                  data-tooltip="Please enter your First and Last name.">
                  <i className="question circle blue outline icon"></i>
                </span>
                Signature: <span className="color-red-regular">*</span>
              </label>
              <Form.Input
                size="big"
                name="signature"
                placeholder="Signature"
                value={state?.signature}
                onChange={(e) => state?.setSignature(e.target.value)}
              />
              {simpleValidator.message(
                "signature",
                state?.signature,
                "required"
              )}              
              {/* {!state?.signature ? (
                <Label
                  className="no-background-label center-fix"
                  color="red"
                  pointing
                  basic
                  content="Please Sign the Form."
                />
              ) : (
                ""
              )} */}
            </Form.Field>
            <Segment>
              <Button
                content="Submit"
                color="green"
                type="submit"
                disabled={!state.formIsEnabled}
              />
            </Segment>
          </Form>
        </>
      )}
    </KeywordApprovalContainer>
  );
};

export default KeywordApproval;
