import React from 'react';
import { Grid, Message } from 'semantic-ui-react';

// Reusable Components
import FormSegment from '../../ReusableComponents/FormSegment/FormSegment';

interface IProps {
    header: string,
    messageHeader: string,
    message: JSX.Element,
};

const WebsiteQuestionnaireNote: React.FunctionComponent<IProps> = ({ header, messageHeader, message }) => {
    return (
        <FormSegment Header={header}>
            <Grid columns={1}>
                <Message info style={{ textAlign: 'left' }}>
                    <Grid.Column>
                        <Message.Header content={messageHeader} />
                        {message}
                </Grid.Column>
                </Message>
            </Grid>
        </FormSegment>
    );
}

export default WebsiteQuestionnaireNote;