import React, { useState, useEffect } from 'react';
import { Segment, List, Button, Icon, Form } from 'semantic-ui-react';

import _ from 'lodash';

// Components
import Instructions from '../Instructions/Instructions';

// Interfaces
import { IKeywordSelectionFormProps } from '../Interfaces/Interfaces';

const KeywordSelectionForm: React.FunctionComponent<any> = ({ keytermsLimit, addedKeyterms, keyterms, addKeyword, totalKeyterms, formIsEnabled, setKeyterms }) => {


    const [newKeyterm, setNewKeyterm] = useState('');
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        if ((addedKeyterms?.length >= keytermsLimit)) {
           return setAddButtonDisabled(true);
        }
        return setAddButtonDisabled(false);
    }, [addedKeyterms, keytermsLimit, formIsEnabled])
      // Alphabatize keyterms
  keyterms.sort((a, b) => {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
    return (
        <>

            <Segment>
                {/* TODO: Header */}
                <Instructions header='Instructions' keytermsLimit={keytermsLimit} />
                <List divided verticalAlign='middle' style={{ textAlign: 'left' }} id='KeywordSelectionForm'>
                    {
                        keyterms?.map(({ id, name, approved }, index) => {
                            return (
                                <List.Item className='item-fix' data-keywordid={id}>
                                    <div className='right floated content' style={{ float: 'right' }}>

                                        {
                                            (approved)
                                                ? <Button type='button' disabled size='small'>
                                                    <Icon name='check' />
                                                    Approved
                                                    </Button>
                                                :
                                                <Button type='button' animated color='blue' size='small' tabIndex='0' disabled={addButtonDisabled} data-keywordid={id} onClick={addKeyword}>
                                                    <div className='visible content' data-keywordid={id}>Add</div>
                                                    <div className='hidden content' data-keywordid={id}>
                                                        <Icon name='plus' data-keywordid={id} />
                                                    </div>
                                                </Button>
                                        }

                                    </div>
                                    <div className='left floated content' style={{ float: 'left' }}>
                                        <span className='custom-lable'>{index + 1}</span>
                                        <div className='content content-fix'>{name}</div>
                                    </div>
                                </List.Item>
                            )
                        })
                    }
                </List>
            </Segment>
        </>
    );
};

export default KeywordSelectionForm;
