import React from 'react';
import { Form } from 'semantic-ui-react';

// Interfaces 
import { IInputElementProps } from '../../../Interfaces/Interfaces';

// Reusable Components
import WarningLabel from '../WarningLabel/WarningLabel';

const FormFieldInput: React.FunctionComponent<IInputElementProps> = ({ id, error, label, warningLabelProps, ...props }) => {


    return (
        <Form.Field error={error || (props.required && !props.value)} id={id}>
            {(label) ? label : ''}
            <Form.Input {...props} error={error || (props.required && !props.value)} /*value={value} onChange={({ target: { value } }) => setValue(value)}*/ />
            {(warningLabelProps && (error || (props.required && !props.value))) ? <WarningLabel {...warningLabelProps as any} /> : ''}
        </Form.Field>
    );
};

FormFieldInput.defaultProps = {
    error: false,
};

export default FormFieldInput;