import React from 'react';
import { Segment, Grid, Form, Label, Checkbox } from 'semantic-ui-react';


// Datepicker
import Datepicker from 'react-datepicker';

// Components
import RecurringPaymentMethod from './RecurringPaymentMethod/RecurringPaymentMethod';
import BillingAgreement from './BillingAgreement/BillingAgreement';
import CardInformation from './CardInformation/CardInformation';
import BillingInformation from './BillingInformation/BillingInformation';
import PurchaseAgreement from './PurchaseAgreement/PurchaseAgreement';

// Container
import RecurringPaymentsContainer from './Container/RecurringPaymentsContainer';

// Reusable Components
import ContactHeaderBar from '../ReusableComponents/ContactHeaderBar/ContactHeaderBar';
import HeaderBar from '../ReusableComponents/HeaderBar/HeaderBar';
import SubmitForm from '../ReusableComponents/SubmitForm/SubmitForm';

// Utils
import { months } from '../../utils/dropdownOptions';

/**
 * @description Top level recurring payments component
 * @param {Props} props
 */
const RecurringPayments = () => {
    return (
        <RecurringPaymentsContainer>
            {
                ({  onSubmit, handleDateChange, handleOnChange, handleDropdownOnChange, handleCheckboxOnClick, state2, forceUpdate,formSubmitted, simpleValidator, ...state }: any) =>
                    <>
                        <ContactHeaderBar Header='Recurring Payments' />
                        <RecurringPaymentMethod paymentMethod = {state2.paymentMethod} handleDropdownOnChange={handleDropdownOnChange} />

                        <HeaderBar header='Monthly Recurring Hosting and Maintenance Fees' />
                        <div className='body'>
                            <Form id='RecurringForm' onSubmit={onSubmit}>
                                <Segment>
                                    <Grid columns='2'>
                                        <Grid.Column textAlign='left'>
                                            <BillingAgreement {...state.BillingAgreement} /*billingAgreementOptions={billingAgreementOptions}*/ handleOnChange={handleOnChange} handleDropdownOnChange={handleDropdownOnChange} {...state2} />
                                            <CardInformation {...state?.CardInformation} promoCode={state?.BillingAgreement?.promoCode} simpleValidator={simpleValidator} setCardMonth={state?.setCardMonth} handleOnChange={handleOnChange} handleDropdownOnChange={handleDropdownOnChange} {...state2} forceUpdate={forceUpdate} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <BillingInformation {...state2} handleOnChange={handleOnChange} handleDropdownOnChange={handleDropdownOnChange} />
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                                <PurchaseAgreement  {...state2}  />
                                {/* TODO: Make this it's own component */}
                                {/* TODO: Implement actual date component */}
                                <SubmitForm header='Confirm Recurring Payment' displayNote={false} disabled={formSubmitted}>
                                    <>
                                        <Grid.Column width='9'>
                                            <Form.Group widths='1'>
                                                <Form.Field error={!state2?.signature} width='16'>
                                                    <label><span className="" data-tooltip="Please enter your First and Last name.">
                                                        <i className="question circle blue outline icon"></i>
                                                    </span>Signature: <span className="color-red-regular">*</span></label>
                                                    <Form.Input name="signature" placeholder="Signature" onChange={handleOnChange} value={state2?.signature} />
                                                    {(!state2?.signature) ? <Label pointing color='red' basic className='center-fix' content='Please Sign the Form.' /> : ''}
                                                </Form.Field>
                                            </Form.Group>
                                        </Grid.Column>
                                        <Grid.Column width='11' className='center-fix-check'>
                                            <Checkbox label={<label><strong><span className="color-red-regular">*</span> I've read and agree to the 'Purchase Agreement'
                  and to the 'Terms &amp; Conditions'.</strong></label>}

                                                name='agreeToTerms'
                                                checked={state2.agreeToTerms}
                                                onClick={handleCheckboxOnClick}
                                                // {...state.ConfirmRecurringPayment.agreeToTermsAndConditions}
                                            />
                                            {(!state2.agreeToTerms) ? <Label pointing color='red' basic>
                                                Please Read and Agree to the 'Purchase Agreement' and the 'Terms &amp; Conditions' before Submitting the
                                                Order.</Label> : ''}
                                        </Grid.Column>
                                    </>
                                </SubmitForm>
                            </Form>
                        </div>
                    </>
            }
        </RecurringPaymentsContainer>
    );
}

RecurringPayments.defaultProps = {
    expireMonthsOptions: months,
}

export default RecurringPayments;