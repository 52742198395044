import React from 'react';
import { Label, Form, Grid, Message, Icon, Header, Segment, Modal, Button } from 'semantic-ui-react';

import RevisionsNote from './RevisionsNote/RevisionsNote';
import RevisionHeaderMessage from './RevisionHeaderMessage';

// Container
import RevisionsContainer from './Container/RevisionsContainer';

// Reusable components
import ContactHeaderBar from '../ReusableComponents/ContactHeaderBar/ContactHeaderBar';
import FormCode from '../ReusableComponents/FormCode/FormCode';

// Components
import RevisionPage from './RevisionForm/RevisionPages/RevisionPage/RevisionPage';
import WarningLabel from '../ReusableComponents/WarningLabel/WarningLabel';
import FormSegment from '../ReusableComponents/FormSegment/FormSegment';
import RenderWrapper from '../ReusableComponents/RenderWrapper/RenderWrapper';

const Revisions = (props) => {

    return (
        <RevisionsContainer>
            {
                ({ RevisionVerification, RevisionPages, handleInputOnChange, handleFormCodeInputOnChange, handlePageTitleOnChange, handlePageDetailsOnChange, handleButtonOnClick, handleFormButtonOnClick, cancelFileUpload, fileOnChange, fileUpload, removeFile, onCheckboxClick, onChange, resetModal, state2, ...state }: any) =>

                    <>

                        <Modal open={state2?.displayErrorModal} onClose={resetModal}>
                            <Modal.Header>Form Error</Modal.Header>
                            <Modal.Content>
                                <Modal.Description>
                                    {state2?.errorModalMessage}
                                </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button content='Okay' color='red' onClick={resetModal} />
                            </Modal.Actions>
                        </Modal>

                        <ContactHeaderBar Header='Revisions' handleContactButtonClick={() => true} />

                        <FormSegment Header={'Revision Verification'}>
                            <Grid.Column>
                                <Message><p><b>Note: </b> Please enter the code with no spaces.</p></Message>
                                <Form.Field inline className='code-flex error margin-fix'>
                                    <Label className='no-background-label'>Verification Code: <span className='color-red-regular'>*</span></Label>
                                    <Form.Input name='formCode' placeholder='Verification Code' value={state2?.formCode} onChange={handleFormCodeInputOnChange} />
                                </Form.Field>
                                <RenderWrapper isVisable={!state2?.formCode}><Label pointing color='red' basic className='error-fix' content="Please enter valid 'Verification Code'" /></RenderWrapper>
                            </Grid.Column>
                        </FormSegment>
                        {
                            (state2?.isValidCode) ? <>

                                <RevisionsNote />
                                <RevisionHeaderMessage revisionCode={state2?.formCode} />
                                <Form data-testid='RevisionsForm' id='RevisionsForm' enctype="multipart/form-data" onSubmit={state?.onSubmit}>

                                    <Grid columns={2}>
                                        {state2?.pages?.map((page, index) => <RevisionPage {...page} index={index} fileUpload={fileUpload} fileOnChange={fileOnChange} cancelFileUpload={cancelFileUpload} removeFile={removeFile} handlePageTitleOnChange={handlePageTitleOnChange} handlePageDetailsOnChange={handlePageDetailsOnChange} />)}
                                        <Grid.Column>
                                            <Message className='height-100' info style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon name='hand point right' size='huge' />
                                                <div className='content' >
                                                    <Header content='Want to add MORE pages?' textAlign='left' />
                                                    <p style={{ textAlign: 'left' }}><a href="/Upgrades.html">Upgrade your package</a> to add more pages for your custom website, the long term benefits will pay for themselves!</p>
                                                    <p style={{ textAlign: 'left' }}><em>Or <a href="/contactus.html">Contact Us</a> to speak to a Customer Service Representative for more details/options.</em></p>
                                                </div>
                                            </Message>
                                        </Grid.Column>
                                    </Grid>


                                    <Segment>
                                        <Grid>
                                            <Grid.Column className='margin-padding-fix'>
                                                <Header dividing content='DNS Information' as='h2' />
                                            </Grid.Column>
                                        </Grid>

                                        <Grid columns={2}>
                                            <Grid.Column>
                                                <Form.Group className={`grouped fields ${(state2?.updateDNSInformation === undefined) ? 'error' : ''}`} style={{ textAlign: 'left' }}>
                                                    <Label style={{ backgroundColor: 'transparent', color: 'black', fontSize: '35rem', paddingLeft: '0px' }}>Do you have DNS information for us to update? <span className="color-red-regular">*</span><br /><br />If
                    you own the domain that you would like to have hosted with us, click yes to enter the login information
                to the account here: </Label>
                                                    <Form.Field className='field'>
                                                        <Form.Checkbox radio label='Yes' value='Yes' data-testid='UpdateDNSInformationYesCheckbox' name='updateDNSInformation' checked={state2?.updateDNSInformation} onClick={onCheckboxClick(true)} />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Form.Checkbox radio label='No' value='No' data-testid='UpdateDNSInformationNoCheckbox' name='updateDNSInformation' checked={(!state2?.updateDNSInformation && state2?.updateDNSInformation !== undefined)} onClick={onCheckboxClick(false)} />
                                                    </Form.Field>
                                                    <WarningLabel visable={(state2?.updateDNSInformation === undefined)} />
                                                </Form.Group>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field style={{ textAlign: 'left' }}>
                                                    <Label style={{ backgroundColor: 'transparent', color: 'black', fontSize: '500px' }} content='Website the domain was bought on (ex. GoDaddy.com):' />
                                                    <Form.Input required={state2?.updateDNSInformation} disabled={!state2?.updateDNSInformation} name='websiteDomain' placeholder='Domain Website' value={state2?.websiteDomain} onChange={onChange} />
                                                </Form.Field>

                                                <Form.Group width={2} style={{ textAlign: 'left' }}>
                                                    <Form.Field>
                                                        <Label style={{ backgroundColor: 'transparent', color: 'black' }} content='Username: ' />
                                                        <Form.Input required={state2?.updateDNSInformation} disabled={!state2?.updateDNSInformation} name='username' placeholder='Username' onChange={onChange} />
                                                    </Form.Field>
                                                    {/* TODO: Should this be hidden????? */}
                                                    <Form.Field>
                                                        <Label style={{ backgroundColor: 'transparent', color: 'black' }} content='Password:' />
                                                        <Form.Input required={state2?.updateDNSInformation} disabled={!state2?.updateDNSInformation} name='password' placeholder='Password' onChange={onChange} />
                                                    </Form.Field>
                                                </Form.Group>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>




                                    {/* =================================================== (START) CONFIRM REVISIONS ========================================================================== */}

                                    <Segment>
                                        {/* Column class? */}
                                        <Grid className='center-fix aligned'>
                                            <Grid.Row columns='16' className='center-fix'>
                                                <Header dividing className='center-fix' content={'Confirm Revisions ' + state2?.revisionType} as='h2' />
                                            </Grid.Row>
                                            <Grid.Column width={9}>
                                                <div className='two fields'>
                                                    {/* TODO: Add minWidth to FormInputs */}
                                                    <Form.Field error={!state2?.signature} >
                                                        <Label style={{ backgroundColor: 'transparent', color: (!state2?.signature) ? '#9f3a38' : 'black' }}>
                                                            <span className="" data-tooltip="Please enter your First and Last name.">
                                                                <i className="question circle blue outline icon"></i>
                                                            </span>Signature: <span className="color-red-regular">*</span>
                                                            <Form.Input name='signature' placeholder='Signature' value={state2?.signature} onChange={onChange} />
                                                            <WarningLabel visable={!state2?.signature} />
                                                        </Label>
                                                    </Form.Field>

                                                    <Form.Field error={!state2?.email}>
                                                        <Label style={{ backgroundColor: 'transparent', color: (!state2?.email) ? '#9f3a38' : 'black' }}>
                                                            <span className="" data-tooltip="Please enter your First and Last name.">
                                                                <i className="question circle blue outline icon"></i>
                                                            </span>Email: <span className="color-red-regular">*</span>
                                                            <Form.Input name='email' placeholder='Email' value={state2?.email} onChange={onChange} />
                                                            <WarningLabel visable={!state2?.email} />
                                                        </Label>
                                                    </Form.Field>

                                                </div>
                                            </Grid.Column>
                                            <Grid.Column width={11} className='center-fix-check'>
                                                <Form.Field inline>
                                                    <Form.Checkbox data-testid='Authorize' value='Authorize' name='authorize' checked={state2?.authorize} onClick={onCheckboxClick()}
                                                        label={<label style={{ backgroundColor: 'transparent', color: 'black' }}>
                                                            <strong><span className="color-red-regular">*</span> I authorize my revision requests to be made based on my website revision form included.</strong></label>}
                                                    />

                                                </Form.Field>
                                                <WarningLabel visable={!state2?.authorize} />

                                            </Grid.Column>
                                            <Grid.Row>
                                                <Form.Field>
                                                    <Form.Button disabled={state2?.formDisabled} content='Submit Button' color='green' type='submit' />
                                                </Form.Field>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                    {/* =================================================== (END) CONFIRM REVISIONS ========================================================================== */}

                                    {/* 
                                    <ConfirmRevisions {...state.ConfirmRevisions} /> */}
                                </Form>
                            </>
                                : ''
                        }

                    </>
            }
        </RevisionsContainer>
    );
};

export default Revisions;
