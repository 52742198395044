import React from 'react';
import { Header, Form, Label, Checkbox } from 'semantic-ui-react';

import { FormContext } from '../../../../HOC/FormHOC/context';

// Interfaces
import { IPaymentMethodProps } from '../../../../../Interfaces/Interfaces';

const PaymentMethod: React.FunctionComponent<any> = () => {

    const { handleOnChange, handleDropdownChange, handlePaymentTypeOnClick, state, paymentMethodRef } = React.useContext<any>(FormContext);

    return (
        <div ref={paymentMethodRef}> 
            <Header dividing content='Payment Method' as='h2'  />
            <Form.Field>
                {/* TODO: margin left should be sset to 0px */}
                <Label className='no-background-label no-margin-left' >
                    Select Payment Type: <span className="color-red-regular">*</span>
                </Label>
            </Form.Field>
            {/* TODO: Font-size for labels should be bigger */}
            <Form.Field>
                <Checkbox radio name='creditDebitCard' onClick={handlePaymentTypeOnClick} checked={state?.creditDebitCard} />
                <Label content='Credit/Debit Card' size='massive' style={{ color: '#9f3a38', backgroundColor: 'transparent' }} />
            </Form.Field>
            <Form.Field>
                <Checkbox radio name='check' onClick={handlePaymentTypeOnClick} checked={state?.check} />
                <Label content='Check' size='massive' style={{ color: '#9f3a38', backgroundColor: 'transparent' }} />
            </Form.Field>
           {(!state?.check && ! state?.creditDebitCard) ? <Label pointing color='red' basic content='Please make a selection.' /> : ''}
        </div>
    );
};

export default PaymentMethod;
