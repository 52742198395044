import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header } from 'semantic-ui-react';

const DoubleFormSegment = (props) => {

    if (!props.showSegment) {
        return (
            <Grid columns={2}>
                <Grid.Column width={props.leftColumnWidth}>
                    {props.leftColumn}
                </Grid.Column>

                <Grid.Column width={props.rightColumnWidth}>
                    {props.rightColumn}
                </Grid.Column>
            </Grid>
        );
    }

    return (
        <Segment>
            <Grid columns={2}>

                <Grid.Column width={props.leftColumnWidth}>
                    {props.leftColumn}
                </Grid.Column>
                <Grid.Column width={props.rightColumnWidth}>
                    {props.rightColumn}
                </Grid.Column>
            </Grid>
        </Segment>
    );
};


DoubleFormSegment.defaultProps = {
    showSegment: true,
};

DoubleFormSegment.propTypes = {
    leftColumnWidth: PropTypes.number.isRequired,
    leftColumn: PropTypes.element.isRequired,
    rightColumnWidth: PropTypes.number.isRequired,
    rightColumn: PropTypes.element.isRequired,
};

export default DoubleFormSegment;
