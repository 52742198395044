import React from "react";
import { Segment, Header, Icon, Divider } from "semantic-ui-react";
export const Register = () => { 
  return (
    <>
      <div className="container with-color">
        <div
          style={{
            textAlign: "left",
            color: "white",
            padding: "15px",
            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
          }}
        >
          <h3>Registration</h3>
        </div>
        <Segment padded="very">
          <Header as="h1">
            <Icon name="registered" />
            Register Today!
          </Header>
          <Divider />
          <p>
            No Account? Sign up today to place your website on the first page of
            major search engines! <br /> Call us anytime toll-free.
          </p>
        </Segment>
      </div>
    </>
  );
};
export default Register;
