import React from 'react';
import { List, Button, Icon } from 'semantic-ui-react';


const FileListItem = ({ file, removeFile }: any) => {

    return (
        <List.Item data-fileid={file?.name}>
            <div className='content' style={{ float: 'right' }}>
                <Button size='mini' type='button' compact basic={true} color='red' content='remove' data-testid={file?.name} data-filename={file?.name} onClick={removeFile} />
            </div>

            <div className='content content-list-fix' style={{ float: 'left' }}>
                <Icon name='file' />
                {file?.name}
            </div>
        </List.Item>
    );
}

export default FileListItem;