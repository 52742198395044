// Packages
import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
// import { Loader } from 'semantic-ui-react';
import jwt from 'jsonwebtoken';
import axios from 'axios';

import Nav from "./components/Header";

// GraphQL
import { ApolloClient } from '@apollo/client/core';
import { ApolloLink, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from '@apollo/client/cache';
// import ApolloClient from 'apollo-client';
// import { ApolloLink } from 'apollo-link';
// import { createHttpLink } from 'apollo-link-http';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory';

// CSS
import "./App.css";
import "./ClientSiteSupport.css";

// Components
import Home from "./components/Home";
import Login from "./components/Login";
import Registration from "./components/Register";
import RequestPassword from "./components/RequestPassword";
import Footer from "./components/Footer";
import Revisions from './components/Revisions/Revisions';
import Referral from './components/Referral/Referral';
import Upgrades from './components/Updgrades/Upgrades';
import BusinessMarketingReview from './components/BusinessMarketingReview/BusinessMarketingReview';
import KeywordApproval from './components/KeywordApproval/KeywordApproval';
import RealEstateMarketingReview from './components/RealEstateMarketingReview/RealEstateMarketingReview';
import RecurringPayments from './components/RecurringPayments/RecurringPayments';
import WebsiteQuestionnaire from './components/WebsiteQuestionnaire/WebsiteQuestionnaire';
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry';

// Context
import UserContext from "./context/UserContext/Context/context";
import { rootURL } from "./config/config";

// React toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CSS Links

// const httpLink = () =>
//   createHttpLink({
//     uri: 'http://159.203.107.1:5000/graphql',
//     credentials: 'same-origin',
//   });

// const espo = () =>
//   createHttpLink({
//     uri: 'http://159.203.107.1:5000/espo',
//     credentials: 'same-origin',
//   });

const httpLink = () =>
  createHttpLink({
    uri: `${rootURL}/graphql`,
    credentials: 'same-origin',
  });

const espo = () =>
  createHttpLink({
    uri: `${rootURL}/espo`,
    credentials: 'same-origin',
  });

// Init Cache
const cache = new InMemoryCache();

// Init Middleware
const middlewareLink = setContext(() => ({
  headers: {
    'x-token': localStorage.getItem('token'),
    'x-refresh-token': localStorage.getItem('refreshToken'),
  }
}));

// Afterware Link
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;

    if (headers) {
      const token = headers.get("x-token");
      const refreshToken = headers.get("x-refresh-token");

      if (token) {
        localStorage.setItem("token", token);
      }
      if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
      }
    }
    return response;
  });
});

// Link for Sequelize

const sequelizeLink = ApolloLink.from([
  // handleError,
  middlewareLink,
  afterwareLink,
  httpLink()
]);

// Link for ESPO
const espoLink = ApolloLink.from([middlewareLink, afterwareLink, espo()]);

// SEQUELIZE CLIENT
export const SequelizeClient = new ApolloClient({
  connectToDevTools: true,
  link: sequelizeLink,
  cache,
});

//  ESPO CLIENT
export const EspoClient = new ApolloClient({
  link: espoLink,
  cache,
  //uri: "http://localhost:8080/graphql",
});

const PrivateRoute = ({ component: Component, setContextUser, ...rest }) => {

  // Currently, User & Context User are both being set here
  const [user, setUser] = useState<any | null>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  const checkUser = () => {
    try {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const tokenSecret = process.env.REACT_APP_SECRET;
      const decodedToken = jwt.decode(token);
      const decodedRefreshToken = jwt.decode(refreshToken);

      // If neither tokens exist, throw error
      if (!token || !refreshToken) throw new Error('No token');

      // If refresh token has expired, throw an error
      if (new Date().getTime() > decodedRefreshToken.exp * 1000) {
        throw new Error('refresh token expired');
      }

      // If first token has expired
      if (new Date().getTime() > decodedToken.exp * 1000) {
        return axios.post(`${rootURL}/checkUser`, {}, {
          // mode: "cors", // no-cors, *cors, same-origin
          // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "x-token": token,
            "x-refresh-token": refreshToken
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: "follow", // manual, *follow, error
          // referrer: "no-referrer" // no-referrer, *client
        })
          .then(result => {
            localStorage.setItem("token", result?.data?.token);
            localStorage.setItem("refreshToken", result?.data?.refreshToken);
            const user = jwt.verify(localStorage.getItem('token'), tokenSecret)
            setUser(user?.user);
            setContextUser(user?.user)
            setIsAuthenticated(true);
            return;
          })

      } else {
        // Both tokens are valid, login normally
        const user = jwt.verify(localStorage.getItem('token'), tokenSecret);

        setUser(user?.user);
        setContextUser(user?.user)
        setIsAuthenticated(true);
      }
    } catch (error) {
      localStorage.clear();
      setIsAuthenticated(false);
      setContextUser(null);
      setUser(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  return <Route {...rest} render={(props) => (isAuthenticated)
    ? <ErrorBoundry>
      <Component auth={isAuthenticated} user={user} checkUser={checkUser} {...props} />
    </ErrorBoundry>
    : <Redirect to='/login' />
  } />
};



// App Component
function App(props) {
  const {
    loading,
    error: userContextError,
    errorModal
  }: any = useContext(UserContext);
  console.log('Environment: ', process.env.REACT_APP_ENV);
  const [contextUser, setContextUser] = React.useState<any>();

  const logoutUser = () => {
    localStorage.clear();
    props.history.push('/login');
    setContextUser(null);
  }

  return (
    <ApolloProvider client={SequelizeClient}>
      <UserContext.Provider value={{ user: contextUser, setContextUser, logoutUser }}>
        <div className="App">
          <Nav {...props} />
          {
            /* (localStorage.getItem('token'))?*/
            <Switch>
              <Route exact path="/" render={(props) => <ErrorBoundry> <Login {...props} /> </ErrorBoundry>} />
              <Route exact path="/login" render={(props) => <ErrorBoundry> <Login {...props} /> </ErrorBoundry>} />
              <Route exact path="/login/:token/:refToken" render={(props) => <ErrorBoundry> <Login {...props} /> </ErrorBoundry>} />
              <Route path="/requestpassword" render={(props) => <ErrorBoundry><RequestPassword /></ErrorBoundry>} />
              <PrivateRoute path="/account" setContextUser={setContextUser} component={Home} />
              <PrivateRoute path="/register" setContextUser={setContextUser} component={Registration} />
              <PrivateRoute path="/revisions" setContextUser={setContextUser} component={Revisions} />
              <PrivateRoute path="/referral" setContextUser={setContextUser} component={Referral} />
              {/* TODO: Fix duplicate upgrades routes. Some links point to the uppercase, some point to the lowercase */}
              <PrivateRoute path="/upgrades" component={Upgrades} setContextUser={setContextUser} />
              <PrivateRoute path="/Upgrades" component={Upgrades} setContextUser={setContextUser} />
              <PrivateRoute path="/businessmarketingreview" component={BusinessMarketingReview} setContextUser={setContextUser} />
              <PrivateRoute path="/keywordapproval" component={KeywordApproval} setContextUser={setContextUser} />
              <PrivateRoute path="/realestatemarketingreview" setContextUser={setContextUser} component={RealEstateMarketingReview} />
              <PrivateRoute path="/recurring" setContextUser={setContextUser} component={RecurringPayments} />
              <PrivateRoute path="/websitequestionnaire" setContextUser={setContextUser} component={() => <WebsiteQuestionnaire />} />
              {/* <PrivateRoute path="/realestatewebsitequestionnaire" component={() => <WebsiteQuestionnaire type='real estate' />} /> */}
            </Switch>

            /* : <div className="App"><Nav {...props} /><Login /> <Footer {...props} /> </div>*/
          }
          <ToastContainer />
          <Footer {...props} />
        </div>
      </UserContext.Provider>
    </ApolloProvider>
  );
}

export default withRouter(App);
