import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

// Interfaces
import { IHeader } from '../../../Interfaces/Interfaces';


const SegmentHeader: React.FunctionComponent<IHeader> = ({ header }) => {
    return (
        <Grid textAlign='left'>
            <Grid.Column className='margin-padding-fix'>
                <Header dividing content={header} as='h2' />
            </Grid.Column>
        </Grid>
    );
}

export default SegmentHeader;