import React from 'react';
import { Message, Form, Label, Header } from 'semantic-ui-react';

// Context
import { FormContext } from '../../../HOC/FormHOC/context';

const OrderForm = (props) => {

    const { handleOnChange, state } = React.useContext<any>(FormContext);

    return (
        <>
            <Header dividing content='Order Form' as='h2' className='left-align' />
            <Message>
                Fields marked with an <span className="color-red-regular">*</span> are required
        </Message>

            <Form.Group className='left-align'>
                <Form.Field>
                    <Label content='Consultant:' className='no-background-label' />
                    <Form.Input error={!state?.consultant} required name="consultant" placeholder='Consultant' value={state?.consultant} onChange={handleOnChange} />
                    {(!state?.consultant) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field>
                    <Label content='Order Code (if applicable):' className='no-background-label' />
                    <Form.Input error={!state?.orderCode} required name='orderCode' placeholder='Order Code' value={state?.orderCode} onChange={handleOnChange} />
                    {(!state?.orderCode) ? <Label basic pointing color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
            </Form.Group>
        </>
    );
};

export default OrderForm;
