import React from "react";
import { Header, Form, Label, Dropdown } from "semantic-ui-react";

// Context
import { FormContext } from "../../../../HOC/FormHOC/context";

// Interfaces
import { ICardInformationProps } from "../../../../../Interfaces/Interfaces";

const CardInformation: React.FunctionComponent<any> = ({
  cardNumber,
  cardExpireMonth,
  cardExpireYear,
  cvv,
  paymentMethod,
  dailyCap,
  cardFirstName,
  cardLastName,
  options,
  monthDropdownOptions,
  forceUpdate,
  simpleValidator,
}) => {
  const {
    handleOnChange,
    handleDropdownChange,
    handlePaymentTypeOnClick,
    state,
  } = React.useContext<any>(FormContext);

  const amex =
    paymentMethod && paymentMethod.text.toLowerCase() === "american express";
  return (
    <>
      <Header dividing content="Card Information" />
      <Form.Group widths={5}>
        <Form.Field width={6}>
          <Header dividing content="Payment Method" />
          <Dropdown
            selection
            fluid
            error={!paymentMethod}
            placeholder="Select Payment Type"
            name="paymentMethod"
            options={options}
            value={paymentMethod?.value}
            onChange={handleDropdownChange}
          />
          {simpleValidator.message("paymentMethod", paymentMethod, "required")}
        </Form.Field>
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Field width={6}>
          <Label className="no-background-label" content="Card Number" />
          <Form.Input
            error={!cardNumber}
            required
            name="cardNumber"
            maxlength="16"
            placeholder="Card #"
            onChange={handleOnChange}
            onBlur={() => {
              simpleValidator.showMessageFor("cardNumber");
              simpleValidator.showMessageFor("paymentMethod");

              forceUpdate();
            }}
            value={cardNumber}
          />

          {amex
            ? simpleValidator.message(
                "cardNumber",
                cardNumber,
                "required|min:15|max:15"
              )
            : simpleValidator.message(
                "cardNumber",
                cardNumber,
                "required|min:16|max:16"
              )}
        </Form.Field>
        <Form.Field width={4}>
          <Label content="Expiration:" className="no-background-label" />
          <Form.Field>
            <Form.Dropdown
              required
              selection
              name="cardExpireMonth"
              fluid
              error={!cardExpireMonth}
              placeholder="Month"
              value={cardExpireMonth?.value}
              options={monthDropdownOptions}
              onChange={handleDropdownChange}
            />
            {!cardExpireMonth?.value ? (
              <Label
                basic
                pointing
                color="red"
                className="center-fix no-background-label"
                content="Please make a selection"
              />
            ) : (
              ""
            )}
          </Form.Field>
        </Form.Field>
        <Form.Field width={3}>
          <Label className="no-background-label">Year</Label>
          <Form.Field>
            <Form.Input
              error={!cardExpireYear}
              required
              name="cardExpireYear"
              maxlength="4"
              placeholder="Year"
              value={cardExpireYear}
              onChange={handleOnChange}
            />
            {!cardExpireYear ? (
              <Label
                basic
                pointing
                color="red"
                className="center-fix no-background-label"
                content="Please add some details."
              />
            ) : (
              ""
            )}
          </Form.Field>
        </Form.Field>
        <Form.Field width={3}>
          <Label content="CVV:" className="no-background-label" />
          <Form.Input
            error={!state?.cvv}
            required
            name="cvv"
            maxLength="3"
            placeholder="CVV"
            onChange={handleOnChange}
            value={state?.cvv}
          />
          {!state?.cvv ? (
            <Label
              basic
              pointing
              color="red"
              className="center-fix no-background-label"
              content="Please add some details."
            />
          ) : (
            ""
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Label className="no-background-label" content="First Name: " />
          <Form.Input
            error={!cardFirstName}
            name="cardFirstName"
            placeholder="First Name"
            onChange={handleOnChange}
            value={cardFirstName}
          />
          {!cardFirstName ? (
            <Label
              basic
              pointing
              color="red"
              className="center-fix no-background-label"
              content="Please add some details."
            />
          ) : (
            ""
          )}
        </Form.Field>
        <Form.Field>
          <Label className="no-background-label" content="Last Name: " />
          <Form.Input
            error={!cardLastName}
            required
            name="cardLastName"
            placeholder="Last Name"
            value={cardLastName}
            onChange={handleOnChange}
          />
          {!cardLastName ? (
            <Label
              basic
              pointing
              color="red"
              className="center-fix no-background-label"
              content="Please add some details."
            />
          ) : (
            ""
          )}
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Label className="no-background-label" content="Daily Cap: " />
        <Form.Input
          name="dailyCap"
          placeholder="Daily Cap"
          onChange={handleOnChange}
          value={dailyCap}
        />
      </Form.Field>
    </>
  );
};

CardInformation.defaultProps = {
  options: [
    { text: "Visa", value: 1 },
    { text: "Master Card", value: 2 },
    { text: "American Express", value: 3 },
    { text: "Discover", value: 4 },
  ],
  monthDropdownOptions: [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ],
};

export default CardInformation;
