import React, { useRef, useState } from 'react';
import { Grid, Message, Form, Button, Label, Header, Segment, Popup, Icon, Container, Divider, Progress, List } from 'semantic-ui-react';

// Components
import ContactHeaderBar from '../ReusableComponents/ContactHeaderBar/ContactHeaderBar';

import DatePicker from 'react-datepicker';

// Container
import WebsiteQuestionnaireContainer from './Container/WebsiteQuestionnaireContainer';
import FormSegment from '../ReusableComponents/FormSegment/FormSegment';
import WebsiteQuestionnaireNote from './WebsiteQuestionnaireNote/WebsiteQuestionnaireNote';
import { IWebsiteQuestionnaireState } from './Interfaces/Interfaces';
import FormFieldInput from '../ReusableComponents/FormFieldInput/FormFieldInput';
import DoubleFormSegment from '../ReusableComponents/DoubleFormSegment/DoubleFormSegment';
import FormFieldCheckbox from '../ReusableComponents/FormFieldCheckbox/FormFieldCheckbox';
import WarningLabel from '../ReusableComponents/WarningLabel/WarningLabel';
import RenderWrapper from '../ReusableComponents/RenderWrapper/RenderWrapper';
import FileUploadItem from '../ReusableComponents/FileUpload/FileUploadItem/FileUploadItem';
import SubmitForm from '../ReusableComponents/SubmitForm/SubmitForm';

const WebsiteQuestionnaire: React.FunctionComponent = () => {

    const chooseCustomDesignFileRef = useRef(null);
    const chooseFileUploadFileRef = useRef(null);


    return (
        <WebsiteQuestionnaireContainer>
            {({ state2, onChange, onCheckboxClick, onReferredAsClick, onFileChange, onFileUpload, onCancelFileUpload, onRemoveFile, onPageInputChange, onDateChange, onSubmit, ...state }: { state2: IWebsiteQuestionnaireState, onChange: (arg0: any) => any, onCheckboxClick: (arg0?: boolean) => any, onReferredAsClick: () => any, onFileChange: (fileUploadType: 'custom' | 'fileUpload') => (e: React.ChangeEvent<HTMLInputElement>) => any, onFileUpload: (arg0: 'customDesignFiles' | 'fileUploadFiles') => any, onCancelFileUpload: (fileUploadType: 'custom' | 'fileUpload') => (e: React.ChangeEvent<HTMLButtonElement>) => any, onRemoveFile: (arg0: 'customDesignFiles' | 'fileUploadFiles') => any, onPageInputChange: (inputType: 'title' | 'details', pageId: string) => any, onDateChange: (date: Date) => any, onSubmit: any, state: object }) => <>
                <ContactHeaderBar Header='Website Questionnaire' />

                <FormSegment Header='Custom Website Confirmation Code'>
                    <Grid.Column>
                        <Message><p><b>Note: Please enter the code with no spaces.
<br />
                Fields marked with an <span className='color-red-regular'>*</span> are required </b></p></Message>
                        <Form.Field inline className='code-flex error margin-fix'>
                            <Label className='no-background-label'>Confirmation Code: <span className='color-red-regular'>*</span></Label>
                            <Form.Input name='formCode' placeholder='Confirmation Code' onChange={onChange} value={state2?.formCode} />
                            {/* <Form.Button type='button' color='blue' className='marg-left' content='Next Step' onClick={() => true} /> */}
                        </Form.Field>
                        {(!state2?.isWebsiteQuestionnaireCode) ? <Label pointing color='red' basic className='error-fix' content="Please enter valid 'Confirmation Code', with no spaces." /> : null}
                    </Grid.Column>
                </FormSegment>

                <RenderWrapper isVisable={(state2?.isWebsiteQuestionnaireCode)}>
                    <Form style={{ textAlign: 'left' }} data-testid={(state2?.isRealEstateCode) ? 'RealEstateWebsiteQuestionnaireForm' : 'MLMWebsiteQuestionnaireForm'} id={(state2?.isRealEstateCode) ? 'RealEstateWebsiteQuestionnaireForm' : 'MLMWebsiteQuestionnaireForm'} onSubmit={onSubmit}>

                        <WebsiteQuestionnaireNote
                            header={`${state2?.isRealEstateCode ? 'Real Estate Website Questionnaire: ' : 'Website Questionnaire: '} ${state2?.numberOfPages}-Page Form`}
                            messageHeader={`Congratulations on the Purchase of your ${state2?.numberOfPages}-Page Custom Website!`}
                            message={
                                <>
                                    <br />
                           Thank you for ordering a Custom Web Build! This is your preference form! Once completed, we will build your new site!
   <br /><br />
   You will then have the opportunity to make any changes you would like, and then a final revision in case there are any last minute changes.
   <br /> <br />
    As always, we appreciate your business and we look forward to helping your business grow!
<br /> <br />
                                </>} />

                        <>
                            <Segment>
                                <Grid>
                                    <Grid.Column>

                                        <Header dividing content={'Contact Information'} textAlign="left" as='h2' />

                                        <Form.Group style={{ textAlign: 'left' }} widths='2'>
                                            <FormFieldInput
                                                required={true}
                                                label={<label>Customer Service Rep Name: </label>}
                                                name='customerServiceRepName'
                                                placeholder='Customer Service Rep Name'
                                                warningLabelProps={{
                                                    content: "Please enter 'CS Rep. Name'",
                                                }}
                                                onChange={onChange}
                                                value={state2?.customerServiceRepName}
                                            />

                                            <FormFieldInput
                                                required
                                                label={<label>Name: </label>}
                                                name='name'
                                                placeholder='Name'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Name'.",
                                                }}
                                                onChange={onChange}
                                                value={state2?.name}
                                            />
                                        </Form.Group>

                                        <FormFieldInput
                                            type='email'
                                            required
                                            label={<label>Email:</label>}
                                            name='email'
                                            placeholder='Email'
                                            warningLabelProps={{
                                                content: "Please enter a 'Email '.",
                                            }}
                                            onChange={onChange}
                                            value={state2?.email}
                                        />
                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                type='number'
                                                required
                                                label={<label>Phone (to be used on site): </label>}
                                                name='phone'
                                                placeholder='Phone'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Phone number'.",
                                                }}
                                                onChange={onChange}
                                                value={state2?.phone}
                                            />
                                            <FormFieldInput
                                                type='number'
                                                label={<label>Secondary Phone (to be used on site): </label>}
                                                name='secondaryPhone'
                                                placeholder='Secondary Phone'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Secondary Phone number'.",
                                                }}
                                                onChange={onChange}
                                                value={state2?.secondaryPhone}
                                            />

                                        </Form.Group>

                                        <Form.Group widths='16'>

                                            <Form.Field width='16'>
                                                <Form.Input
                                                    required
                                                    label={<label>How do you want to be referred to? (I, us, we, group, etc.) </label>}
                                                    name='referredTo'
                                                    placeholder='Referred To'
                                                />
                                            </Form.Field>

                                        </Form.Group>

                                        <RenderWrapper isVisable={(state2?.isRealEstateCode)}>
                                            <Form.Group className={''} grouped style={{ textAlign: 'left' }}>
                                                <label>What do you want to be referred to as? <span className="color-red-regular">*</span></label>

                                                <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_broker} data-testid='ReferredAsBroker' radio label={<label>Broker</label>} name='referredAs_broker' value='Broker' />
                                                <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_realEstateAgent} data-testid='ReferredAsRealEstateAgent' radio label={<label>Real Estate Agent</label>} name='referredAs_realEstateAgent' value='Real Estate Agent' />
                                                <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_realtor} data-testid='ReferredAsRealtor' radio label={<label>REALTOR&reg;</label>} name='referredAs_realtor' value='Realtor' />
                                                <FormFieldCheckbox onClick={onReferredAsClick} checked={state2?.referredAs_other} data-testid='ReferredAsOther' radio label={<label>Other</label>} name='referredAs_other' value='Other' />
                                                <WarningLabel visable={(!state2?.referredAs_broker && !state2?.referredAs_realEstateAgent && !state2?.referredAs_realtor && !state2?.referredAs_other)} />
                                                <FormFieldInput label={<label>Other: </label>} name='referredAs_otherInput' placeholder='Other' disabled={(!state2?.referredAs_other)} onChange={onChange} value={state2?.referredAs_otherInput} />
                                            </Form.Group>
                                        </RenderWrapper>

                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </>
                        {/* ---------------------------------------------------- DNS INFORMATION----------------------------------------- */}
                        <Segment textAlign='left'>
                            <Grid>
                                <Grid.Column className='margin-padding-fix'>
                                    <Header dividing content='DNS Information' />

                                    <Message>
                                        <Message.Header>
                                            Let's Get Started!
                        </Message.Header>
                                        <br />
                            We must host any domain for your website that will be used to build your site and as long as you have a
                            campaign with us.
              <br />
                            If you own your domain, we will send you instructions on changing your DNS, but otherwise, you can request
                            different domain ideas which we will check the availability on from your #1 choice down!
                    </Message>
                                </Grid.Column>
                            </Grid>

                            <Grid columns={2}>
                                <Grid.Column>
                                    <Form.Group grouped>
                                        <label>Do you own the domain you want us to use? <span className="color-red-regular">*</span></label>
                                        <FormFieldCheckbox data-testid='ownDomainYes' radio label='Yes' name='ownDomain' value='Yes' checked={state2?.ownDomain} onClick={onCheckboxClick(true)} />
                                        <FormFieldCheckbox data-testid='ownDomainNo' radio label='No' name='ownDomain' value='No' checked={(!state2?.ownDomain && state2?.ownDomain !== undefined)} onClick={onCheckboxClick(false)} />

                                        <WarningLabel visable={(state2?.ownDomain === undefined)} />
                                    </Form.Group>

                                    <FormFieldInput required label={<label>Your Domain</label>} placeholder='Your Domain' name='domain' value={state2?.domain} onChange={onChange} />
                                    <Form.Field>
                                        <label>Suggested Domains: </label>
                                        <Form.TextArea name='suggestedDomains' placeholder='Suggested Domains' value={state2?.suggestedDomains} onChange={onChange} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Group grouped>
                                        <label>Do you have your DNS information? </label>
                                        <Popup
                                            position='top center'
                                            content='We use this information to host your domain on our servers.'
                                            trigger={<Icon name='info circle' color='blue' />}
                                        />
                                        <FormFieldCheckbox data-testid='dnsInformationYes' radio label='Yes' name='dnsInformation' value='Yes' checked={state2?.dnsInformation} onClick={onCheckboxClick(true)} />
                                        <FormFieldCheckbox data-testid='dnsInformationNo' radio label='No' name='dnsInformation' value='No' checked={(!state2?.dnsInformation && state2?.dnsInformation !== undefined)} onClick={onCheckboxClick(false)} />

                                        <WarningLabel visable={(state2?.dnsInformation === undefined)} />
                                    </Form.Group>
                                    <FormFieldInput onChange={onChange} value={state2?.parentCompany} label={<label>What is your parent company name?:</label>} name='parentCompany' placeholder='Parent Company' />
                                    <FormFieldInput onChange={onChange} value={state2?.websiteDomain} label={<label>Website the domain was bought on (ex. GoDaddy.com):</label>} name='websiteDomain' placeholder='Website the domain was bought on' />
                                    <Form.Group widths='2'>
                                        <FormFieldInput label={<label>Username: </label>} onChange={onChange} name='username' placeholder='Username' value={state2?.username} />
                                        <FormFieldInput label={<label>Password: </label>} onChange={onChange} name='password' placeholder='Password' value={state2?.password} />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid>
                        </Segment>







                        {/* ================================================= Website Questionnaire Custom ======================================================== */}



                        <FormSegment Header='Custom Design'>
                            <Grid columns={2}>
                                <Grid.Column>
                                    <Form.Field error={(!state2?.siteTitle)}>
                                        <Message>
                                            <strong>What do you want the title of your site to be?</strong> (This is the bold
                    power statement of your site, whether it is a company name or a specific catch phrase etc.)
                        </Message>
                                        <label>Site Title: <span className="color-red-regular">*</span></label>
                                        <FormFieldInput name='siteTitle' placeholder='Site Title' onChange={onChange} value={state2?.siteTitle} />
                                    </Form.Field>



                                    <Form.Group grouped>
                                        <label>Do you have a logo<span className="color-red-regular">*</span></label>
                                        <FormFieldCheckbox data-testid='haveLogoYes' radio label='Yes' name='haveLogo' value='Yes' checked={state2?.haveLogo} onClick={onCheckboxClick(true)} />
                                        <FormFieldCheckbox data-testid='haveLogoNo' radio label='No' name='haveLogo' value='No' checked={(!state2?.haveLogo && state2?.haveLogo !== undefined)} onClick={onCheckboxClick(false)} />

                                        <WarningLabel visable={(state2?.haveLogo === undefined)} />
                                    </Form.Group>

                                    <Form.Field>
                                        <Form.TextArea label={<label>Description for logo: </label>} name='logoDescription' placeholder='Description for logo' onChange={onChange} value={state2?.logoDescription} />
                                    </Form.Field>

                                    {/* Come back to */}
                                    <>
                                        <Form.Field>
                                            <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='File Upload' />
                                            <br />
                                            {/* <Form.Input type='file'> */}
                                            <input
                                                data-testid='selectedCustomDesignFile'
                                                ref={chooseCustomDesignFileRef}
                                                type='file'
                                                name='selectedCustomDesignFile'
                                                onChange={onFileChange('custom')}
                                                style={{
                                                    opacity: '0',
                                                    position: 'absolute',
                                                    pointerEvents: 'none',
                                                    // alternative to pointer-events, compatible with all browsers, just make it impossible to find
                                                    width: '1px',
                                                    height: '1px',
                                                }} />
                                            <Button basic size='small' htmlFor='file' type='button' onClick={() => chooseCustomDesignFileRef?.current?.click?.()}>
                                                <Icon name='paperclip' />Choose File</Button>

                                            {/* </Form.Input> */}File size must be 10MB or smaller.</Form.Field>
                                        <List animated verticalAlign='middle' celled>
                                            <List.Item>
                                                <Divider />
                                                <RenderWrapper isVisable={(state2?.selectedCustomDesignFile)}>
                                                    <div style={{ float: 'right' }} className='content'>

                                                        <Button basic size='tiny' type='button' name='selectedCustomDesignFile' onClick={onCancelFileUpload('custom') as any}>
                                                            cancel</Button>
                                                        <Button size='tiny' color='blue' type='button' data-testid='customDesignFileUploadButton' name='selectedCustomDesignFile' onClick={onFileUpload('customDesignFiles')}>
                                                            <Icon name='upload' />upload</Button>
                                                    </div>
                                                </RenderWrapper>

                                                <div className='content content-upload-fix'>{(state2?.selectedCustomDesignFile) ? state2?.selectedCustomDesignFile?.name : null}</div>
                                                <Divider />


                                                <Progress id='CustomDesignProgress_Uploading' style={{ marginBottom: '0px' }} active size='small' percent={state2?.customDesignPercentage_uploading} />
                                                <Container fluid textAlign='center'>
                                                    <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='Uploading Files' />
                                                </Container>

                                                <Progress id='CustomDesignProgress_Requirements' style={{ marginBottom: '0px' }} size='small' warning percent={state2?.customDesignPercentage_requirements} />
                                                <Container textAlign='center'>
                                                    <Label style={{ backgroundColor: 'transparent' }} content="Your file didn't meet the requirements." />
                                                </Container>

                                                <Progress id='CustomDesignProgress_Error' style={{ marginBottom: '0px' }} size='small' error percent={state2?.customDesignPercentage_error} />
                                                <Container textAlign='center'>
                                                    <Label style={{ backgroundColor: 'transparent' }} content="There was an error." />
                                                </Container>

                                                <Progress id='CustomDesignProgress_Success' style={{ marginBottom: '0px' }} size='small' success percent={state2?.customDesignPercentage_successful} />
                                                <Container textAlign='center'>
                                                    <Label style={{ backgroundColor: 'transparent' }} content="Upload Successful!" />
                                                </Container>
                                            </List.Item>
                                        </List>

                                        <List animated relaxed divided verticalAlign='middle' data-testid='customDesignFileList'>
                                            {(state2?.customDesignFiles as [File])?.map?.(file => { return <FileUploadItem file={file} removeFile={onRemoveFile('customDesignFiles')} /> })}
                                        </List>
                                    </>



                                </Grid.Column>
                                <Grid.Column>
                                    <Message>
                                        <strong>Choose your site colors.</strong> We understand you may not know what you
                  want, but anything will help. If your favorite color is green or red, let us know so we can use that as a
                  base to design from. If you know exactly what colors you want please fill them in below.
                    </Message>
                                    <Form.Group>
                                        <FormFieldInput label={<label>Background Color: </label>} placeholder='Background Color: ' name='backgroundColor' onChange={onChange} value={state2?.backgroundColor} />
                                        <FormFieldInput label={<label>Letter Color: </label>} placeholder='Letter Color: ' name='letterColor' onChange={onChange} value={state2?.letterColor} />
                                        <FormFieldInput label={<label>Highlight Color: </label>} placeholder='Highlight Color: ' name='highlightColor' onChange={onChange} value={state2?.highlightColor} />
                                    </Form.Group>
                                    <Message>
                                        <strong>The reason behind a custom site is to brand yourself and sell who YOU are.
                                        You hired us to separate you from everyone else who has a template or an unprofessional website. We can
                                        highlight the benefits and reasons for working with you to your potential prospect. Our custom site will
                be linked to your original URL.</strong>
                                        <br /><br />
                        Home Business pages or Product pages on the custom would link to product pages or any capture pages you
                        have.
              <br /><br /><strong>The reason for this is some sites have copyrighted information that without prior consent,
                                        we cannot copy so if you want pertinent information about the program we must link it to your original
                site.</strong>
                                    </Message>
                                    <Form.TextArea
                                        label={<><label>What do you want the site to do?
              </label> <p>(<em>For example: direct traffic, fill in a form, talk about product, etc.</em>)</p></>}
                                        placeholder='What do you want the site to do'
                                        name='sitePurpose'
                                        onChange={onChange}
                                        value={state2?.sitePurpose}
                                    />
                                    {/* TODO: Make this a controlled component */}
                                    <Form.TextArea
                                        label={<><label>Additional Links you want on the site
              </label></>}
                                        placeholder='Additional links'
                                        name='additionalLinks'
                                        onChange={onChange}
                                        value={state2?.additionalLinks}
                                    />
                                </Grid.Column>
                            </Grid>
                        </FormSegment>


                        {/* File Upload */}

                        <FormSegment Header='File Upload'>
                            <>
                                <Form.Field>
                                    <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='File Upload' />
                                    <br />
                                    {/* <Form.Input type='file'> */}
                                    <input
                                        ref={chooseFileUploadFileRef}
                                        type='file'
                                        name='selectedfileUploadFile'
                                        onChange={onFileChange('fileUpload')}
                                        style={{
                                            opacity: '0',
                                            position: 'absolute',
                                            pointerEvents: 'none',
                                            // alternative to pointer-events, compatible with all browsers, just make it impossible to find
                                            width: '1px',
                                            height: '1px',
                                        }} />
                                    <Button basic size='small' htmlFor='file' type='button' onClick={() => chooseFileUploadFileRef?.current?.click?.()}>
                                        <Icon name='paperclip' />Choose File</Button>

                                    {/* </Form.Input> */}File size must be 10MB or smaller.</Form.Field>
                                <List animated verticalAlign='middle' celled>
                                    <List.Item>
                                        <Divider />
                                        <RenderWrapper isVisable={(state2?.selectedfileUploadFile)}>
                                            <div style={{ float: 'right' }} className='content'>
                                                <Button basic size='tiny' type='button' name='selectedfileUploadFile' onClick={onCancelFileUpload('fileUpload') as any}>
                                                    cancel</Button>
                                                <Button size='tiny' color='blue' type='button' name='selectedfileUploadFile' onClick={onFileUpload('fileUploadFiles')}>
                                                    <Icon name='upload' />upload</Button>
                                            </div>
                                        </RenderWrapper>

                                        <div className='content content-upload-fix'>{(state2?.selectedfileUploadFile) ? state2?.selectedfileUploadFile?.name : null}</div>
                                        <Divider />


                                        <Progress style={{ marginBottom: '0px' }} active size='small' percent={state2?.fileUpload_uploading} />
                                        <Container fluid textAlign='center'>
                                            <Label style={{ backgroundColor: 'transparent', color: 'black', paddingLeft: '0px' }} content='Uploading Files' />
                                        </Container>

                                        <Progress style={{ marginBottom: '0px' }} size='small' warning percent={state2?.fileUpload_requirements} />
                                        <Container textAlign='center'>
                                            <Label style={{ backgroundColor: 'transparent' }} content="Your file didn't meet the requirements." />
                                        </Container>

                                        <Progress style={{ marginBottom: '0px' }} size='small' error percent={state2?.fileUpload_error} />
                                        <Container textAlign='center'>
                                            <Label style={{ backgroundColor: 'transparent' }} content="There was an error." />
                                        </Container>

                                        <Progress style={{ marginBottom: '0px' }} size='small' success percent={state2?.fileUpload_successful} />
                                        <Container textAlign='center'>
                                            <Label style={{ backgroundColor: 'transparent' }} content="Upload Successful!" />
                                        </Container>
                                    </List.Item>
                                </List>

                                <List animated relaxed divided verticalAlign='middle'>
                                    {(state2?.fileUploadFiles as [File])?.map?.(file => { return <FileUploadItem file={file} removeFile={onRemoveFile('fileUploadFiles')} /> })}
                                </List>
                            </>
                            <Form.TextArea
                                label={<label>What would you like us to do with these files?</label>}
                                name='purposeOfFiles'
                                placeholder='What would you like us to do with these files?'
                                onChange={onChange}
                                value={state2?.purposeOfFiles}
                            />
                        </FormSegment>




                        {/* Real estate property search */}
                        <RenderWrapper isVisable={(state2?.isRealEstateCode)}>
                            <FormSegment Header='Real Estate Property Search Pages'>
                                <Message>
                                    We will link to property search or any special features you want from your original website and happily frame IDX links to your custom site. If you have a WordPress-enabled IDX, we can also work with this information.
            <br /><br /><br />
                                    <Message.Header>Please Note: </Message.Header>
                                    <ul>
                                        <li>We are <u>not a vendor company</u> and will not sign any contract from an IDX vendor.</li>
                                        <li>We <u>do not build custom IDX</u> nor do we have any control over the design or listings in the IDX that we frame.</li>
                                        <li>We frame in current client-owned IDX to the custom web build pages.</li>
                                        <li>We <u>cannot modify</u> search areas, property listings, or information in IDX.</li>
                                    </ul>
                                </Message>

                                <Form.Field>
                                    <label>IDX Information:</label>
                                    <em>Enter your framed link for IDX or the product key and name of your WordPress-enabled IDX provider.</em>

                                    <Form.TextArea rows='9' name='idxInformation' placeholder='IDX Information' onChange={onChange} value={state2?.idxInformation} />
                                </Form.Field>

                                <Grid>
                                    <Grid.Column className='margin-padding-fix'>
                                        <Header dividing as='h2' content='Real Estate Questionnaire' />
                                    </Grid.Column>
                                </Grid>

                                <Grid columns='2'>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>What connections do you have?</label>
                                            <em>(Home inspection specialists, landscapers, etc.)</em>
                                            <Form.Input name='connections' placeholder='What connections do you have' onChange={onChange} value={state2?.connections} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>What do you do special for clients?</label>
                                            <em>(do you stage homes? For buyers, do you spend time with them to get their dynamic to to find a house
                for them and will offer work to get their dream home?)</em>
                                            <Form.Input name="special" placeholder='What do you do special for clients' onChange={onChange} value={state2?.special} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>How long have you been a real estate agent or broker?</label>
                                            <Form.Input name="timeAsAgent" placeholder="How long have you been a real estate agent or broker" onChange={onChange} value={state2?.timeAsAgent} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Do you do anything extra to help your clients to make an educated decision on such a large
                                            investment?
              </label>
                                            <Form.Input name="helpClients" placeholder="Do you do anything extra to help your clients" onChange={onChange} value={state2?.helpClients} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>What type of Real Estate agent are you? Property? Commercial? Broker? Etc.</label>
                                            <Form.Input name="typeOfAgent" placeholder="What type of Real Estate agent are you" onChange={onChange} value={state2?.typeOfAgent} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                            </FormSegment>
                        </RenderWrapper>

                        <>
                            <Message className='margin-bottom-fix' style={{ textAlign: 'left' }}>
                                <Message.Header>Pages and Content: </Message.Header>

                                <RenderWrapper isVisable={(state2?.isRealEstateCode)}>

                                    <br />
        This is a custom site. We want the site to feel as if you are talking to your visitors and the flow is natural
        to how you want to present your brand so you can either write a paragraph of content for each page you paid for
        and a bio.
            <br /><br />
                                    <strong>How long have you been a real estate agent or broker?</strong>
                                    <br />
                                    <strong>What connections do you have?</strong>
                                    <br />
                                    <strong>What do you do special for clients? (do you stage homes? For buyers, do you spend time with them to get
          their dynamic to to find a house for them and will offer work to get their dream home?)</strong>
                                    <br />
                                    <strong>Do you do anything extra to help your clients to make an educated decision on such a large
          investment?</strong>
                                    <br />
                                    <strong>What type of Real Estate agent are you? Property? Commercial? Broker? Etc.</strong>
                                    <br /><br />
                                </RenderWrapper>

                                <RenderWrapper isVisable={(state2?.isMLMCode)}>
                                    <br />
                This is a custom site. We want the site to feel as if you are talking to your visitors and the flow is natural
                to how you want to present your brand so you can either write a paragraph of content for each page you paid for
                and a bio.
                                </RenderWrapper>

                            </Message>
                            <Grid columns='2' id='PagesAndContentList'>
                                {(state2?.pages as [any])?.map((page, index) => <Grid.Column>
                                    {/* TODO: Get actuall height measurement from other components / CSS Designs  */}
                                    <Segment style={{ height: '40rem' }} id='PageSegment'>
                                        <Header textAlign='left' dividing content={`Page ${index + 1}`} as='h3' />
                                        <Form.Field>
                                            <RenderWrapper isVisable={(state2?.isRealEstateCode)}>
                                                <label>What services would you like to focus on? Do you want to highlight any areas? What introductory
                                                content would you like to include?

              </label>
                                            </RenderWrapper>
                                            <RenderWrapper isVisable={(state2?.isMLMCode)}>
                                                <strong>What do you do full time?</strong> (stay at home mom work for a company)
              <br />
                                                <strong>What was your mindset when you decided to open your own business? </strong>
                                                <br />
                                                <strong>What goals do you expect to accomplish with this program?</strong> (travel, pay bills, trust
                            funds, etc)
                                            </RenderWrapper>

                                            <FormFieldInput data-pageid={page?.id} name={`${page?.id}[title]`} placeholder='Page Title' label={<label style={{ textAlign: 'left' }}>Page Title: </label>} value={page?.value} onChange={onPageInputChange('title', page?.id)} />
                                            <Form.TextArea data-pageid={page?.id} name={`${page?.id}[details]`} placeholder='Page Details' value={page?.details} onChange={onPageInputChange('details', page?.id)} />
                                        </Form.Field>
                                    </Segment>
                                </Grid.Column>)}


                                <Grid.Column textAlign='left'>
                                    <Message info className='height-100' icon>
                                        <Icon name='hand point right' />
                                        <div className='content'>
                                            <Header content='Want to add MORE Pages?' />
                                            <p><a href="/Upgrades.html">Upgrade your package</a> to add more pages for your custom website, the long
    term benefits will pay for themselves!</p>
                                            <p><em>Or <a href="/contactus.html">Contact Us</a> to speak to a Customer Service Representative for more
      details/options.</em></p>
                                        </div>
                                    </Message>
                                </Grid.Column>
                            </Grid>




                            <SubmitForm header={`Confirm ${state2?.numberOfPages} ${(state2?.isRealEstateCode) ? 'Real Esate' : ''} Website Questionnaire Form`} displayNote={false} disabled={state2?.formDisabled}>
                                <>
                                    <Grid.Column width='7'>
                                        <Form.Group widths='equal'>
                                            <FormFieldInput
                                                required
                                                label={
                                                    <label><span className="" data-tooltip="Please enter your First and Last name.">
                                                        <i className="question circle blue outline icon"></i>
                                                    </span>Signature: <span className="color-red-regular">*</span></label>
                                                }
                                                placeholder='Signature'
                                                warningLabelProps={{ content: 'Please type signature' }}
                                                name='signature'

                                                value={state2?.signature}
                                                onChange={onChange}
                                            // error={true}

                                            />
                                            <Form.Field error={!state2?.date}>
                                                <label><span className="" data-tooltip="Please enter a date.">
                                                    <i className="question circle blue outline icon"></i>
                                                </span>Date: <span className="color-red-regular">*</span></label>
                                                <DatePicker
                                                    selected={state2?.date}
                                                    onChange={onDateChange}
                                                    showTimeSelect
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    name='date'
                                                    placeholder='Date'
                                                // inline
                                                // timeIntervals={15}
                                                // timeCaption="time"
                                                />
                                                {(!state2?.date) ? <Label pointing color='red' basic content="Please select a date." /> : ''}
                                            </Form.Field>


                                        </Form.Group>
                                    </Grid.Column>
                                    <Grid.Column width='11' className='center-fix-check'>
                                        <Form.Checkbox
                                            label={<label><strong><span className="color-red-regular">*</span> I approve the contented provided above, and authorize CSS to proceeed with my Custom Website build.</strong></label>}
                                            name='authorize'
                                            data-testid='authorize'
                                            value='yes'
                                            checked={state2?.authorize}
                                            onClick={onCheckboxClick()}
                                        />
                                        {(!state2?.authorize) ? <Label pointing color='red' basic content="Please review your changes and 'Check' to Submit the Real Estate Website Questionnaire Form." /> : ''}
                                    </Grid.Column>
                                </>
                            </SubmitForm>
                        </>
                    </Form>
                </RenderWrapper>
            </>}
        </WebsiteQuestionnaireContainer >
    )
};

export default WebsiteQuestionnaire;
