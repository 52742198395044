export const rootURL =
  process.env.REACT_APP_ENV === "production"
    ? "https://users.clientsitesupport.com:8090"
    : process.env.REACT_APP_ENV === "development"
    ? "https://cssdev.wms2006.com:8090"
    : "";
export const espoURL =
  process.env.REACT_APP_ENV === "production"
    ? "https://espo.wms2006.com/api/v1"
    : "https://espodev.wms2006.com/api/v1";
