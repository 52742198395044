import React from 'react';
import { Grid, Message, Header } from 'semantic-ui-react';

const ReferralIncentives = () => {
    return (
        <>
            <Grid.Column width={8}>
                <Message>
                    <Message.Header>Referral Incentives</Message.Header>
                    <br />Referrals are the basis of all positive business
                  relationships, and Client Site Support knows just how important that is.
              <br /><br />
                    Effective November 1, 2018, when you send a referral, if they purchase any SEO package with Client Site
              Support, you will receive a <strong>$100 bonus per referral</strong> who joins.
              <br /><br />
                    When you <strong>refer 5 people who join</strong>, you will <strong>receive six months at no cost</strong>
                    to you.
              <br /><br />
                    There is no signing up, and when they purchase, you will see their information listed on this page as a
                    referral for you. When they refer someone, you get a $25 bonus.
              <br /><br />
                    To refer someone today, add their information in the form on this page.
            </Message>
            </Grid.Column>
        </>
    );
}

export default ReferralIncentives;