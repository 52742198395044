import React from 'react';
import { Grid, Form } from 'semantic-ui-react';

// Components
import ReferralIncentives from './ReferralIncentives/ReferralIncentives';
import ReferralInformationForm from './ReferralInformationForm/ReferralInformationForm';

// Container
import ReferralContainer from './Container/ReferralContainer';

// Reusable Components
import ContactHeaderBar from '../ReusableComponents/ContactHeaderBar/ContactHeaderBar';
import FormSegment from '../ReusableComponents/FormSegment/FormSegment';

const Referral = (props) => {

    return (
        <ReferralContainer>
            {
                (state) =>
                    <>
                        <ContactHeaderBar Header='Referral Program' />
                        <FormSegment Header='Referral Information'>
                            <Form>
                                <Grid columns={3}>
                                    <ReferralIncentives />
                                    <ReferralInformationForm {...state} />
                                </Grid>
                            </Form>
                        </FormSegment>
                    </>
            }
        </ReferralContainer>
    );
};

export default Referral;
