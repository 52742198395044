import React from 'react';
import { IBusinessMarketingReviewState } from '../Interfaces/Interfaces';
export const _initialState: IBusinessMarketingReviewState = {
    customerServiceRepName: '',
    name: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    allContactInfoDisplayed: undefined,
    notDisplayedPhoneNumber: false,
    notDisplayedEmail: false,
    notDisplayedName: false,
    facebook: '',
    instagram: '',
    twiiter: '',
    youtube: '',
    linkedIn: '',
    blog: '',
    skype: '',
    clientWebsite: '',
    originalWebsite: '',
    additionalWebsites: undefined,
    additionalWebsitesList: '',
    urlRequested: undefined,
    urlRequestedSuggestions: '',
    inBusiness: '',
    businessIndustry: '',
    highIncomeJobs: false,
    secondIncome: false,
    lowCostOpportunity: false,
    businessStrategy: false,
    businessForSale: false,
    healthProducts: false,
    turnkey: false,
    job: false,
    highIncomeOpportunity: false,
    selfEmployed: false,
    workFromHomeJobs: false,
    fifteyThousandPerYear: false,
    twentyFiveThouseandPerYear: false,
    fifteenThousandPerYear: false,
    tenThousandPerYear: false,
    noneOrNoAnswer: false,
    areasOfIntrest: [],
    cashGifting: undefined,
    opportunityAndProducts: undefined,
    productsOnly: undefined,
    businessOnly: undefined,
    keyterms: '',
    bestSellingProducts: '',
    salesContentQuestion: '',
    citiesStatesAndCountries: '',
    specialNotes: '',
    keywordApprovalTime: '',
    disableForm: false,
};

