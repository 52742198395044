import React, { useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {  Menu, Segment, Dropdown, Button } from "semantic-ui-react";
import Image from "./Image";
import UserContext from "../context/UserContext/Context/context";

const Navigation = () => {
  const { user, logoutUser }: any = useContext(UserContext);
    
    return (
      <>
        <div className="container with-color">
          <Segment secondary className="column fix-column-header">
            <Menu borderless stackable>
              <Menu.Item position="left">
                <div className="logo-fix-header">
                  <NavLink to={{ pathname: user ? '/account' : '/login'  }}>
                    <Image path="../images/logo-top.jpg" />
                  </NavLink>
                </div>
              </Menu.Item>

              <div className="ui menu no-border-bot">
                {
                  (user)
                    ?
                    <>
                      <Menu.Item position="left" onClick={logoutUser}>
                        <Button color='red'>
                          Logout
                        </Button>
                      </Menu.Item>
                      <Menu.Item position="right">
                        <NavLink
                          to={{ pathname: "/Account" }}
                        //   classNameName="item"
                        >
                          Account
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item position="right">
                        <NavLink
                          to={{ pathname: "/Revisions" }}
                        //   classNameName="item"
                        >
                          Revisions
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item position="right">
                        <NavLink
                          to={{ pathname: "/upgrades" }}
                        //   classNameName="item"
                        >
                          Upgrades
                  </NavLink>
                      </Menu.Item>
                      <Menu.Item>
                        <Dropdown text='Marketing Review'>
                          <Dropdown.Menu>
                            <NavLink to={{ pathname: '/businessmarketingreview' }}>
                              <Dropdown.Item>Business Marketing Review</Dropdown.Item>
                            </NavLink>
                            <NavLink to={{ pathname: '/realestatemarketingreview' }}>
                              <Dropdown.Item>Real Estate Marketing Review</Dropdown.Item>
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Menu.Item>

                      <Menu.Item position="right">
                        <NavLink
                          to={{ pathname: "/keywordapproval" }}
                        //   classNameName="item"
                        >
                          Keyword Approval
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item position="right">
                        <NavLink
                          to={{ pathname: "/recurring" }}
                        //   classNameName="item"
                        >
                          Recurring
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item position="right">
                        <NavLink
                          to={{ pathname: "/websitequestionnaire" }}
                        //   classNameName="item"
                        >
                          Website Questionnaire
                        </NavLink>
                      </Menu.Item>
                    </>
                    : ''
                }
              </div>
              <div>

              </div>
            </Menu>
            <div className="header-menu-section"></div>
          </Segment>
        </div>
        <div className="column fix-column-header"></div>
      </>
    );  
};

export default withRouter(Navigation);
