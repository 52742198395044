
import { IWebsiteQuestionnaireState } from '../Interfaces/Interfaces';
export const _initialState: IWebsiteQuestionnaireState = {
    numberOfPages: 0,
    isWebsiteQuestionnaireCode: false,
    isMLMCode: false,
    isRealEstateCode: false, formCode: '',
    customerServiceRepName: '',
    name: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    referredTo: '',
    referredAs_broker: false,
    referredAs_realEstateAgent: false,
    referredAs_realtor: false,
    referredAs_other: false,
    referredAs_otherInput: '',

    ownDomain: undefined,
    domain: '',
    suggestedDomains: '',
    dnsInformation: undefined,
    parentCompany: '',
    websiteDomain: '',
    username: '',
    password: '',
    siteTitle: '',
    haveLogo: undefined,
    logoDescription: '',
    selectedCustomDesignFile: null,
    customDesignFiles: [],
    customDesignPercentage_uploading: 0,
    customDesignPercentage_requirements: 0,
    customDesignPercentage_error: 0,
    customDesignPercentage_successful: 0,
    backgroundColor: '',
    letterColor: '',
    highlightColor: '',
    sitePurpose: '',
    additionalLinks: '',
    selectedfileUploadFile: '',
    fileUploadFiles: [],
    fileUpload_uploading: 0,
    fileUpload_requirements: 0,
    fileUpload_error: 0,
    fileUpload_successful: 0,
    purposeOfFiles: '',
    idxInformation: '',
    connections: '',
    special: '',
    timeAsAgent: '',
    helpClients: '',
    typeOfAgent: '',
    pages: [],
    signature: '',
    authorize: false,
    date: '',

    formDisabled: false,
};