import React, { useReducer, useEffect, useState, useContext } from "react";

//axios
import axios from "axios";

// reducer
import { rootURL } from "../../../config/config";

import { toast } from "react-toastify";

import { useAfterFormSubmit } from "../../../customHooks/afterFormSubmit";

// Context
import UserContext from "../../../context/UserContext/Context/context";

import SimpleReactValidator from "simple-react-validator";
const simpleValidator = new SimpleReactValidator();

const RecurringPaymentsContainer = (props) => {
  // Context
  const { user } = useContext(UserContext) || {};

  const [afterFormSubmit] = useAfterFormSubmit();

  const [formSubmitted, setFormSubmitted] = useState(false);

  // Refactored State
  const [state2, setState] = useState({
    paymentMethod: "",
    billingDate: "",
    monthsAgreedTo: "",
    promoCode: "",
    cardNumber: "",
    cardExpireMonth: "",
    cardExpireYear: "",
    cvv: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    apt: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    email: "",
    specialInstructions: "",
    hostingFee: "$59.95",
    maintenanceFee: "$10.00",
    signature: "",
    agreeToTerms: false,
  });

  // Reset React Validator
  useEffect(() => {
    simpleValidator.hideMessages();
    simpleValidator.purgeFields();
    forceUpdate();
  }, []);

  useEffect(() => {
    handleFeesUpdate(state2.promoCode);
  }, [state2.promoCode]);

  const validateForm = () => {
    // all state values that do not need to be validated are destructured here
    const { apt, specialInstructions, promoCode, ...rest } = state2;
    if (!Object.keys(rest).every((key) => rest[key])) {
      throw new Error("Finish entering form data!");
    }
  };
  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  };
  const forceUpdate = useForceUpdate();

  const onSubmit = () => {
    try {
      if (simpleValidator.allValid()) {
        simpleValidator.hideMessages();
        simpleValidator.purgeFields();
        forceUpdate();
        if (!formSubmitted) {
          validateForm();
          setFormSubmitted(true);

          axios
            .post(
              `${rootURL}/recurring/createRecurringEmail`,
              {
                ...state2,
                clientId: user?.accountId,
              },
              {
                headers: {
                  "x-token": `${localStorage.getItem("token")}`,
                  "x-refresh-token": `${localStorage.getItem("refreshToken")}`,
                },
              }
            )
            .then((results) => {
              if (results.status === 200) return afterFormSubmit();
              else throw new Error(results.data?.message);
            })
            .catch((error) => {
              setFormSubmitted(false);
              toast.error(error.response.data.message);
              console.warn("Error: ", error.response.data.message);
            });
        }
      } else {
        simpleValidator.showMessages();
        forceUpdate();
      }
    } catch (error) {
      toast.error(error.message);
      console.warn("Error: ", error);
    }
  };

  const handleDateChange = (date) =>
    setState((prevState) => Object.assign({}, { ...prevState, date }));

  // Refactor this for less code
  const handleFeesUpdate = (promoCode) => {
    if (promoCode.trim().toLowerCase() === "promo1") {
      setState((prevState) =>
        Object.assign({}, { ...prevState, hostingFee: "$9.95" })
      );
    } else if (promoCode.trim().toLowerCase() === "custom") {
      setState((prevState) =>
        Object.assign({}, { ...prevState, hostingFee: "$19.95" })
      );
    } else
      setState((prevState) =>
        Object.assign({}, { ...prevState, hostingFee: "$59.95" })
      );
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target || {};
    setState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleDropdownOnChange = (_, data) => {
    const { options, value, name } = data || {};
    setState((prevState) =>
      Object.assign(
        {},
        {
          ...prevState,
          [name]: options?.find(
            ({ value: option_value }) => value === option_value
          ),
        }
      )
    );
  };

  const handleCheckboxOnClick = (_, { name, checked }) => {
    setState((prevState) =>
      Object.assign({}, { ...prevState, agreeToTerms: checked })
    );
  };

  return (
    <>
      {props.children({
        onSubmit,
        handleDateChange,
        handleOnChange,
        handleCheckboxOnClick,
        handleDropdownOnChange,
        state2,
        forceUpdate,
        simpleValidator,
        formSubmitted,
      })}
    </>
  );
};

export default RecurringPaymentsContainer;
