import React, { useReducer } from 'react';
import { Form, Header, Message, Grid, Segment, Button } from 'semantic-ui-react';


// DatePicker
import DatePicker from 'react-datepicker';
// Components
import BusinessMarketingReviewNote from './Note/BusinessMarketingReviewNote';

// Container
import BusinessMarketingReviewContainer from './Container/BusinessMarketingReviewContainer';

// Reusable Components
import ContactHeaderBar from '../ReusableComponents/ContactHeaderBar/ContactHeaderBar';
import FormHeader from '../ReusableComponents/FormHeader/FormHeader';

// Interfaces
import DoubleFormSegment from '../ReusableComponents/DoubleFormSegment/DoubleFormSegment';
import FormFieldInput from '../ReusableComponents/FormFieldInput/FormFieldInput';
import { IBusinessMarketingReviewState } from './Interfaces/Interfaces';
import WarningLabel from '../ReusableComponents/WarningLabel/WarningLabel';
import MakeSelectionLabel from '../ReusableComponents/MakeSelectionLabel/MakeSelectionLabel';
import FormTextArea from '../ReusableComponents/FormTextArea/FormTextArea';
import FormSegmentSingleHeader from '../ReusableComponents/FormSegment/FormSegmentSingleHeader/FormSegmentSingleHeader';
import FormFieldCheckbox from '../ReusableComponents/FormFieldCheckbox/FormFieldCheckbox';

const BusinessMarketingReview = (props) => {

    return (
        <BusinessMarketingReviewContainer>
            {
                ({ handleDateOnChange, handleOnChange, handleCheckboxOnClick, isAreaOfIntrestChecked, handleOnSubmit, state2, ...state }: { state2: IBusinessMarketingReviewState, handleDateOnChange: any, handleOnChange: any, handleCheckboxOnClick: any, isAreaOfIntrestChecked: any, handleOnSubmit: any, }) => {
                    return <>
                        <ContactHeaderBar Header='Business Marketing Review Form' handleContactButtonClick={() => true} />
                        <FormHeader
                            header='Business Marketing Review Form'
                            message='The purpose of your marketing review is to help us make sure that we know a little more about you and your business. We will be asking you a series of questions which is very helpful to us in every step of the process with researching key term topics and the custom content writing.'
                        />
                        <Form id='BusinessMarketingReviewForm' onSubmit={handleOnSubmit}>

                            {/* Contact Information */}
                            <DoubleFormSegment
                                leftColumn={
                                    <>
                                        <Header dividing content={'Contact Information'} textAlign="left" as='h2' />

                                        <Form.Group style={{ textAlign: 'left' }} widths='2'>
                                            <FormFieldInput
                                                required={true}
                                                label={<label>Customer Service Rep Name: </label>}
                                                name='customerServiceRepName'
                                                placeholder='Customer Service Rep Name'
                                                warningLabelProps={{
                                                    content: "Please enter 'CS Rep. Name'",
                                                }}
                                                onChange={handleOnChange}
                                                value={state2?.customerServiceRepName}
                                            />

                                            <FormFieldInput
                                                required
                                                label={<label>Name: </label>}
                                                name='name'
                                                placeholder='Name'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Name'.",
                                                }}
                                                onChange={handleOnChange}
                                                value={state2?.name}
                                            />
                                        </Form.Group>

                                        <FormFieldInput
                                            required
                                            label={<label>Email:</label>}
                                            name='email'
                                            placeholder='Email'
                                            warningLabelProps={{
                                                content: "Please enter a 'Email '.",
                                            }}
                                            onChange={handleOnChange}
                                            value={state2?.email}
                                        />
                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                type='number'
                                                required
                                                label={<label>Phone (to be used on site): </label>}
                                                name='phone'
                                                placeholder='Phone'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Phone number'.",
                                                }}
                                                onChange={handleOnChange}
                                                value={state2?.phone}
                                            />
                                            <FormFieldInput
                                                type='number'
                                                label={<label>Secondary Phone (to be used on site): </label>}
                                                name='secondaryPhone'
                                                placeholder='Secondary Phone'
                                                warningLabelProps={{
                                                    content: "Please enter a 'Secondary Phone number'.",
                                                }}
                                                onChange={handleOnChange}
                                                value={state2?.secondaryPhone}
                                            />
                                        </Form.Group>
                                        <Form.Group className={''} grouped style={{ textAlign: 'left' }}>
                                            <label>Do you want all contact info displayed?</label>
                                            <Form.Checkbox radio label='Yes' value='Yes' data-testid='AllContactInfoDisplayed_YesCheckbox' name='allContactInfoDisplayed' onClick={handleCheckboxOnClick(true)} checked={state2?.allContactInfoDisplayed} />
                                            <Form.Checkbox radio label='No' value='No' data-testid='AllContactInfoDisplayed_NoCheckbox' name='allContactInfoDisplayed' onClick={handleCheckboxOnClick(false)} checked={(state2?.allContactInfoDisplayed === false)} />

                                        </Form.Group>
                                        <Form.Group className={''} grouped style={{ textAlign: 'left' }}>
                                            <label>Which do you NOT want displayed?:</label>
                                            <Form.Checkbox radio label='Phone Number' name='notDisplayedPhoneNumber' data-testid='PhoneNumberNotDisplayedCheckbox' onClick={handleCheckboxOnClick()} value='notDisplayedPhoneNumber' checked={state2?.notDisplayedPhoneNumber} />
                                            <Form.Checkbox radio label='Email' name='notDisplayedEmail' data-testid='EmailNotDisplayedCheckbox' onClick={handleCheckboxOnClick()} value='notDisplayedEmail' checked={state2?.notDisplayedEmail} />
                                            <Form.Checkbox radio label='Name' name='notDisplayedName' data-testid='NameNotDisplayedCheckbox' onClick={handleCheckboxOnClick()} value='notDisplayedName' checked={state2?.notDisplayedName} />
                                        </Form.Group>
                                    </>
                                }
                                rightColumn={<>
                                    <Grid.Column style={{ textAlign: 'left' }}>
                                        <Header dividing content={'Social Media Information'} as='h2' />
                                        <Message>
                                            <strong>Are you registered with any of the following?</strong> If so, please list
              your existing link.
            </Message>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>Facebook</label>}
                                                name='facebook'
                                                placeholder='Facebook Link'
                                                onChange={handleOnChange}
                                                error={false}
                                            />
                                            <FormFieldInput
                                                label={<label>Instagram</label>}
                                                name='instagram'
                                                placeholder='Instagram Link'
                                                onChange={handleOnChange}
                                                error={false}
                                            />
                                        </Form.Group>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>Twitter: </label>}
                                                name='twitter'
                                                placeholder='Twitter Link'
                                                onChange={handleOnChange}
                                                error={false}
                                            />
                                            <FormFieldInput
                                                label={<label>YouTube: </label>}
                                                name='youTube'
                                                placeholder='YouTube Link'
                                                onChange={handleOnChange}
                                                error={false}
                                            />
                                        </Form.Group>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>LinkedIn: </label>}
                                                name='linkedIn'
                                                placeholder='LinkedIn Link'
                                                onChange={handleOnChange}
                                                error={false}
                                            />
                                            <FormFieldInput
                                                label={<label>Third-party Blog: </label>}
                                                name='blog'
                                                placeholder='Blog Link'
                                                onChange={handleOnChange}
                                                error={false} />
                                        </Form.Group>

                                        <Form.Group widths='2'>
                                            <FormFieldInput
                                                label={<label>Skype: </label>}
                                                name='skype'
                                                placeholder='Skype Link'
                                            //    onChange={handleOnChange}
                                            />
                                        </Form.Group>

                                    </Grid.Column></>}

                            />
















                            {/* Website Information */}


                            <Segment textAlign='left' id='WebsiteInformation'>
                                {/* TODO: Create reusable component, possibly for grid with segment and header */}
                                <Grid>
                                    <Grid.Column className='margin-padding-fix'>
                                        <Header dividing content='Website Information' as='h2' />
                                    </Grid.Column>
                                </Grid>
                                <Grid columns={2}>

                                    <Grid.Column>
                                        <FormFieldInput
                                            required
                                            name='clientWebsite'
                                            label={<label>Client Website: </label>}
                                            placeholder='Client Website'
                                            warningLabelProps={{ content: "Please enter 'Client Website'." }}
                                            onChange={handleOnChange}
                                            value={state2?.clientWebsite}
                                        />

                                        <FormFieldInput
                                            required
                                            name='originalWebsite'
                                            label={<label>How long have you owned your original website?
                        <span className="color-red-regular">*</span></label>}
                                            placeholder='How long have you owned your original website?'
                                            warningLabelProps={{
                                                className: 'center-fix',
                                                content: 'Please add some details.'
                                            }}
                                            onChange={handleOnChange}
                                            value={state2?.originalWebsite}
                                        />
                                        <Form.Group error={''} grouped>
                                            <label>Do you have additional websites relevant to this business?<span className="color-red-regular">*</span></label>
                                            <Form.Checkbox name='additionalWebsites' data-testid='additionalWebsitesYesCheckbox' onClick={handleCheckboxOnClick(true)} checked={state2?.additionalWebsites} label='Yes' value='Yes' radio />
                                            <Form.Checkbox name='additionalWebsites' data-testid='additionalWebsitesNoCheckbox' onClick={handleCheckboxOnClick(false)} checked={(state2?.additionalWebsites === false)} label='No' value='No' radio />
                                            <MakeSelectionLabel error={state2?.additionalWebsites === undefined} />
                                        </Form.Group>
                                        <FormTextArea
                                            label={<label>Please list them here: </label>}
                                            name='additionalWebsitesList'
                                            placeholder='Additional websites'
                                            rows={8}
                                            onChange={handleOnChange}
                                            value={state2?.additionalWebsitesList}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>

                                        <Form.Group grouped>
                                            <label>URL Requested?<span className="color-red-regular">*</span></label>
                                            <Form.Checkbox name='urlRequested' data-testid='urlRequestedYesCheckbox' onClick={handleCheckboxOnClick(true)} checked={state2?.urlRequested} label='Yes' value='Yes' radio />
                                            <Form.Checkbox name='urlRequested' data-testid='urlRequestedNoCheckbox' onClick={handleCheckboxOnClick(false)} checked={(state2?.urlRequested === false)} label='No' value='No' radio />
                                            <MakeSelectionLabel error={state2?.urlRequested === undefined} />
                                        </Form.Group>


                                        <Message>
                                            <strong>What URL you're interested in or a general idea of what it could be.</strong>
                                            <br />
                                            <em> (ex. "something that contains Texas" or "my current domain name but with .net")</em>
                                            <br /><br />
                        We will try to find availability from the top of your list downwards.
                    </Message>
                                        <FormTextArea
                                            name='urlRequestedSuggestions'
                                            label={<label>Please enter your suggestions here: </label>}
                                            placeholder='Custom URL'
                                            rows={8}
                                            onChange={handleOnChange}
                                            value={state2?.urlRequestedSuggestions}
                                        />
                                    </Grid.Column>

                                </Grid>
                            </Segment>

                            {/* ================================ (START) CUSTOM CONTENT QUESTIONNAIRE ========================================================== */}

                            <FormSegmentSingleHeader
                                id='CustomContentQuestionnaire'
                                header={
                                    <>
                                        <Header content={'Custom Content Questionnaire'} dividing as='h2' />
                                        <Message>
                                            <strong>List products or services.</strong> Custom content will be written on the following topics:</Message>
                                    </>}>
                                <>
                                    <Grid columns='2'>
                                        <Grid.Column>
                                            <FormFieldInput required warningLabelProps={{ content: "Please add some detials." }} label={<label>How long have you been in business?</label>} placeholder='How long have you been in business?' name='inBusiness' onChange={handleOnChange} value={state2?.inBusiness} />
                                            <FormFieldInput required warningLabelProps={{ content: "Please add some details." }} label={<label>Business Industry</label>} placeholder='Business Industry' name='businessIndustry' onChange={handleOnChange} value={state2?.businessIndustry} />

                                            <Form.Group id='SelectAreasOfIntrest'>
                                                <Grid columns={3}>
                                                    <Grid.Column className='padding-fix'>
                                                        <strong>Select each area of interest: <span className="color-red-regular">*</span></strong>
                                                        <FormFieldCheckbox radio={false} checked={state2?.highIncomeJobs} onClick={handleCheckboxOnClick()} name='highIncomeJobs' data-testid='highIncomeJobs' label='High Income Jobs' value='High Income Jobs' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.secondIncome} onClick={handleCheckboxOnClick()} name='secondIncome' data-testid='secondIncome' label='Second Income' value='Second Income' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.lowCostOpportunity} onClick={handleCheckboxOnClick()} name='lowCostOpportunity' data-testid='lowCostOpportunity' label='Low Cost Opportunity' value='Low Cost Opportunity' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.businessStrategy} onClick={handleCheckboxOnClick()} name='businessStrategy' data-testid='businessStrategy' label='Business Strategy' value='Business Strategy' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.businessForSale} onClick={handleCheckboxOnClick()} name='businessForSale' data-testid='businessForSale' label='Business For Sale' value='Business For Sale' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.healthProducts} onClick={handleCheckboxOnClick()} name='healthProducts' data-testid='healthProducts' label='Health Products' value='Health Products' />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <FormFieldCheckbox radio={false} checked={state2?.turnkey} onClick={handleCheckboxOnClick()} name='turnkey' data-testid='turnkey' label='Turnkey' value='Turnkey' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.job} onClick={handleCheckboxOnClick()} name='job' data-testid='job' label='Job' value='Job' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.highIncomeOpportunity} onClick={handleCheckboxOnClick()} name='highIncomeOpportunity' data-testid='highIncomeOpportunity' label='High Income Opportunity' value='High Income Opportunity' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.selfEmployed} onClick={handleCheckboxOnClick()} name='selfEmployed' data-testid='selfEmployed' label='Self Employed' value='Self Employed' />
                                                        <FormFieldCheckbox radio={false} checked={state2?.workFromHomeJobs} onClick={handleCheckboxOnClick()} name='workFromHomeJobs' data-testid='workFromHomeJobs' label='Work From Home Jobs' value='Work From Home Jobs' />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <FormFieldCheckbox radio={false} onClick={handleCheckboxOnClick()} checked={state2?.fifteyThousandPerYear} name='fifteyThousandPerYear' data-testid='fifteyThousandPerYear' label='Fiftey Thousand Per Year' value='Fiftey Thousand Per Year' />
                                                        <FormFieldCheckbox radio={false} onClick={handleCheckboxOnClick()} checked={state2?.twentyFiveThouseandPerYear} name='twentyFiveThouseandPerYear' data-testid='twentyFiveThouseandPerYear' label='Twenty Five Thousand Per Year' value='Twenty Five Thousand Per Year' />
                                                        <FormFieldCheckbox radio={false} onClick={handleCheckboxOnClick()} checked={state2?.fifteenThousandPerYear} name='fifteenThousandPerYear' data-testid='fifteenThousandPerYear' label='Fifteen Thousand Per Year' value='Fifteen Thousand Per Year' />
                                                        <FormFieldCheckbox radio={false} onClick={handleCheckboxOnClick()} checked={state2?.tenThousandPerYear} name='tenThousandPerYear' data-testid='tenThousandPerYear' label='Ten Thousand Per Year' value='Ten Thousand Per Year' />
                                                        <FormFieldCheckbox radio={false} onClick={handleCheckboxOnClick()} checked={state2?.noneOrNoAnswer} name='noneOrNoAnswer' data-testid='noneOrNoAnswer' label='None Or No Answer' value='None Or No Answer' />
                                                    </Grid.Column>
                                                </Grid>
                                            </Form.Group>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Form.Group grouped>
                                                <label>Is your business a cash gifting?<span className="color-red-regular">*</span></label>
                                                <Form.Checkbox name='cashGifting' data-testid='cashGiftingYesCheckbox' onClick={handleCheckboxOnClick(true)} checked={state2?.cashGifting} label='Yes' value='Yes' radio />
                                                <Form.Checkbox name='cashGifting' data-testid='cashGiftingNoCheckbox' onClick={handleCheckboxOnClick(false)} checked={(state2?.cashGifting === false)} label='No' value='No' radio />
                                                <MakeSelectionLabel error={state2?.cashGifting === undefined} />
                                            </Form.Group>
                                            <Form.Group grouped>
                                                <label>Are you promoting both opportunity and products?<span className="color-red-regular">*</span></label>
                                                <Form.Checkbox name='opportunityAndProducts' data-testid='opportunityAndProductsYesCheckbox' onClick={handleCheckboxOnClick(true)} checked={state2?.opportunityAndProducts} label='Yes' value='Yes' radio />
                                                <Form.Checkbox name='opportunityAndProducts' data-testid='opportunityAndProductsNoCheckbox' onClick={handleCheckboxOnClick(false)} checked={(state2?.opportunityAndProducts === false)} label='No' value='No' radio />
                                                <MakeSelectionLabel error={state2?.opportunityAndProducts === undefined} />
                                            </Form.Group>
                                            <Form.Group grouped>
                                                <label>Are you promoting your products only?<span className="color-red-regular">*</span></label>
                                                <Form.Checkbox name='productsOnly' data-testid='productsOnlyYesCheckbox' onClick={handleCheckboxOnClick(true)} checked={state2?.productsOnly} label='Yes' value='Yes' radio />
                                                <Form.Checkbox name='productsOnly' data-testid='productsOnlyNoCheckbox' onClick={handleCheckboxOnClick(false)} checked={(state2?.productsOnly === false)} label='No' value='No' radio />
                                                <MakeSelectionLabel error={state2?.productsOnly === undefined} />
                                            </Form.Group>
                                            <Form.Group grouped>
                                                <label>Are you promoting business opportunity only?<span className="color-red-regular">*</span></label>
                                                <Form.Checkbox name='businessOnly' data-testid='businessOnlyYesCheckbox' onClick={handleCheckboxOnClick(true)} checked={state2?.businessOnly} label='Yes' value='Yes' radio />
                                                <Form.Checkbox name='businessOnly' data-testid='businessOnlyNoCheckbox' onClick={handleCheckboxOnClick(false)} checked={(state2?.businessOnly === false)} label='No' value='No' radio />
                                                <MakeSelectionLabel error={state2?.businessOnly === undefined} />
                                            </Form.Group>
                                            <Form.Field required>
                                                <label>Keyterms?</label>
                                                <Form.TextArea rows='6' onChange={handleOnChange} name='keyterms' value={state2?.keyterms} placeholder='Keyterms' required />
                                                <WarningLabel visable={!state2?.keyterms} />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>

                                    <Grid columns={2}>
                                        <Grid.Column>
                                            <Form.Field required>
                                                <>
                                                    <label>What are my best selling products that I offer clients? And its best used for? <span
                                                        className="color-red-regular">*</span>
                                                    </label><em>(Example: Collagen cream -- used for antiaging..)</em>
                                                </>
                                                <Form.TextArea rows='6' onChange={handleOnChange} name='bestSellingProducts' value={state2?.bestSellingProducts} placeholder='What are my best selling products?' required />
                                                <WarningLabel visable={!state2?.bestSellingProducts} />
                                            </Form.Field>
                                            <Form.Field required>
                                                <>
                                                    <label>Sales Content Question:
                <span className="color-red-regular">*</span>
                                                    </label><em>(If you had 2 minutes to tell someone about you and/or your opportunity what would you
                say?)</em>
                                                </>
                                                <Form.TextArea rows='6' onChange={handleOnChange} name='salesContentQuestion' value={state2?.salesContentQuestion}  placeholder='Sales Content Question' required />
                                                <WarningLabel visable={!state2?.salesContentQuestion} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Form.Field required>
                                                <>
                                                    <label>Cities, states, and countries you wish to market: <span className="color-red-regular">*</span>
                                                    </label><em>(Please include city and state or zip codes if you have a particular area to market.)</em>
                                                </>
                                                <Form.TextArea rows='6' onChange={handleOnChange} name='citiesStatesAndCountries' value={state2?.citiesStatesAndCountries}  placeholder='Cities States And Countries' required />
                                                <WarningLabel visable={!state2?.citiesStatesAndCountries} />
                                            </Form.Field>
                                            <Form.Field required>
                                                <>
                                                    <label>Special Notes For Content:</label><em>(Please add any instructions for the content writer in this section)</em>
                                                </>
                                                <Form.TextArea rows='6' onChange={handleOnChange} name='specialNotes' value={state2?.specialNotes}  placeholder='Special Notes' required />
                                                <WarningLabel visable={!state2?.specialNotes} />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </>
                            </FormSegmentSingleHeader>
                            {/* ================================ (END) CUSTOM CONTENT QUESTIONNAIRE ========================================================== */}

                            {/* ================================ (START) BUSINESS MARKETING REVIEW NOTE ========================================================== */}
                            <BusinessMarketingReviewNote />
                            {/* ================================ (END) BUSINESS MARKETING REVIEW NOTE ========================================================== */}

                            <Segment>
                                <Grid textAlign='center'>
                                    <Grid.Row>
                                        <Header dividing className='center-fix' content={'Submit Business Marketing Review Form'} as='h2' />
                                    </Grid.Row>
                                    <Grid.Row>
                                        {/* TODO: Setting <Message> component prop warning makes the component invisable  */}

                                        <Grid.Column width='7'>
                                            <Message color='yellow'>
                                                <Message.Header>NOTE FOR CUSTOMER SERVICE REP:</Message.Header>
                                                <p>Once complete, print out the email you get from submitting this form.</p>
                                            </Message>
                                        </Grid.Column>


                                    </Grid.Row>
                                    {


                                        <Grid.Column width='4'>
                                            <Form.Field error={!state2?.keywordApprovalTime}>
                                                <label>Keyword Approval Appointment Time: <span className="color-red-regular">*</span> </label>
                                                <DatePicker
                                                    selected={state2?.keywordApprovalTime}
                                                    onChange={handleDateOnChange}
                                                    showTimeSelect
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    name='date'
                                                // inline
                                                // timeIntervals={15}
                                                // timeCaption="time"
                                                />
                                                <WarningLabel visable={!state2?.keywordApprovalTime} content='Please select a time.' />
                                            </Form.Field>
                                        </Grid.Column>
                                    }
                                    <Grid.Row>
                                        <Button disabled={state2?.disableForm} color='green' tabindex='0' content='Submit Form' />
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form>
                    </>
                }
            }
        </BusinessMarketingReviewContainer >
    );
};

export default BusinessMarketingReview;
