// Packages
import React from "react";
import { Image } from "semantic-ui-react";


// All Images
import logo from "../images/logo-top.jpg";
import background from "../images/bg.jpg";
import brilliant from "../images/brilliant.png";
// import graph from "../images/graph.JPG";
import google from "../images/google2.jpg";
import yahoo from "../images/yahoo2.jpg";
import bing from "../images/Bing-icon2.jpg";
import aol from "../images/aol2.jpg";
import swagl from "../images/swagl2.jpg";
import top from "../images/toplogo_sm2.jpg";
import lewtsy from "../images/lewtsy2.jpg";
import finditright from "../images/finditright2.jpg";
import ecosia from "../images/ecosia2.jpg";
import kajoe from "../images/kajoe2.jpg";
import avant from "../images/Avant-Browser-20172.jpg";
import izito from "../images/izito2.jpg";
import fsn from "../images/fsnsearch2.jpg";
import jooper from "../images/jooper2.jpg";
import searx from "../images/searx2.jpg";
import { SemanticSIZES } from "semantic-ui-react/dist/commonjs/generic";

type IProps = {
  path: string,
  title?: string,
  size?: SemanticSIZES,
  className?: string,
}



const Img = ({ path, size, className }: IProps) => {
  const imageLoader = () => {
    if (path === "../images/logo-top.jpg") return logo;
    if (path === "../images/bg.jpg") return background;
    if (path === "../images/brilliant.png") return brilliant;
    // if (path === "../images/graph.JPG") return graph;
    if (path === "../images/google2.jpg") return google;
    if (path === "../images/yahoo2.jpg") return yahoo;
    if (path === "../images/Bing-icon2.jpg") return bing;
    if (path === "../images/aol2.jpg") return aol;
    if (path === "../images/swagl2.jpg") return swagl;
    if (path === "../images/toplogo_sm2.jpg") return top;
    if (path === "../images/lewtsy2.jpg") return lewtsy;
    if (path === "../images/finditright2.jpg") return finditright;
    if (path === "../images/ecosia2.jpg") return ecosia;
    if (path === "../images/kajoe2.jpg") return kajoe;
    if (path === "../images/Avant-Browser-20172.jpg") return avant;
    if (path === "../images/fsnsearch2.jpg") return fsn;
    if (path === "../images/izito2.jpg") return izito;
    if (path === "../images/jooper2.jpg") return jooper;
    if (path === "../images/searx2.jpg") return searx;
  };

  return (
    <>
      <Image className={className} src={imageLoader()} size={size} />
    </>
  );
};

export default Img;
