import React from 'react';
import { Header, Form, Label, Dropdown } from 'semantic-ui-react';

// Interfaces
import { IBillingInformation } from '../../../../../Interfaces/Interfaces';
import { FormContext } from '../../../../HOC/FormHOC/context';

import { states, countries } from '../../../../../utils/dropdownOptions';

const BillingInformation: React.FunctionComponent<any> = ({ address, apt, city, state, country, zipcode }) => {

    const { handleOnChange, handleDropdownChange, billingInformaitonRef } = React.useContext<any>(FormContext);

    return (
        <div ref={billingInformaitonRef}>
            <Header content='Billing Information' />
            <Form.Group widths={2}>
                <Form.Field width={12}>
                    <Label content='Address:' className='no-background-label' />
                    <Form.Input error={!address} required name='billingInformationStreetAddress' placeholder='Street Address' value={address} onChange={handleOnChange}  />
                    {(!address) ? <Label pointing basic color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field widths={4}>
                    <Label className='no-background-label'>&nbsp;</Label>
                    <Form.Input name='billingInformationApt' placeholder='Apt #' value={apt} onChange={handleOnChange}  />
                </Form.Field>
            </Form.Group>
            <Form.Group widths={3}>
                <Form.Field>
                    <Label className='no-background-label' content='City' />
                    <Form.Input error={!city} required name='billingInformationCity' placeholder='City' value={city} onChange={handleOnChange} />
                    {(!city) ? <Label pointing basic color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field>
                    <Label content='State:' className='no-background-label' />
                    <Dropdown selection name='billingInformationState' options={states} value={state?.value} onChange={handleDropdownChange} />
                    {(!state?.value) ? <Label pointing basic color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field>
                    <Label className='no-background-label' content='Country' />
                    <Dropdown selection name='billingInformationCountry' options={countries} onChange={handleDropdownChange} value={country?.value} />
                    {(!country?.value) ? <Label pointing basic color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
                <Form.Field>
                    <Label className='no-backround-label' content='Zip Code:' />
                    <Form.Input error={!zipcode} required name='billingInformationZipCode' placeholder='Zip Code' value={zipcode} onChange={handleOnChange} />
                    {(!zipcode) ? <Label pointing basic color='red' className='center-fix no-background-label' content='Please add some details.' /> : ''}
                </Form.Field>
            </Form.Group>
        </div>
    );
};

export default BillingInformation;