import React from "react";
import { Header, Message, Form, Dropdown } from "semantic-ui-react";

// utils
import { months, cardYears } from "../../../utils/dropdownOptions";

import ErrorLabel from "../../ReusableComponents/ErrorLabel/ErrorLabel";

interface IProps {
  options?: any;
}

const CardInformation: React.FunctionComponent<any> = ({
  expirationMonthOptions,
  setCardMonth,
  cardNumber,
  cardExpireYear,
  cardExpireMonth,
  cvv,
  handleOnChange,
  handleDropdownOnChange,
  simpleValidator,
  ...props
}) => {
  const { paymentMethod, forceUpdate } = props || {};
  const amex =
    paymentMethod.text &&
    paymentMethod.text.toLowerCase() === "american express";

  return (
    <>
      <Header dividing content="Card Information" as="h2" />
      <Form.Group widths="3">
        <Form.Field width="7">
          <label>Card Number:</label>
          <Form.Input
            name="cardNumber"
            type="number"
            placeholder="Card #"
            value={cardNumber}
            onChange={handleOnChange}
            required
            onBlur={() => {              
              simpleValidator.showMessageFor("cardNumber");
              forceUpdate();
            }}
          />
          {amex
            ? simpleValidator.message(
                "cardNumber",
                cardNumber,
                "required|min:15|max:15"
              )
            : simpleValidator.message(
                "cardNumber",
                cardNumber,
                "required|min:16|max:16"
              )}
        </Form.Field>
        <Form.Field width="4">
          <label>Expiration:</label>
          <Form.Field>
            {/* TODO: Possibly make selected word 'month' the default */}
            <Dropdown
              upward={false}
              selection
              fluid
              name="cardExpireMonth"
              placeholder="Month"
              options={months}
              onChange={handleDropdownOnChange}
              value={cardExpireMonth?.value}
            />
            <ErrorLabel error={!cardExpireMonth?.value} />
          </Form.Field>
        </Form.Field>
        <Form.Field width="3">
          <label>&nbsp;</label>
          {/* TODO: Reusable component?? */}
          <Form.Field>
            {/* TODO: Possibly make selected word 'month' the default */}
            <Dropdown
              upward={false}
              selection
              fluid
              name="cardExpireYear"
              placeholder="Year"
              options={cardYears}
              onChange={handleDropdownOnChange}
              value={cardExpireYear?.value}
            />
            <ErrorLabel error={!cardExpireYear?.value} />
          </Form.Field>
        </Form.Field>
        <Form.Field width="2">
          <label>CVV:</label>
          <Form.Input
            name="cvv"
            type="number"
            placeholder="CVV"
            value={cvv}
            onChange={handleOnChange}
            required
          />
          <ErrorLabel error={!cvv} />
        </Form.Field>
      </Form.Group>
    </>
  );
};

CardInformation.defaultProps = {
  // options: [
  //     { text: 'January', value: 1 },
  //     { text: 'February', value: 2 },
  //     { text: 'March', value: 3 },
  // ],
};

export default CardInformation;
