import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import { Header, Icon, Segment, Form, Button, Label } from "semantic-ui-react";

import { rootURL } from '../config/config';
import { validate } from "graphql";

const RequestPassword = () => {
  const [email, setEmail] = useState("");
  const [accountId, setAccountId] = useState("");

  const [token, setToken] = useState();

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordValidate, setNewPasswordValidate] = useState('');

  const onEmailChange = e => {
    const validatedString = e.target.value.replace(/\s/g, '');
    setEmail(validatedString);
  };

  const onAccountIdChange = e => {
    const validatedString = e.target.value.replace(/[()-\s]/g, '');
    setAccountId(validatedString);
  };

  const handleNewPasswordOnChange = e => setNewPassword(e.target.value);
  const handleNewPasswordValidateOnChange = e => setNewPasswordValidate(e.target.value);


  const handleEmailAccountIdOnSubmit = e => {
    e.preventDefault();
    // setEmail("");
    // setAccountId("");


    axios.post(`${rootURL}/account/requestPasswordToken`, { email, accountId })
      .then((results) => {
        setToken(results?.data?.token);
        toast.success('Form Submitted!');
      })
      .catch((error) => {
        console.log('error: ', error.response.data.message);
        toast.error(error.response.data.message);
      })
  };

  const handleNewPasswordOnSubmit = e => {
    e.preventDefault();

    if (newPassword && newPassword === newPasswordValidate) {

      axios.post(`${rootURL}/account/requestPassword`, { newPassword, token })
        .then((results) => {
          setToken(results?.data?.token);
          toast.success('Form Submitted!');
        })
        .catch((error) => {
          console.log('error: ', error.response.data.message);
          toast.error(error.response.data.message);
        })
    } else {
      if (!newPassword) return toast.error('Must enter a new password!');
      if (newPassword !== newPasswordValidate) return toast.error('Passwords do not match!');
    }

  };

  if (!token) {
    return (
      <>
        <div className="container with-color">
          <div
            style={{
              textAlign: "left",
              color: "white",
              padding: "15px",
              fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
            }}
          >
            <h3>Request New Password</h3>
          </div>
          <Segment padded="very">
            <Header as="h1">
              {/* <Icon name="" /> */}
              Forgot your password? <br /> No worries, request a new one.
            </Header>
            <div>
              Please enter a valid email address and a temporary password will be
              sent.
            </div>
            <Form onSubmit={handleEmailAccountIdOnSubmit}>
              <div>
                <Form.Field
                  style={{
                    margin: "0 auto",
                    width: "50%"
                  }}
                >
                  <label style={{ textAlign: "left", marginTop: "15px" }}>
                    <Icon name="mail" size="large" />
                    Email Address
                  </label>
                  <Form.Input
                    onChange={onEmailChange}
                    name="email"
                    type="mail"
                    value={email}
                    placeholder="ex: john_doe@gmail.com"
                  />


                  <label style={{ textAlign: "left", marginTop: "15px" }}>
                    <Icon name="mail" size="large" />
                    Account Id
                  </label>
                  <Form.Input
                    onChange={onAccountIdChange}
                    name="accountId"
                    type="text"
                    value={accountId}
                    placeholder="ex: 12345"
                  />

                  <Button size="large" color="green">
                    Request Password
                  </Button>
                </Form.Field>
              </div>
            </Form>
          </Segment>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container with-color">
        <div
          style={{
            textAlign: "left",
            color: "white",
            padding: "15px",
            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
          }}
        >
          <h3>Request New Password</h3>
        </div>
        <Segment padded="very">
          <Header as="h1">
            {/* <Icon name="" /> */}
          Enter your new password.
        </Header>
          <Form onSubmit={handleNewPasswordOnSubmit}>
            <div>
              <Form.Field
                style={{
                  margin: "0 auto",
                  width: "50%"
                }}
              >
                <label style={{ textAlign: "left", marginTop: "15px" }}>
                  <Icon name="mail" size="large" />
              New Password
              </label>
                <Form.Input
                  onChange={handleNewPasswordOnChange}
                  name="New Password"
                  type="password"
                  value={newPassword}
                  placeholder="New Password"
                />

                <label style={{ textAlign: "left", marginTop: "15px" }}>
                  <Icon name="mail" size="large" />
                Validate New Password
              </label>
                <Form.Input
                  onChange={handleNewPasswordValidateOnChange}
                  name="newPasswordValidate"
                  type="password"
                  value={newPasswordValidate}
                  placeholder="New Password Validation"
                />

                {(newPassword && newPassword !== newPasswordValidate) ? <Label color='red' content='Passwords do not match!' pointing='above' /> : ''}

              </Form.Field>
              <Form.Field style={{ marginTop: '3rem' }}>
                <Button size="large" color="green">
                  Submit New Password
              </Button>
              </Form.Field>
            </div>
          </Form>
        </Segment>
      </div>
    </>
  )

};

export default RequestPassword;
